<template>
  <div class="describeWrap">
    <div class="d_top_banner">
      <img class="d_top_banner_img" :src="topBanner">
      <div class="d_top_banner_main">
        <div class="d_top_banner_title">
          <p class="d_top_banner_p1">
            {{ $t('views.pages.topTitle') }}
          </p>
          <p class="d_top_banner_p2">
            {{ $t('views.header.cryptocurrencies.title') }}
          </p>
        </div>
      </div>
    </div>
    <div class="d_main">
      <div class="d_left">
        <route-page :routeIndex="0"/>
      </div>
      <div class="d_right">
        <div class="d_title">
          {{ $t('views.pages.cryptocurrencies.bitcoinPerpetualFutures.title') }}
        </div>
        <div class="d_line"/>
        <div class="d_describe">
          <p>{{ $t('views.pages.cryptocurrencies.bitcoinPerpetualFutures.d1') }}</p><br>
          <p>{{ $t('views.pages.cryptocurrencies.bitcoinPerpetualFutures.d2') }}</p><br>
          <p>{{ $t('views.pages.cryptocurrencies.bitcoinPerpetualFutures.d3') }}</p>
        </div>

        <div class="table">
          <table border="1" cellpadding="1" cellspacing="1">
            <thead>
            <tr>
              <th colspan="2" scope="col">
                <strong>{{ $t('views.pages.cryptocurrencies.bitcoinPerpetualFutures.tableTh') }}</strong></th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td><strong>{{ $t('views.pages.cryptocurrencies.bitcoinPerpetualFutures.column_1_01') }}</strong></td>
              <td>{{ $t('views.pages.cryptocurrencies.bitcoinPerpetualFutures.column_2_01') }}</td>
            </tr>
            <tr>
              <td><strong>{{ $t('views.pages.cryptocurrencies.bitcoinPerpetualFutures.column_1_02') }}</strong></td>
              <td>{{ $t('views.pages.cryptocurrencies.bitcoinPerpetualFutures.column_2_02') }}</td>
            </tr>
            <tr>
              <td><strong>{{ $t('views.pages.cryptocurrencies.bitcoinPerpetualFutures.column_1_03') }}</strong></td>
              <td>{{ $t('views.pages.cryptocurrencies.bitcoinPerpetualFutures.column_2_03') }}</td>
            </tr>
            <tr>
              <td><strong>{{ $t('views.pages.cryptocurrencies.bitcoinPerpetualFutures.column_1_04') }}</strong></td>
              <td>{{ $t('views.pages.cryptocurrencies.bitcoinPerpetualFutures.column_2_04') }}</td>
            </tr>
            <tr>
              <td><b>{{ $t('views.pages.cryptocurrencies.bitcoinPerpetualFutures.column_1_05') }}</b></td>
              <td>{{ $t('views.pages.cryptocurrencies.bitcoinPerpetualFutures.column_2_05') }}</td>
            </tr>
            <tr>
              <td><b>{{ $t('views.pages.cryptocurrencies.bitcoinPerpetualFutures.column_1_06') }}</b></td>
              <td>{{ $t('views.pages.cryptocurrencies.bitcoinPerpetualFutures.column_2_06') }}</td>
            </tr>
            <tr>
              <td><strong>{{ $t('views.pages.cryptocurrencies.bitcoinPerpetualFutures.column_1_07') }}</strong></td>
              <td>
                <p>{{ $t('views.pages.cryptocurrencies.bitcoinPerpetualFutures.column_2_07') }}</p>
                <p>{{ $t('views.pages.cryptocurrencies.bitcoinPerpetualFutures.column_2_08') }}</p>
                <p><u>{{
                    $t('views.pages.cryptocurrencies.bitcoinPerpetualFutures.column_2_09')
                  }}</u><br>{{ $t('views.pages.cryptocurrencies.bitcoinPerpetualFutures.column_2_10') }}<br>
                  {{$t('views.pages.cryptocurrencies.bitcoinPerpetualFutures.column_2_11')}}<br>
                  {{$t('views.pages.cryptocurrencies.bitcoinPerpetualFutures.column_2_12')}}</p>
                <p><u>{{$t('views.pages.cryptocurrencies.bitcoinPerpetualFutures.column_2_13')}}</u><br>
                  {{$t('views.pages.cryptocurrencies.bitcoinPerpetualFutures.column_2_14')}}<br>
                  {{$t('views.pages.cryptocurrencies.bitcoinPerpetualFutures.column_2_15')}}<br>
                  {{$t('views.pages.cryptocurrencies.bitcoinPerpetualFutures.column_2_16')}}</p>
                <p>{{$t('views.pages.cryptocurrencies.bitcoinPerpetualFutures.column_2_17')}}<br>
                  {{$t('views.pages.cryptocurrencies.bitcoinPerpetualFutures.column_2_18')}}</p>
              </td>
            </tr>
            <tr>
              <td><strong>{{ $t('views.pages.cryptocurrencies.bitcoinPerpetualFutures.column_1_08') }}</strong></td>
              <td>{{$t('views.pages.cryptocurrencies.bitcoinPerpetualFutures.column_2_19')}}</td>
            </tr>
            <tr>
              <td><strong>{{ $t('views.pages.cryptocurrencies.bitcoinPerpetualFutures.column_1_09') }}</strong></td>
              <td>{{$t('views.pages.cryptocurrencies.bitcoinPerpetualFutures.column_2_20')}}</td>
            </tr>
            <tr>
              <td><strong>{{ $t('views.pages.cryptocurrencies.bitcoinPerpetualFutures.column_1_10') }}</strong></td>
              <td>{{$t('views.pages.cryptocurrencies.bitcoinPerpetualFutures.column_2_21')}}
              </td>
            </tr>
            <tr>
              <td><strong>{{ $t('views.pages.cryptocurrencies.bitcoinPerpetualFutures.column_1_11') }}</strong></td>
              <td>
                <p>{{$t('views.pages.cryptocurrencies.bitcoinPerpetualFutures.column_2_22')}}</p>
                <p>{{$t('views.pages.cryptocurrencies.bitcoinPerpetualFutures.column_2_23')}}</p>
              </td>
            </tr>
            <tr>
              <td><strong>{{ $t('views.pages.cryptocurrencies.bitcoinPerpetualFutures.column_1_12') }}</strong></td>
              <td>{{$t('views.pages.cryptocurrencies.bitcoinPerpetualFutures.column_2_24')}}<br>
                {{$t('views.pages.cryptocurrencies.bitcoinPerpetualFutures.column_2_25')}}</td>
            </tr>
            <tr>
              <td><strong>{{ $t('views.pages.cryptocurrencies.bitcoinPerpetualFutures.column_1_13') }}</strong></td>
              <td>{{$t('views.pages.cryptocurrencies.bitcoinPerpetualFutures.column_2_26')}}</td>
            </tr>
            <tr>
              <td><b>{{ $t('views.pages.cryptocurrencies.bitcoinPerpetualFutures.column_1_14') }}</b></td>
              <td>{{$t('views.pages.cryptocurrencies.bitcoinPerpetualFutures.column_2_27')}}</td>
            </tr>
            <tr>
              <td><strong>{{ $t('views.pages.cryptocurrencies.bitcoinPerpetualFutures.column_1_15') }}</strong></td>
              <td>{{$t('views.pages.cryptocurrencies.bitcoinPerpetualFutures.column_2_28')}}</td>
            </tr>
            <tr>
              <td><strong>{{ $t('views.pages.cryptocurrencies.bitcoinPerpetualFutures.column_1_16') }}</strong></td>
              <td>{{$t('views.pages.cryptocurrencies.bitcoinPerpetualFutures.column_2_29')}}
              </td>
            </tr>
            <tr>
              <td><strong>{{ $t('views.pages.cryptocurrencies.bitcoinPerpetualFutures.column_1_17') }}</strong></td>
              <td>{{$t('views.pages.cryptocurrencies.bitcoinPerpetualFutures.column_2_30')}}</td>
            </tr>
            </tbody>
          </table>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import RoutePage from "../../components/public/routerPage";

export default {
  name: "BitcoinPerpetualFutures",
  components: {RoutePage},
  data() {
    return {
      topBanner: require('@/assets/img/describe/header-banner.jpg')
    }
  },

}
</script>

<style lang="scss" scoped>

</style>