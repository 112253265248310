module.exports = {
    views: {
        home: {
            title0: 'The First Regulated Bitcoin Perpetual Futures in Asia',
            subTitle: 'Your Regulated Futures Trading Venue!',
            title1: 'Trade the gold in grams, with the global price.',
            d1: 'Trading Statistics',
            d2: '24hr Trading Value',
            d3: '10.18M',
            d4: '1w Trading Value',
            d5: '57.98M',
            d6: '30d Trading Value',
            d7: '368.04M',
            d8: 'Our Bitcoin index, Compass Crypto Real Time Index Bitcoin, is provided by the COMPASS Financial Technologies (France) which is registered as Benchmarks Administrator by the Autorité des Marchés Financiers (AMF) under the EU Benchmarks Regulation (EU BMR).',
            d9: 'View Disclaimer',
            d10: 'Our Products',
            d11: 'Bitcoin Perpetual Futures',
            d12: 'Bitcoin Monthly Futures',
            d13: 'Find out more',
            d14: 'Start Your Investment Journey with a Licensed Exchange Today',
            d15: 'Get in Touch with APEX Product Experts',
            d16: 'Open Account at one of APEX’s Members',
            disclaimer: 'All rights reserved. Compass has all proprietary rights with respect to the Compass Crypto Reference Index Bitcoin (“Index”). Any third party product based on or in relation to the Index (“Product”) may only be issued upon the prior written approval of Compass and upon the execution of a license agreement between Compass and the party intending to launch a Product. In no way Compass sponsors, endorses or is otherwise involved in the issue and offering of a Product or does it make any representation or warranty, express or implied, to the holders of the Products or any member of the public regarding the advisability of investing in the Product or commodities generally or in futures particularly, or as to results to be obtained from the use of the Index or from the Product. Compass disclaims any liability to any party for any inaccuracy in the data on which the Index is based, for any mistakes, errors, omissions or interruptions in the calculation and/or dissemination of the Index, or for the manner in which it is applied in connection with the issue and offering of a Product. In no event shall Compass have any liability for any lost profits or indirect, punitive, special or consequential damages or losses, even if notified of the possibility thereof. This is not an offer or solicitation of an offer to buy or sell any security or investment. Past performances of the Compass Crypto Reference Indices are not necessarily indicative of future results.'

        },
        header: {
            cryptocurrencies: {
                title: 'Cryptocurrencies',
                d1: 'Bitcoin Perpetual Futures',
                d2: 'Bitcoin Monthly Futures'
            },
            preciousMetals: {
                title: 'Precious Metals',
                d1: 'Gold Futures',
                d2: 'Silver Futures'
            },
            forex: {
                title: 'Forex',
                d1: 'USD/CNH Futures',

            },
            commodities: {
                title: 'Commodities',
                d1: 'Palm Olein Futures',
                d2: 'Crude Palm Oil Futures',
                d3: 'Fuel Oil Futures',
                d4: 'Low Sulphur Fuel Oil Futures'
            },
            btn: 'Trade with Alpha'
        },
        pages: {
            topTitle: 'EXCHANGE',
            cryptocurrencies: {
                bitcoinPerpetualFutures: {
                    title: 'Bitcoin Perpetual Futures',
                    d1: 'Cryptocurrency has gained much traction among investors, showing signs of wider scale adoption. Bitcoin, being the first cryptocurrency, remains one of the most popular and valuable digital asset, despite the ever growing legion of cryptocurrencies.',
                    d2: 'APEX Bitcoin Perpetual Futures Contract allows you to manage your risk in cryptocurrencies and gain valuable exposure in this growing asset class. It provides diversification to trading portfolio for institutional clients and, being a futures contract, it can act as a risk minimization instrument to help your portfolio withstand price volatility in cryptocurrencies.',
                    d3: 'Note: This product is not suitable for retail investors as the underlying asset has much higher price volatility compared to other asset classes. Hence, please approach APEX’s members for them to assess your suitability to trade this product.',
                    tableTh: 'APEX Bitcoin Perpetual Futures Contract Specifications',
                    column_1_01: 'Contract Code',
                    column_1_02: 'Contract Size',
                    column_1_03: 'Quote Currency',
                    column_1_04: 'Minimum Price Fluctuation',
                    column_1_05: 'Tick Value',
                    column_1_06: 'Contract Series',
                    column_1_07: 'Trading Hours',
                    column_1_08: 'Last Trading Day',
                    column_1_09: 'Daily Price Limit',
                    column_1_10: 'Daily Settlement Price',
                    column_1_11: 'Funding Fee',
                    column_1_12: 'Funding Interval',
                    column_1_13: 'Final Settlement Price',
                    column_1_14: 'Settlement Method',
                    column_1_15: 'Block Trade',
                    column_1_16: 'Initial Margin',
                    column_1_17: 'Maximum Order Size',

                    column_2_01: 'BTCP',
                    column_2_02: '50 milliBitcoin/lot (0.05 BTC = 50 milliBitcoin)',
                    column_2_03: 'USD',
                    column_2_04: 'US $0.005/milliBitcoin',
                    column_2_05: 'US $0.25',
                    column_2_06: 'Perpetual Contract',
                    column_2_07: 'Singapore time trading sessions:',
                    column_2_08: 'Monday – Friday, including Singapore Public Holidays',
                    column_2_09: 'T Session:',
                    column_2_10: '06:55hr – 06:59hr (Pre-opening session)',
                    column_2_11: '06:59hr – 07:00hr (Opening match session)',
                    column_2_12: '07:00hr – 18:00hr (Day session)',
                    column_2_13: 'T+1 Session:',
                    column_2_14: '19:25hr – 19:29hr (Pre-opening session)',
                    column_2_15: '19:29hr – 19:30hr (Opening match session)',
                    column_2_16: '19:30hr – 05:30hr (Night session)',
                    column_2_17: 'T stands for a given Trading Day.',
                    column_2_18: 'The end of the Day Session is the end of T.',
                    column_2_19: 'Not applicable as there is no maturity date.',
                    column_2_20: 'Unless otherwise prescribed by the Exchange, there shall be no daily price limits.',
                    column_2_21: 'The Daily Settlement Price of a given day shall be equal to Compass Crypto Reference Index Bitcoin (CCRIBTC) Price published by Compass Financial Technologies SA (“Compass”) at 18:00hr (GMT+8). The Daily Settlement Price shall be rounded to the nearest US $0.005/milliBitcoin. ',
                    column_2_22: 'Funding occurs where the position holder will receive or pay a Funding Fee computed using a Funding Rate. This shall be applied to all open positions at the end of each T Session and T+1 Session. The Funding Fee will be calculated and collected during the end-of-day clearing cycle at the end of each Trading Day.',
                    column_2_23: 'The formula is as follows: Funding Fee = Number of open position contracts x Contract Size x Compass Crypto Real Time Index Bitcoin (CCRTBTC) (in milliBitcoin) x Funding Rate The Funding Rates and its methodology will be published on APEX website.',
                    column_2_24: 'T Session: At 18:00hr (GMT+8)',
                    column_2_25: 'T+1 Session: At 05:30hr (GMT+8)',
                    column_2_26: 'Not applicable as there is no maturity date.',
                    column_2_27: 'Cash Settlement in USD against the Daily Settlement Price',
                    column_2_28: 'Minimum 20 lots',
                    column_2_29: 'Based on SPAN ',
                    column_2_30: '500 lots',


                },
                bitcoinMonthlyFutures: {
                    title: ' Bitcoin Monthly Futures',
                    d1: 'Cryptocurrency has gained much traction among investors, showing signs of wider scale adoption. Bitcoin, being the first cryptocurrency, remains one of the most popular and valuable digital asset, despite the ever growing legion of cryptocurrencies.',
                    d2: 'APEX Bitcoin Monthly Futures Contract allows you to manage your risk in cryptocurrencies and gain valuable exposure in this growing asset class. It provides diversification to trading portfolio for institutional clients and, being a futures contract, it can act as a risk minimization instrument to help your portfolio withstand price volatility in cryptocurrencies.',
                    d3: 'Note: This product is not suitable for retail investors as the underlying asset has much higher price volatility compared to other asset classes. Hence, please approach APEX’s members for them to assess your suitability to trade this product.',
                    tableTh: 'APEX Bitcoin Monthly Futures Contract Specifications',
                    column_1_01: 'Contract Code',
                    column_1_02: 'Contract Size',
                    column_1_03: 'Quote Currency',
                    column_1_04: 'Minimum Price Fluctuation',
                    column_1_05: 'Tick Value',
                    column_1_06: 'Contract Series',
                    column_1_07: 'Trading Hours',
                    column_1_08: 'Last Trading Day',
                    column_1_09: 'Daily Price Limit',
                    column_1_10: 'Daily Settlement Price',
                    column_1_11: 'Final Settlement Price',
                    column_1_12: 'Settlement Method',
                    column_1_13: 'Block Trade',
                    column_1_14: 'Initial Margin',
                    column_1_15: 'Maximum Order Size',

                    column_2_01: 'BTC',
                    column_2_02: '50 milliBitcoin/lot (0.05 BTC = 50 milliBitcoin)',
                    column_2_03: 'USD',
                    column_2_04: 'US $0.005/milliBitcoin',
                    column_2_05: 'US $0.25',
                    column_2_06: 'Nearest 6 consecutive months',
                    column_2_07: 'Singapore time trading sessions:',
                    column_2_08: 'Monday – Friday, including Singapore Public Holidays',
                    column_2_09: 'T Session:',
                    column_2_10: '06:55hr – 06:59hr (Pre-opening session)',
                    column_2_11: '06:59hr – 07:00hr (Opening match session)',
                    column_2_12: '07:00hr – 18:00hr (Day session)',
                    column_2_13: 'T+1 Session:',
                    column_2_14: '19:25hr – 19:29hr (Pre-opening session)',
                    column_2_15: '19:29hr – 19:30hr (Opening match session)',
                    column_2_16: '19:30hr – 05:30hr (Night session)',
                    column_2_17: 'T stands for a given Trading Day. The end of the Day Session is the end of T.',
                    column_2_18: 'The Last Trading Day of a Contract shall be the last Friday of the Contract Month',
                    column_2_19: 'Unless otherwise prescribed by the Exchange, there shall be no daily price limits.',
                    column_2_20: 'The Daily Settlement Price of a given day shall be the volume-weighted average of the traded prices of all transactions occurred during the last minute of the given day’s Day Session, or other methodology as set forth by the Clearing House. The Daily Settlement Price shall be rounded to the nearest US $0.005/milliBitcoin. ',
                    column_2_21: 'The Final Settlement Price shall be equal to Compass Crypto Reference Index Bitcoin (CCRIBTC) Price published by Compass Financial Technologies SA (“Compass”) at 18:00hr (GMT+8) on the Last Trading Day. The Final Settlement Price shall be rounded to the nearest US $0.005/milliBitcoin. ',
                    column_2_22: 'Cash Settlement in USD',
                    column_2_23: 'Minimum 20 lots',
                    column_2_24: 'Based on SPAN ',
                    column_2_25: '500 lots',


                }
            },
            preciousMetals: {
                goldFutures: {
                    title: 'Gold Futures',
                    d1: 'Gold, long viewed as safe haven asset, is commonly used as a hedging tool for investment. With its limited supply and indestructible nature, Gold is an excellent store of value. APEX Gold Perpetual Futures Contract serves as a tool for your trading, hedging and risk management needs in this precious metal.',
                    tableTh: 'APEX Gold Perpetual Futures Contract Specifications (V2021.08.06)',
                    column_1_01: 'Contract Code',
                    column_1_02: 'Contract Size',
                    column_1_03: 'Quote Currency',
                    column_1_04: 'Minimum Price Fluctuation',
                    column_1_05: 'Tick Value',
                    column_1_06: 'Contract Series',
                    column_1_07: 'Trading Hours',
                    column_1_08: 'Last Trading Day',
                    column_1_09: 'Daily Price Limit',
                    column_1_10: 'Daily Settlement Price',
                    column_1_11: 'Final Settlement Price',
                    column_1_12: 'Settlement Method',
                    column_1_13: 'Rollover Fees',
                    column_1_14: 'Block Trade',
                    column_1_15: 'Initial Margin',
                    column_1_16: 'Maximum Order Size',


                    column_2_01: 'AUP',
                    column_2_02: '100 grams / Lot',
                    column_2_03: 'USD',
                    column_2_04: 'US $0.01 / gram',
                    column_2_05: 'US $1',
                    column_2_06: 'Perpetual Contract',
                    column_2_07: 'London Bullion Market Association (LBMA) AM Gold Price Publication Day',
                    column_2_08: 'T Session:',
                    column_2_09: '06:55hr – 06:59hr (Pre-opening Session)',
                    column_2_10: '06:59hr – 07:00hr (Opening Match Session)',
                    column_2_11: '07:00hr – 18:00hr (Day Session)',
                    column_2_12: 'T+1 Session:',
                    column_2_13: '20:25hr – 20:29hr (Pre-opening Session)',
                    column_2_14: '20:29hr – 20:30hr (Opening Match Session)',
                    column_2_15: '20:30hr – 05:00hr (Night Session)',
                    column_2_16: 'T stands for a given Trading Day.',
                    column_2_17: 'The end of the Day Session is the end of T.',
                    column_2_18: 'There will be no T+1 Session on a non-LBMA Gold AM Publication Day or the previous Trading Day of a non-LBMA Gold AM Publication Day.',
                    column_2_19: 'Not Applicable as there is no maturity date.',
                    column_2_20: '+/-10% of previous Trading Day’s Daily Settlement Price.',
                    column_2_21: 'The Daily Settlement Price of a given day shall be equal to the LBMA Gold Price AM published by IBA rounded to the nearest US $0.01/gram. ',
                    column_2_22: 'Not Applicable as there is no maturity date.',
                    column_2_23: 'Cash Settlement in USD against the Daily Settlement Price.',
                    column_2_24: 'Rollover Fees shall be applied to all open positions at the end of the T Session. The Rollover Fees will be calculated and collected during the end-of-day clearing cycle at the end of each Trading Day.',
                    column_2_25: 'The formula is as follows:',
                    column_2_26: 'Number of open position contracts x Contract Size x Daily Settlement Price x (Rollover Rate) ÷ 365',
                    column_2_27: 'The Rollover Rates used to calculate the Rollover Fees will be published on the APEX website.',
                    column_2_28: 'Minimum 30 lots',
                    column_2_29: 'Based on SPAN',
                    column_2_30: '500 lots',


                },
                silverFutures: {
                    title: 'Silver Futures',
                    d1: 'As compared to Gold, Silver has a higher industrial demand especially in the photographic and medical fields. Having a high correlation with Gold, Silver is similarly often regarded as a safe haven asset and useful hedging tool. APEX Silver Perpetual Futures Contract serves as a tool for your trading, hedging and risk management needs in this precious metal.',
                    tableTh: 'APEX Silver Perpetual Futures Contract Specifications',
                    column_1_01: 'Contract Code',
                    column_1_02: 'Contract Size',
                    column_1_03: 'Quote Currency',
                    column_1_04: 'Minimum Price Fluctuation',
                    column_1_05: 'Tick Value',
                    column_1_06: 'Contract Series',
                    column_1_07: 'Trading Hours',
                    column_1_08: 'Last Trading Day',
                    column_1_09: 'Daily Price Limit',
                    column_1_10: 'Daily Settlement Price',
                    column_1_11: 'Final Settlement Price',
                    column_1_12: 'Settlement Method',
                    column_1_13: 'Rollover Fees',
                    column_1_14: 'Block Trade',
                    column_1_15: 'Initial Margin',
                    column_1_16: 'Maximum Order Size',


                    column_2_01: 'AGP',
                    column_2_02: '5 kilograms/lot',
                    column_2_03: 'USD',
                    column_2_04: 'US $0.2/kilogram',
                    column_2_05: 'US $1',
                    column_2_06: 'Perpetual Contract',
                    column_2_07: 'London Bullion Market Association (LBMA) Silver Price Publication Day',
                    column_2_08: 'T Session:',
                    column_2_09: '06:55hr – 06:59hr (Pre-opening Session)',
                    column_2_10: '06:59hr – 07:00hr (Opening Match Session)',
                    column_2_11: '07:00hr – 18:00hr (Day Session)',
                    column_2_12: 'T+1 Session:',
                    column_2_13: '20:25hr – 20:29hr (Pre-opening Session)',
                    column_2_14: '20:29hr – 20:30hr (Opening Match Session)',
                    column_2_15: '20:30hr – 05:00hr (Night Session)',
                    column_2_16: 'T stands for a given Trading Day.',
                    column_2_17: 'The end of the Day Session is the end of T.',
                    column_2_18: 'There will be no T+1 Session on the non-LBMA Silver Publication Day or the previous Trading Day of a non-LBMA Silver Publication Day.',
                    column_2_19: 'Not Applicable as there is no maturity date.',
                    column_2_20: '+/-15% of previous Trading Day’s Daily Settlement Price.',
                    column_2_21: 'The Daily Settlement Price of a given day shall be equal to the LBMA Silver Price published by IBA rounded to the nearest US $0.2/kilogram',
                    column_2_22: 'Not Applicable as there is no maturity date.',
                    column_2_23: 'Cash Settlement in USD against the Daily Settlement Price.',
                    column_2_24: 'Rollover Fees shall be applied to all open positions at the end of the T Session. The Rollover Fees will be calculated and collected during the end-of-day clearing cycle at the end of each Trading Day.',
                    column_2_25: 'The formula is as follows:',
                    column_2_26: 'Number of open position contracts x Contract Size x Daily Settlement Price x (Rollover Rate) ÷ 365',
                    column_2_27: 'The Rollover Rates used to calculate the Rollover Fees will be published on the APEX website.',
                    column_2_28: 'Minimum 50 lots',
                    column_2_29: 'Based on SPAN',
                    column_2_30: '500 lots',


                }
            },
            forex: {
                USDCNHFutures: {
                    title: ' USD/CNH Futures',
                    d1: 'With the acceleration of the internationalization of the Renminbi (RMB), the international status of the RMB has been on the rise. The RMB is widely used in global trade and investment, and increasingly becoming one of the important international reserve currencies. The pace of reform of the RMB exchange rate regime is gaining momentum at the same time with greater international trade flows, resulting in trends of higher volatility and increased fluctuation in both directions of the exchange rate.',
                    tableTh: 'APEX USD/CNH Futures Contract Specifications',
                    column_1_01: 'Contract Code',
                    column_1_02: 'Ticker Symbols',
                    column_1_03: 'Contract Size',
                    column_1_04: 'Price Quotation',
                    column_1_05: 'Minimum Price Fluctuation',
                    column_1_06: 'Tick Value',
                    column_1_07: 'Contract Series',
                    column_1_08: 'Trading Hours',
                    column_1_09: 'Last Trading Day',
                    column_1_10: 'Daily Price Limit',
                    column_1_11: 'Daily Settlement Price',
                    column_1_12: 'Final Settlement Price',
                    column_1_13: 'Settlement Method',
                    column_1_14: 'Block Trade',
                    column_1_15: 'Initial Margin',
                    column_1_16: 'Maximum Order Size',


                    column_2_01: 'UC',
                    column_2_02: 'A Contract is denoted as UCyymmW#, where “yy”, “mm” and “W#” refer to the year, month and the ordinal week of that month on which the Contract shall expire. For symbology purposes, the first week of a month is the first week of that month with a Wednesday.',
                    column_2_03: 'e.g. UC1901W3 refers to a Contract that is to expire on the third week of January 2019.',
                    column_2_04: 'USD 10,000',
                    column_2_05: 'CNH per USD',
                    column_2_06: 'CNH 0.0001 (4 decimal places)',
                    column_2_07: 'CNH 1',
                    column_2_08: 'The Exchange shall list 4 Contracts which shall expire in the next 4 near-term consecutive weeks, followed by another 3 Contracts which shall expire in the third week of each of the next 3 consecutive months, followed by another 3 Contracts which shall expire in the third week of each of the next 3 quarterly months (March, June, September and December).',
                    column_2_09: 'Upon the expiration of a Contract, the Exchange shall list a new Contract for trading by the beginning of the next Trading Day.',
                    column_2_10: 'Singapore time trading sessions: Singapore Business Days',
                    column_2_11: 'Monday – Friday, Excluding Singapore public holidays08:55am – 08:59am (Pre-opening Session)',
                    column_2_12: '08:59am – 09:00am (Opening match session of Day Session)',
                    column_2_13: '09:00am – 18:00pm (Day Session)Trading of a Contract shall cease at 11:00am on its Last Trading Day',
                    column_2_14: 'The Last Trading Day of a Contract shall be the Monday of that Contract Week if it is both a Hong Kong Business Day and a Singapore Business Day, otherwise the earliest subsequent day which is both a Hong Kong Business Day and a Singapore Business Day. Notwithstanding the above, the Exchange reserves the right to determine the Last Trading Day with notice.',
                    column_2_15: 'Unless otherwise prescribed by the Exchange, there shall be no daily price limits.',
                    column_2_16: 'The Daily Settlement Price of a given day shall be the volume-weighted average of the traded prices of all transactions occurred during the last 5 minutes of the given day’s Day Session, or other methodology as set forth by the Clearing House.',
                    column_2_17: 'The Final Settlement Price shall be equal to USD/CNY(HK) Spot Rate published by the Treasury Markets Association of Hong Kong (TMA) at approximately 11:30am Hong Kong time on the Last Trading Day, or other methodology as set forth by the Clearing House.',
                    column_2_18: 'Cash settlement in CNH',
                    column_2_19: 'Minimum 200 lots',
                    column_2_20: 'Based on SPAN',
                    column_2_21: '500 lots',


                },

            },
            commodities: {
                palmOleinFutures: {
                    title: 'Palm Olein Futures',
                    d1: 'APEX is targeting to launch its first product, a refined palm olein futures contract. The key advantages of palm olein futures are: FOB physical delivery with delivery ports that are located Malaysia and Indonesia along the Straits of Malacca. 85% of global palm oil production and 91% of international palm trade takes place in Malaysia and Indonesia.',
                    d2: 'By using USD as the pricing currency and accepting RMB and other currencies as acceptable margin collateral, APEX’s palm olein futures contract is in line with international trade practices and avoids the risk of currency price fluctuations.',
                    d3: 'With the impending launch of APEX’s palm olein futures contract, it aims to provide an alternative venue to the global palm oil trading industry and serves as a new avenue for hedging and arbitraging purposes.',
                    tableTh: 'APEX RBD Palm Olein Futures Contract Specifications (V2019.04.03)',
                    column_1_01: 'Contract Code',
                    column_1_02: 'Contract Size',
                    column_1_03: 'Quote Currency',
                    column_1_04: 'Minimum Price Fluctuation',
                    column_1_05: 'Trading Hours',
                    column_1_06: 'Daily Price Limit',
                    column_1_07: 'Contract Series',
                    column_1_08: 'Last Trading Day',
                    column_1_09: 'Delivery Method',
                    column_1_10: 'Minimum Deliverable Size',
                    column_1_11: 'Initial Margin',
                    column_1_12: 'Last Delivery Day',
                    column_1_13: 'Maximum Order Size',

                    column_2_01: 'PF',
                    column_2_02: '10MT / Contract',
                    column_2_03: 'USD',
                    column_2_04: 'US$0.25 / MT',
                    column_2_05: 'Singapore time trading sessions: Singapore Business Days',
                    column_2_06: 'Monday – Friday, Excluding Singapore public holidays',
                    column_2_07: ' ',
                    column_2_08: '08:55am – 08:59am (Pre-opening Session)',
                    column_2_09: '08:59am – 09:00am (Opening Match Session)',
                    column_2_10: '09:00am – 12:30pm (Morning Session/1st Session)',
                    column_2_11: '13:30pm – 18:00pm (Afternoon Session/2nd Session)',
                    column_2_12: 'Trading of a Contract shall cease at 18:00pm on its Last Trading Day',
                    column_2_13: '+/-10% of Last Settlement Price. There shall be no Daily Price Limit in force for a Contract on its Last Trading Day.',
                    column_2_14: 'Monthly Contracts (12 consecutive months Contracts)',
                    column_2_15: 'The 15th Calendar Day of Front Contract Month if it is a Business Day, otherwise the preceding Business Day',
                    column_2_16: 'Physical delivery FOB at Pasir Gudang (Malaysia), Port Klang (Malaysia), Lahad Datu (Malaysia), Belawan (Indonesia), Dumai (Indonesia), or any other APEX Approved Port',
                    column_2_17: '50 Contracts (i.e. a total of 500 MT)',
                    column_2_18: 'Based on SPAN (Click to see Margin Schedule)',
                    column_2_19: 'The last Calendar Day of Delivery Month',
                    column_2_20: '500 lots',


                },
                crudePalmOilFutures: {
                    title: 'Crude Palm Oil Futures',
                    d1: 'The APEX Crude Palm Oil Futures Contract is the second in the palm oil series and also holds similar features to the first product, APEX RBD Palm Olein Futures Contract. APEX Crude Palm Oil Futures will operate in parallel with the APEX RBD Palm Olein Futures and increase the arbitrage opportunities available in the palm oil market',
                    tableTh: 'APEX Crude Palm Oil Futures Contract Specifications',
                    column_1_01: 'Contract Code',
                    column_1_02: 'Contract Size',
                    column_1_03: 'Quote Currency',
                    column_1_04: 'Minimum Price Fluctuation',
                    column_1_05: 'Trading Hours',
                    column_1_06: 'Daily Price Limit',
                    column_1_07: 'Contract Series',
                    column_1_08: 'Last Trading Day',
                    column_1_09: 'Delivery Method',
                    column_1_10: 'Minimum Deliverable Size',
                    column_1_11: 'Initial Margin',
                    column_1_12: 'Last Delivery Day',
                    column_1_13: 'Maximum Order Size',

                    column_2_01: 'CPF',
                    column_2_02: '10MT / Contract',
                    column_2_03: 'USD',
                    column_2_04: 'US$0.25 / MT',
                    column_2_05: 'Singapore time trading sessions: Singapore Business Days',
                    column_2_06: 'Monday – Friday, Excluding Singapore public holidays08:55am – 08:59am (Pre-opening Session)',
                    column_2_07: '08:59am – 09:00am (Opening Match Session)',
                    column_2_08: '09:00am – 12:30pm (Morning Session)',
                    column_2_09: '13:30pm – 18:00pm (Afternoon Session)',
                    column_2_10: ' ',
                    column_2_11: 'Trading of a Contract shall cease at 18:00pm on its Last Trading Day',
                    column_2_12: '+/-10% of Last Settlement Price. There shall be no Daily Price Limit in force for a Contract on its Last Trading Day.',
                    column_2_13: 'Monthly Contracts (12 consecutive months Contracts)',
                    column_2_14: 'The 15th Calendar Day of the Front Contract Month if it is a Business Day, otherwise the preceding Business Day',
                    column_2_15: 'Physical delivery FOB at any APEX Approved Ports as listed in ANNEX A on APEX’s website',
                    column_2_16: '50 Contracts (i.e. a total of 500 MT)',
                    column_2_17: 'Based on SPAN',
                    column_2_18: 'The last Calendar Day of Delivery Month',
                    column_2_19: '500 Contracts per order',

                },
                fuelOilFutures: {
                    title: 'Fuel Oil Futures',
                    d1: 'The contract is currently dormant and not available for trading. For more information, please contact APEX or your respective brokers directly.',
                    d2: 'Singapore is currently the largest bunkering port in the world, supplying approximately 50 million metric tons of marine fuel oil annually. The maritime industry remains a huge part of the Singapore Economy, contributing around 7% to Singapore’s Gross Domestic Product (GDP).',
                    d3: 'As fuel costs remain the biggest cost item for shipping companies, huge price fluctuations in marine fuel oil prices can adversely affect operational costs for many companies, which can potentially cause a ripple effect on the Singapore economy.',
                    d4: 'In light of the current situation, APEX launched the 380cst Fuel Oil Futures Contract, to create a platform for Energy industries and Financial institutions to hedge and invest in the fuel oil market.',
                    tableTh: 'APEX 380cst Fuel Oil Futures Contract Specifications',
                    column_1_01: 'Contract Code',
                    column_1_02: 'Contract Size',
                    column_1_03: 'Quote Currency',
                    column_1_04: 'Minimum Price Fluctuation',
                    column_1_05: 'Trading Hours',
                    column_1_06: 'Daily Price Limit',
                    column_1_07: 'Contract Series',
                    column_1_08: 'Last Trading Day',
                    column_1_09: 'Delivery Method',
                    column_1_10: 'Minimum Deliverable Size',
                    column_1_11: 'Initial Margin',
                    column_1_12: 'Maximum Order Size',

                    column_2_01: 'FO',
                    column_2_02: '10MT / Contract',
                    column_2_03: 'USD',
                    column_2_04: 'US$0.10 / MT',
                    column_2_05: 'Singapore time trading sessions: Singapore Business Days',
                    column_2_06: 'Monday – Friday, Excluding Singapore public holidays08:55am – 08:59am (Pre-opening Session)',
                    column_2_07: '08:59am – 09:00am (Opening Match Session)',
                    column_2_08: '09:00am – 12:30pm (Morning Session/1st Session)',
                    column_2_09: '13:30pm – 18:00pm (Afternoon Session/2nd Session)Trading of a Contract shall cease at 18:00pm on its Last Trading Day',
                    column_2_10: '/-10% of Last Settlement Price. There shall be no Daily Price Limit in force for a Contract on its Last Trading Day.',
                    column_2_11: 'Monthly Contracts (12 consecutive months Contracts)',
                    column_2_12: 'The last Business Day of the Front Contract Month otherwise the preceding Business Day',
                    column_2_13: 'Physical Delivery at Recognised Warehouses by FOB or inter-Tank transfer',
                    column_2_14: '100 Contracts (i.e. a total of 1,000 MT)',
                    column_2_15: 'Based on SPAN',
                    column_2_16: '500 Contracts per order',


                },
                lowSulphurFuelOilFutures: {
                    title: 'Low Sulphur Fuel Oil Futures',
                    d1: 'The contract is currently dormant and not available for trading. For more information, please contact APEX or your respective brokers directly.',
                    d2: 'Asia Pacific Exchange (APEX) has launched the Low Sulphur Fuel Oil Futures Contract (LFO Contract) at 9pm on 21st Nov 2019. The LFO Contract is the first deliverable product worldwide to facilitate hedging and FOB physical delivery of low Sulphur fuel oil.',
                    tableTh: 'Low Sulphur Fuel Oil Futures Contract Specification',
                    column_1_01: 'Contract Code',
                    column_1_02: 'Contract Size',
                    column_1_03: 'Quote Currency',
                    column_1_04: 'Minimum Price Fluctuation',
                    column_1_05: 'Trading Hours',
                    column_1_06: 'Daily Price Limit',
                    column_1_07: 'Contract Series',
                    column_1_08: 'Last Trading Day',
                    column_1_09: 'Last Delivery Day',
                    column_1_10: 'Delivery Method',
                    column_1_11: 'Minimum Deliverable Size',
                    column_1_12: 'Initial Margin',
                    column_1_13: 'Maximum Order Size',

                    column_2_01: 'LFO',
                    column_2_02: '10 MT / Contract',
                    column_2_03: 'USD',
                    column_2_04: 'US $0.10 / MT',
                    column_2_05: 'Singapore time trading sessions: Singapore Business Days',
                    column_2_06: 'Monday – Friday, Excluding Singapore public holidays08:55am – 08:59am (Pre-opening Session)',
                    column_2_07: '08:59am – 09:00am (Opening Match Session)',
                    column_2_08: '09:00am – 12:30pm (Morning Session/1st Session)',
                    column_2_09: '13:30pm – 18:00pm (Afternoon Session/2nd Session)Trading of a Contract shall cease at 18:00pm on its Last Trading Day',
                    column_2_10: '+/-10% of Last Settlement Price',
                    column_2_11: 'There shall be no Daily Price Limit in force for a Contract on its Last Trading Day.',
                    column_2_12: 'Monthly Contracts (12 consecutive months Contracts)',
                    column_2_13: 'The last Business Day of the Front Contract Month',
                    column_2_14: 'The last Calendar Day of Contract Month',
                    column_2_15: 'Physical Delivery at Recognised Warehouses by FOB or inter-Tank transfer',
                    column_2_16: '100 Contracts (i.e. a total of 1,000 MT)',
                    column_2_17: 'Based on SPAN',
                    column_2_18: '500 Contracts per order',

                },
            },
            privacyPolicy: {
                title: 'Privacy Policy',
                d1: 'In brief: We are Alpha fund management Pte. Ltd. ("Alpha," "we" or "us"). We respect your privacy and work hard to protect the confidentiality of information that you give us. We will share your personal data with third parties only in the event that it’s needed for the provision of services and data you have requested (explained in detail in this privacy policy).',
                d2: 'About Us',
                d3: 'Alpha is the data controller responsible for your personal data and is the sole owner of the Alpha mobile and desktop applications, Alpha services (each, a "Service") and all of the information collected on it. Alpha collects information from users in several ways (explained in detail in this privacy policy).',
                d4: 'This privacy policy applies to personal data that we collect from you when you create an account, use our applications or services ("you" or "your" being interpreted accordingly). It provides information on what personal data we collect, why we collect personal data, how it is used, the lawful basis on which your personal data is processed, how it is stored, and how we share information.',
                d5: 'As used in this privacy policy, "personal data" means any information that relates to you from which you can be identified.',
                d6: 'By using our Service or submitting your personal data, you are agreeing to accept the terms of this privacy policy, so please read it carefully. If you disagree with this privacy policy at any point or time, you should stop using our Services and delete your user account.',
                d7: 'Personal data that we collect',
                d8: 'There is no need to create an account when searching and viewing information from certain markets, such as symbols, quotes, financial data, as well as ideas and publicly available data of Alpha users.',
                d9: '1) User Accounts',
                d10: 'If you decide to create an account, you will need to provide us with some personal data so we can provide you with access to our additional services that users benefit from. You may also elect to provide us with additional information about you, as described below.',
                d11: 'During registration you will be required to provide us with either:',
                d12: 'a) your username, email address;',
                d13: 'b) your account information with one of the following social Internet services: Facebook, Twitter, Google, Yahoo!, LinkedIn; and',
                d14: 'c) if you want to buy a subscription, we will need your first name, last name and postal address.',
                d15: 'd) In some cases, you may need to provide and verify your phone number. You can also add additional information about yourself in the profile settings, however, this is not mandatory.',
                d16: 'The following information will be publicly displayed:',
                d17: 'e) the image you have elected to provide as your user avatar, signature, “about me,” location.',
                d18: 'f) The following information will not be publicly displayed:',
                d19: 'g) your first and last name, phone number, email, company, address, and taxpayer ID.',
                d20: '2) Contact data',
                d21: 'We also process any personal data you choose to give when corresponding with us by phone, email, or otherwise visiting and interacting with any other services we operate.',
                d22: '3) Log files',
                d23: 'We use web server log files. Records in our log files include internet protocol (IP) addresses, browser types, internet service providers, referring pages, exit pages, platform types, and date/time stamps. We use web server log files to administer the site, provide broad traffic information for site planning purposes, and to ensure that our terms of service agreement is being adhered to. Note that IP addresses are not tied to personal data in our web server log files.',
                d24: '4) Device information',
                d25: 'We collect the following information about the devices you use to interact with our services: IP address of the device (from which we can also infer the country you are connecting from), operating system, and browser version. We collect this information to prevent spam and abuse of our services. IP addresses may be considered personal data in certain jurisdictions, and will be treated in accordance with this privacy policy.',
                d26: '5) Analytics',
                d27: 'When you are using our Website, mobile and desktop applications, or widgets we provide to another site, we may collect analytics information automatically. This information may include your general device information, device identifiers (for example, Google Advertising ID, if you are using an Android device, or Advertising Identifier (IDFA), if you are using an iOS device), network information, website, and application interaction events. We collect pseudonymous data only and this analytic information does not include any personal identifiable information from your profile. We use this information to analyze and improve the work of our services and applications, and to develop, test, and improve our service further. For this purpose we may use internal analytic as well as third party service providers. For further details about our use of third party service providers, please refer to the Analytics providers section below.',
                d28: '6) Data we receive from third parties',
                d29: 'When you sign in to your account using your social internet service credentials (Facebook, Twitter, Google, Yahoo!, LinkedIn), such service sends us your username and the email address associated with the credentials you\'ve used. We neither control nor take any responsibility for the way third parties provide information about you and/or process your personal data.',
                d30: 'How personal information is used',
                d31: 'We use personal data in various ways that are necessary to provide the services you intend to use, certain legitimate interests, and as a necessity in order to comply with applicable law, as further described below.',
                d32: 'We do not generally rely on your consent to allow us to process your personal data if there is another lawful ground available. If we do rely on your consent, we will make this clear to you at that time.',
                d33: '1) Profile',
                d34: 'A profile is a set of information for each individual user that details his/her viewing preferences. User settings (chart settings, toolbars, buttons, etc.) are kept solely for the purpose of customizing the user’s experience when using the Website, applications or user accounts. We do not share private profile settings with any third parties.',
                d35: 'Note that when you create an account, other users or visitors may access your public profile and see any information you have made publicly available about yourself.',
                d36: '2) User account and profile operations',
                d37: 'We use your contact details, such as username, email address, or phone number, in the following ways:',
                d38: 'a) to authenticate your user account, ensure its security along with the security of our services, and to prevent spam, fraud, and abuse;',
                d39: 'b) to acknowledge, confirm, and deal with your user account;',
                d40: 'c) to send all new users a welcome activation email to verify their email address;',
                d41: 'd) (For Paid Users) to provide you with subscription services and contact you regarding your use of such services. This is necessary for us to implement your requests; and',
                d42: 'e) Paid Users will also receive a notification email from PayPal if there are any issues regarding billing their cards for auto-renew subscriptions. Users cannot opt out of these messages, though they can delete their account.',
                d43: '3) User account deleting',
                d44: 'You may refuse to use our Website and/or application and may ask us to delete your account in the Profile settings. Your account will be deleted after 30 days. During this time, you can stop the Please note that if you have requested your account to be deleted, we will delete the subsequent personal data you have supplied us. However some data, such as published ideas/scripts and messages sent to other users, will be saved as they have already integrated into our system. This data must be stored to maintain the integrity of the platform, as well as for legitimate business processes, including audits and security purposes.',
                d45: 'Note that search engines, as well as third parties, can keep copies of your publicly available information once your account has been deleted. It should also be noted that published ideas will remain on the site and messages sent to other users cannot be deleted.',
                d46: '4) Marketing',
                d47: 'We may send you marketing emails about our products or services that are similar to the products or services you are subscribed to, including information about events and other promotions we feel may interest you. This is unless you have indicated to us that you do not wish to receive communications in this manner. We will send you other promotional information by email only with your consent, which was given at the time you provided us with your personal data. Users can opt out of receiving marketing communications at any time by clicking "unsubscribe" in one of the emails.',
                d48: '5) Social Notifications',
                d49: 'We can notify you via email about new users who have followed you, liked an idea, new ideas of users that you follow, and so on. You can refuse to receive such notifications at any time by adjusting your Profile settings.',
                d50: '6) Service-Related Announcements',
                d51: 'On rare occasions, it may be necessary to send out service-related announcements. For instance, if our service is interrupted for a prolonged period or a major functionality upgrade is released, we might send all users an email message.',
                d52: '7) Legitimate interests',
                d53: 'We will process your personal data as necessary for certain legitimate business interests, which include the following:',
                d54: 'a) where we are asked to respond to any of your inquiries, comments, or grievances;',
                d55: 'b) to administer our services in order to better understand how visitors interact with the Website and application, and ensure that they are presented in the most effective manner for you and your computer/device;',
                d56: 'c) to develop and improve our mobile applications;',
                d57: 'd) to share personal data among our affiliated businesses for administrative purposes, provide subscription services, and in relation to our sales and marketing activities, except where we require your consent, as described above;',
                d58: 'e) to send you information about our products or services that are similar to the products or services you subscribed to (unless you have refused or opted out of receiving these emails at the time you provided us with your email address or you have indicated to us that you do not wish to receive communications in this manner). Our legitimate interest is to ensure our marketing is relevant to you, so we may process your data to send you information on our products or services that is specifically tailored to your interests;',
                d59: 'f) we may anonymize, pseudonymize, aggregate and de-identify the data that we collect and use this data for our own internal business purposes, including sharing it with our business partners, our affiliated businesses, agents and other third parties for commercial, statistical and market research purposes. For example, to allow those parties to analyses patterns among groups of people and conducting research on demographics, interests, and behavior;',
                d60: 'g) for internal business/technical operations, including troubleshooting, data analysis, testing, research, statistical and survey purposes, and as part of our efforts to keep our Website and application, network, and information systems secure; and',
                d61: 'h )to (a) comply with legal obligations, (b) respond to requests from competent authorities; (c) enforce our Terms of Use or House Rules; (d) protect our operations or those of any of our affiliated businesses; (e) protect our rights, safety or property, and/or that of our affiliated businesses, you or others; and (f) enforce or defend legal rights, or prevent damage. This means we can store and disclose your information to law enforcement authorities, state, or government agencies if we establish that such disclosure is necessary to comply with the law. This includes responses to court orders or subpoenas, as well as other judicial or regulatory processes.',
                d62: 'As used in this privacy policy, “legitimate interests” means the interests of Alpha and our affiliated businesses in conducting and managing our business. When we process your personal data for our legitimate interests, we make sure to consider and balance any potential impact on you and your rights under data protection laws. Our legitimate interests do not automatically override your interests. We do not use personal data for activities where our interests override the impact they may have on our users, unless we have their explicit consent or those activities are otherwise required or permitted by law. You have the right to object to the processing of your personal data that is based on our legitimate interests at any time, on grounds relating to your particular situation.',
                d63: 'When would we share personal information',
                d64: 'We share your personal data with third parties in the following situations:',
                d65: 'a) Service providers: Alpha, like many businesses, occasionally engages with selected third parties who act on our behalf to support our operations, such as (i) card processing or payment services, (ii) IT suppliers and contractors (i.e. data hosting providers or delivery partners) as necessary to provide IT support and enable us to provide subscription services and other goods/services available on this Website and application or to users, (iii) web and mobile analytics providers, (iv) providers of digital advertising services, and (v) providers of marketing and sales software solutions. Pursuant to our instructions, these parties may access, process or store your personal data in the course of performing their duties to us and solely in order to perform the services we have hired them to provide.',
                d66: 'b) Payment information: Paid Users must provide payment information (name, payment card information or payment account information). This information is used for billing purposes only and is processed by our payment processors. When you are making a payment, we do not have access to your payment card information. Should we have any issues with processing an order, the email address you have provided will be used to contact you.',
                d67: 'c) Third Party Content Providers: When you buy market data from IDC or other stock exchanges, we give them your contact details, phone number, address, and email and you sign the agreement. This data is also processed by IDC or other stock exchanges in order to provide you with access to the exchanges.',
                d68: 'd) Alpha affiliated businesses: In order to provide the services you request from us, our affiliated businesses may access and process the information which we collect from you for the purposes described above, including offering products and services to you. Our affiliated businesses will only use your data for the purposes for which we originally collected said data.',
                d69: 'e) Brokers: When you fill out the Open account form for a broker, we send them your personal data filled in this form: your first and last name, phone number, email address, country. This data is processed by the broker to open your brokerage account.',
                d70: 'f) Analytics providers: We may use third-party service providers to monitor and analyze the use of our services and mobile applications. We use this information to analyze and improve the work of our services and mobile applications.',
                d71: 'g) Business transfers: If we sell our business or our company assets are acquired by a third party, personal data held by us about our general account users, subscription account users or customers may be one of the transferred assets.',
                d72: 'h) Administrative and legal reasons: If we need to disclose your personal data (i) to comply with a legal obligation and/or judicial or regulatory proceedings, a court order or other legal process, (ii) to enforce our Terms of Use, House Rules or other applicable contract terms that you are subject to, or (iii) to protect us or our contractors against loss or damage. This may include, but is not limited to, exchanging information with the police, courts or law enforcement organizations.',
                d73: 'i) Advertising: We display advertising on the Website and application and may use remarketing and tracking features. Remarketing features allow us to display advertising in a more targeted manner in order to present advertisements of interest to users, based on their activity on other websites. Tracking features allow us to collect information about impressions, ad clicks and certain actions that users complete through our Website and application. This data also helps us evaluate the performance of ad campaigns. Note that we collect pseudonymous data only.',
                d74: 'Note that we do not share any personal information with our advertisers. They can only access general statistics that include the amount of impressions their ads have generated as well as clicks.',
                d75: 'Security of personal data',
                d76: 'We use technical and organizational safeguards to protect your personal data and require third parties with whom we work to do the same.',
                d77: 'We use Transport Layer Security (TLS) encryption technology in order to protect certain information that you submit to us. This technology protects you from having your information intercepted by anyone while it is being transmitted to Alpha or payment processor. While on a secure page, such as our order form, the "lock" icon in the browser window is displayed, confirming that a secure and encrypted connection has been established with the Website and application. We work hard to ensure that our Service is secure and that we meet industry standards. We also use other safeguards, such as firewalls, authentication systems (i.e. passwords, and personal identification numbers), and access control mechanisms to control unauthorized access to systems and data. If you have chosen to create an account, you are responsible for doing everything you reasonably can to keep your access details secret. You must not share these details with anyone else.',
                d78: 'We also do our best to protect user information offline. All of our users\' personal information is restricted to our offices. Only employees who need to see the information to perform their jobs are allowed to access it.',
                d79: 'The servers that store personal data are located in a secure environment in a locked facility. We are constantly improving the ways we secure, store, and process the data we collect, including the addition of physical security measures that help us counter unauthorized access to our systems. However, no method of electronic transmission or storage is 100% secure. Therefore, we cannot guarantee its absolute security.',


            },

        },
        register: {
            firstName: 'First Name',
            lastName: 'Last Name',
            phoneNumber: 'Phone Number',
            select:'Select',
            email: 'Email',
            phone:'Phone',
            verificationCode:'Verification Code',
            getCode:'Get Code',
            password: 'Password',
            confirmPassword: 'Confirm Password',
            iDNumber: 'ID Number',
            submit: 'Submit',
            required: '* Required',
            step1: 'Register',
            step2: 'Open account',
            success1: 'Submitted successfully',
            success2: 'Account opening review will be completed within 1-3 working days',
            pass:'Account opening review has passed',
            noPass:'The account opening review has not passed',
            back:'Go back and resubmit',
            codeError:'Please enter correct verify code',
            getImgError:'It takes 5 seconds to get a new graphic captcha',
            d1: 'To verify your identity,we\'ll need to know your government-issued personal ID number or Passport.',
            d2: 'Identity Doc Front Side',
            d3: 'Identity Doc Back Side',
            d4: 'Browse Files',
            d5: ' or drag an image to upload',
            d6: 'OR',
            d7: 'Select a Sample Image',
            d8: 'By clicking on the “Submit” button below, you are agreeing to our ',
            d9: 'Privacy Policy',
            d10: '.',
            d11: 'Not a valid email address',
            d12:'* Select email or phone number as your login account',
            d13:'At least 8 characters long',
            d14:'Passwords do NOT match',
            AD: 'Andorra',
            AE: 'United Arab Emirates',
            AF: 'Afghanistan',
            AG: 'Antigua and Barbuda',
            AI: 'Anguilla',
            AL: 'Albania',
            AM: 'Armenia',
            AO: 'Angola',
            AR: 'Argentina',
            AT: 'Austria',
            AU: 'Australia',
            AZ: 'Azerbaijan',
            BB: 'Barbados',
            BD: 'Bangladesh',
            BE: 'Belgium',
            BF: 'Burkina-faso',
            BG: 'Bulgaria',
            BH: 'Bahrain',
            BI: 'Burundi',
            BJ: 'Benin',
            BL: 'Palestine',
            BM: 'BermudaIs.',
            BN: 'Brunei',
            BO: 'Bolivia',
            BR: 'Brazil',
            BS: 'Bahamas',
            BW: 'Botswana',
            BY: 'Belarus',
            BZ: 'Belize',
            CA: 'Canada',
            KY: 'CaymanIs.',
            CF: 'Central African Republic',
            CG: 'Congo',
            CH: 'Switzerland',
            CK: 'Cook Is.',
            CL: 'Chile',
            CM: 'Cameroon',
            CN: 'China',
            CO: 'Colombia',
            CR: 'Costa Rica',
            CU: 'Cuba',
            CY: 'Cyprus',
            CZ: 'Czech Republic',
            DE: 'Germany',
            DJ: 'Djibouti',
            DK: 'Denmark',
            DO: 'Dominica Rep.',
            DZ: 'Algeria',
            EC: 'Ecuador',
            EE: 'Estonia',
            EG: 'Egypt',
            ES: 'Spain',
            ET: 'Ethiopia',
            FI: 'Finland',
            FJ: 'Fiji',
            FR: 'France',
            GA: 'Gabon',
            GB: 'United Kingdom',
            GD: 'Grenada',
            GE: 'Georgia',
            GF: 'French Guiana',
            GH: 'Ghana',
            GI: 'Gibraltar',
            GM: 'Gambia',
            GN: 'Guinea',
            GR: 'Greece',
            GT: 'Guatemala',
            GU: 'Guam',
            GY: 'Guyana',
            HK: 'Hong Kong',
            HN: 'Honduras',
            HT: 'Haiti',
            HU: 'Hungary',
            ID: 'Indonesia',
            IE: 'Ireland',
            IL: 'Israel',
            IN: 'India',
            IQ: 'Iraq',
            IR: 'Iran',
            IS: 'Iceland',
            IT: 'Italy',
            CI: 'Ivory Coast',
            JM: 'Jamaica',
            JO: 'Jordan',
            JP: 'Japan',
            KE: 'Kenya',
            KG: 'Kyrgyzstan',
            KH: 'Kampuchea(Cambodia)',
            KP: 'North Korea',
            KR: 'Korea',
            KT: 'Republic of Ivory Coast',
            KW: 'Kuwait',
            KZ: 'Kazakstan',
            LA: 'Laos',
            LB: 'Lebanon',
            LC: 'St.Lucia',
            LI: 'Liechtenstein',
            LK: 'SriLanka',
            LR: 'Liberia',
            LS: 'Lesotho',
            LT: 'Lithuania',
            LU: 'Luxembourg',
            LV: 'Latvia',
            LY: 'Libya',
            MA: 'Morocco',
            MC: 'Monaco',
            MD: 'Republic of Moldova',
            MG: 'Madagascar',
            ML: 'Mali',
            MM: 'Burma',
            MN: 'Mongolia',
            MO: 'Macao',
            MS: 'Montserrat Is',
            MT: 'Malta',
            MQ: 'Martinique',
            MU: 'Mauritius',
            MV: 'Maldives',
            MW: 'Malawi',
            MX: 'Mexico',
            MY: 'Malaysia',
            MZ: 'Mozambique',
            NA: 'Namibia',
            NE: 'Niger',
            NG: 'Nigeria',
            NI: 'Nicaragua',
            NL: 'Netherlands',
            NO: 'Norway',
            NP: 'Nepal',
            AN: 'Netherlands Antilles',
            NR: 'Nauru',
            NZ: 'New Zealand',
            OM: 'Oman',
            PA: 'Panama',
            PE: 'Peru',
            PF: 'French Polynesia',
            PG: 'Papua New Guinea',
            PH: 'Philippines',
            PK: 'Pakistan',
            PL: 'Poland',
            PR: 'Puerto Rico',
            PT: 'Portugal',
            PY: 'Paraguay',
            QA: 'Qatar',
            RE: 'Reunion',
            RO: 'Romania',
            RU: 'Russia',
            SA: 'Saudi Arabia',
            SB: 'Solomon Is',
            SC: 'Seychelles',
            SD: 'Sudan',
            SE: 'Sweden',
            SG: 'Singapore',
            SI: 'Slovenia',
            SK: 'Slovakia',
            SL: 'Sierra Leone',
            SM: 'San Marino',
            AS: 'Samoa Eastern',
            WS: 'San Marino',
            SN: 'Senegal',
            SO: 'Somali',
            SR: 'Suriname',
            ST: 'Sao Tome and Principe',
            SV: 'El Salvador',
            SY: 'Syria',
            SZ: 'Swaziland',
            TD: 'Chad',
            TG: 'Togo',
            TH: 'Thailand',
            TJ: 'Tajikistan',
            TM: 'Turkmenistan',
            TN: 'Tunisia',
            TO: 'Tonga',
            TR: 'Turkey',
            TT: 'Trinidad and Tobago',
            TW: 'Taiwan',
            TZ: 'Tanzania',
            UA: 'Ukraine',
            UG: 'Uganda',
            US: 'United States of America',
            UY: 'Uruguay',
            UZ: 'Uzbekistan',
            VC: 'Saint Vincent',
            VE: 'Venezuela',
            VN: 'Vietnam',
            YE: 'Yemen',
            YU: 'Yugoslavia',
            ZA: 'South Africa',
            ZM: 'Zambia',
            ZR: 'Zaire',
            ZW: 'Zimbabwe',
        },
        login:{
            username:'Phone Number / Email',
            password:'Password',
            login:'Log in',
            required: '* Required',
            have:'Already have an account, log in',
            other:'Login to another account',
            create:'Create new account'
        }
    }
}