import Vue from "vue";

Vue.prototype.$codeTimeGo = function () {
    this.$store.state.codeTime = 60;
    let that = this;
    let timeGo = setInterval(() => {
        if (that.$store.state.codeTime <= 0) {
            clearInterval(timeGo);
        } else {
            that.$store.state.codeTime -= 1;
        }
    }, 1000);
}

let channel = 1;
let device = 1;
let p = navigator.platform;
let u = navigator.userAgent;
let is_android = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1;
let is_ios = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
if (p == "Win32" || p == "Win64" || p == "MacPPC" || p == "MacIntel" || p == "X11" || p == "Linux i686") {//PC调试环境
    device = 1;
} else {
    if (is_ios) {//IOS终端
        device = 2;
    } else if (is_android) {//Android终端
        console.log('Mobile环境--Android移动端');
        device = 3;
    }
}

Vue.prototype.$addQuery = function (data) {
    data.channel = channel;
    data.device = device;
    return data;

}