<template>
  <div>

    <div v-for="(item,index) in menuList[routeIndex]" :key="index">
      <div style="margin-bottom: 4px">
        <router-link :to="item.path" active-class="active">{{ item.name }}</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "routerPage",
  props: {
    routeIndex: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      menuList: []
    }
  },
  mounted() {
    this.menuList = [
      [{
        name: this.$t('views.header.cryptocurrencies.d1'),
        path: '/bitcoin-perpetual-futures'
      }, {
        name: this.$t('views.header.cryptocurrencies.d2'),
        path: '/bitcoin-monthly-futures'
      },],
      [{
        name: this.$t('views.header.preciousMetals.d1'),
        path: '/gold-futures'
      }, {
        name: this.$t('views.header.preciousMetals.d2'),
        path: '/silver-futures'
      },],
      [{
        name: this.$t('views.header.forex.d1'),
        path: '/usd-cnh-futures'
      }],
      [{
        name:this.$t('views.header.commodities.d1'),
        path: '/palm-olein-futures'
      }, {
        name: this.$t('views.header.commodities.d2'),
        path: '/crude-palm-oil-futures'
      },
        {
          name: this.$t('views.header.commodities.d3'),
          path: '/fuel-oil-futures'
        }, {
        name: this.$t('views.header.commodities.d4'),
        path: '/low-sulphur-fuel-oil-futures'
      },]
    ]
  }

}
</script>

<style lang="scss" scoped>
a {
  padding-left: 20px;
  transition: all 0.3s ease-out;
  color: #666;
  font-size: 14px;
  outline: none;
  &:hover {
    border-left: 5px solid #2096A2;
    color: #2096A2;
    font-weight: bold;
  }
}

.active {
  border-left: 5px solid #2096A2;
  color: #2096A2;
  font-weight: bold;
}
</style>