<template>
  <div class="carouselFrame">
    <!--    <el-carousel class="carousel" arrow="never" indicator-position="none" height="700px" :interval="500">-->
    <!--      <el-carousel-item v-for="(item,index) in imgList" :key="index">-->
    <!--        <img :src="item">-->
    <!--      </el-carousel-item>-->
    <!--    </el-carousel>-->
    <img ref="index" :src="item" v-for="(item,index) in imgList" :key="index" alt="">

    <div :class="'title title'+index" :ref="'title'+index" v-for="(item,index) in titleList"
         :key="'title'+index">
      <p style="word-break : break-word;">{{ item.title }}</p>
      <p class="subtitle" style="word-break : break-word;">{{ item.subTitle }}</p>
    </div>
  </div>
</template>

<script>



export default {
  name: "carousel",
  data() {
    return {
      imgList: [
        require('@/assets/img/home/homapage-top-banner1.png'),
        require('@/assets/img/home/homapage-top-banner2.png'),
      ],
      titleList: [],
      interval: null,
      index: 0
    }
  },
  mounted() {
    this.titleList=[
      {
        title: this.$t('views.home.title0'),
        subTitle: this.$t('views.home.subTitle'),
      },
      {
        title:  this.$t('views.home.title1'),
        subTitle: ''
      },
    ];
    let that = this;
    this.interval = setInterval(() => {
      if (that.index < that.imgList.length - 1) {
        for (let i = 0; i < that.imgList.length; i++) {
          that.$refs.index[i].style.cssText = 'transform: translateX(-' + (1 + that.index) + '00%);transition: 1s ease;'
        }
        for (let i = 0; i < that.titleList.length; i++) {

          that.$refs['title' + i][0].style.cssText = 'left:' + (that.index - 1 + i) + '50%;transition: 1s ease;'
        }
        that.index += 1;
        if (!(that.index < that.imgList.length - 1)) {
          setTimeout(() => {
            for (let i = 0; i < that.imgList.length - 1; i++) {
              if (that.$refs.index[i]) {
                that.$refs.index[i].style.cssText = 'transform: translateX(100%);transition: 0'
              }
            }
            for (let i = 0; i < that.titleList.length - 1; i++) {
              if (that.$refs['title' + i]) {
                that.$refs['title' + i][0].style.cssText = 'left:' + (i+1) + '50%;transition: 0'
              }
            }
          }, 1500)
        }
      } else {
        for (let i = 0; i < that.imgList.length; i++) {
          if (i === that.imgList.length - 1) {
            that.$refs.index[i].style.cssText = 'transform: translateX(-' + (1 + that.index) + '00%);transition: 1s ease;'
            that.$refs['title' + i][0].style.cssText = 'left:-' + (that.index) + '50%;transition: 1s ease;'
          } else {
            that.$refs.index[i].style.cssText = 'transform: translateX(0);transition: 1s ease;'
            that.$refs['title' + i][0].style.cssText = 'left:' + (i) + '50%;transition: 1s ease;'

          }
        }
        that.index = 0;
        setTimeout(() => {
          that.$refs.index[that.imgList.length - 1].style.cssText = 'transform: translateX(0);transition: 0'
          that.$refs['title' + (that.titleList.length - 1)][0].style.cssText = 'left:' + (that.titleList.length - 1) + '50%;transition: 0'
        }, 1500)

      }
    }, 5000)
  },
  beforeDestroy() {
    clearInterval(this.interval);
  }
}
</script>

<style lang="scss" scoped>
.carouselFrame {
  width: 100%;
  display: flex;
  overflow-x: hidden;
  height: 36vw;
  position: relative;

  img {
    width: 100vw;
    height: 36vw;
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .title {
    font-size: clamp(30px, 8vw, 58px);
    font-weight: 700;
    position: absolute;
    width: 90%;
    top: 40%;
    transform: translateX(-50%);
    text-align: center;
    text-shadow: 0 1px 10px rgba(0, 0, 0, 1);
    word-wrap: break-word;
    animation: title 1s ease;
    word-break: break-word;

    .subtitle {
      font-size: clamp(18px, 3vw, 25px);
      font-weight: 300;
      margin-top: 32px;
      text-align: center;
      text-shadow: 0 1px 3px rgba(0, 0, 0, .3);
      word-wrap: break-word;

    }
  }

  @keyframes title {
    0% {
      opacity: 0;
      transform: translate(-50%, 100%);
    }
    100% {
      opacity: 1;
      transform: translate(-50%, 0);
    }
  }
}

.title0 {
  left: 50%;

}

.title1 {
  left: 150%;

}

.title2 {
  left: 250%;

}

.title3 {
  left: 350%;

}

.title4 {
  left: 450%;

}

</style>