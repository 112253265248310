module.exports = {
    views: {
        home: {
            title0: '亞洲第一個受監管的比特幣永續期貨',
            subTitle: '您受監管的期貨交易場所！',
            title1: '以克為單位用全球價格交易黃金',
            d1: '交易統計',
            d2: '24小時交易金額',
            d3: '10.18M',
            d4: '每週交易量',
            d5: '57.98M',
            d6: '30天交易金額',
            d7: '368.04M',
            d8: '我們的比特幣指數，Compass Crypto Real Time Index Bitcoin，由 COMPASS Financial Technologies（法國）提供，該公司根據歐盟基準條例（EU BMR）由 Autorité des Marchés Financiers（AMF）註冊為基準管理員。',
            d9: '查看免責聲明',
            d10: '我們的產品',
            d11: '比特幣永續期貨',
            d12: '比特幣月度期貨',
            d13: '了解更多',
            d14: '今天就通過持牌交易所開始您的投資之旅',
            d15: '與 APEX 產品專家取得聯繫',
            d16: '在 APEX 成員之一開立賬戶',
            disclaimer: '保留所有權利。 Compass 擁有關於 Compass Crypto Reference Index Bitcoin（“指數”）的所有專有權利。 任何基於或與指數相關的第三方產品（“產品”）只能在 Compass 事先書面批准並在 Compass 與打算推出產品的一方之間簽署許可協議後發布。 Compass 絕不會贊助、認可或以其他方式參與產品的發行和提供，或者它是否就投資於產品的可取性向產品持有人或任何公眾做出任何明示或暗示的陳述或保證 一般的產品或商品或特別是期貨，或關於使用指數或產品獲得的結果。 Compass 不對指數所依據的數據的任何不准確、指數計算和/或傳播中的任何錯誤、錯誤、遺漏或中斷，或指數在其中的應用方式承擔任何責任。 與產品的發行和提供有關。 在任何情況下，Compass 均不對任何利潤損失或間接、懲罰性、特殊或後果性損害或損失承擔任何責任，即使已被告知發生這種情況的可能性。 這不是購買或出售任何證券或投資的要約或要約邀請。 Compass Crypto Reference Indices 過去的表現並不一定代表未來的結果'

        },
        header: {
            cryptocurrencies: {
                title: '加密貨幣',
                d1: '比特幣永續期貨',
                d2: '比特幣月度期貨'
            },
            preciousMetals: {
                title: '貴金屬',
                d1: '黃金期貨',
                d2: '白銀期貨'
            },
            forex: {
                title: '外匯',
                d1: 'USD/CNH 期貨',

            },
            commodities: {
                title: '商品',
                d1: '棕櫚油精期貨',
                d2: '毛棕櫚油期貨',
                d3: '燃料油期貨',
                d4: '低硫燃料油期貨'
            },
            btn: '與 Alpha 交易'
        },
        pages: {
            topTitle: '交易',
            cryptocurrencies: {
                bitcoinPerpetualFutures: {
                    title: '比特幣永續期貨',
                    d1: '加密貨幣在投資者中獲得了很大的吸引力，顯示出更廣泛採用的跡象。 比特幣作為第一種加密貨幣，仍然是最受歡迎和最有價值的數字資產之一，儘管加密貨幣的數量不斷增加。',
                    d2: 'APEX 比特幣永續期貨合約讓您可以管理加密貨幣的風險，並在這個不斷增長的資產類別中獲得有價值的敞口。 它為機構客戶提供多樣化的交易組合，作為期貨合約，它可以作為風險最小化工具，幫助您的投資組合抵禦加密貨幣的價格波動。',
                    d3: '注意：該產品不適合散戶投資者，因為與其他資產類別相比，標的資產的價格波動性要高得多。 因此，請聯繫 APEX 會員，讓他們評估您是否適合交易該產品。',
                    tableTh: 'APEX 比特幣永續期貨合約規格',
                    column_1_01: '合同代碼',
                    column_1_02: '合約大小',
                    column_1_03: '報價貨幣',
                    column_1_04: '最低價格變動',
                    column_1_05: '勾選值',
                    column_1_06: '合同系列',
                    column_1_07: '交易時間',
                    column_1_08: '最後交易日',
                    column_1_09: '每日價格限制',
                    column_1_10: '每日結算價',
                    column_1_11: '資金費',
                    column_1_12: '資金間隔',
                    column_1_13: '最終結算價',
                    column_1_14: '結算方式',
                    column_1_15: '大宗交易',
                    column_1_16: '初始保證金',
                    column_1_17: '最大訂單金額',

                    column_2_01: 'BTCP',
                    column_2_02: '50 毫比特幣/lot (0.05 BTC = 50 毫比特幣)',
                    column_2_03: 'USD',
                    column_2_04: 'US $0.005/毫比特幣',
                    column_2_05: 'US $0.25',
                    column_2_06: '永久合同',
                    column_2_07: '新加坡時段交易時段:',
                    column_2_08: '週一至週五，包括新加坡的公共假期',
                    column_2_09: 'T 時段:',
                    column_2_10: '06:55 - 06:59（開市前時段）',
                    column_2_11: '06:59 ~ 07:00 (集合競價)',
                    column_2_12: '07:00 – 18:00（日间交易时间段）',
                    column_2_13: 'T+1 時段:',
                    column_2_14: '19:25 - 19:29（開市前時段）',
                    column_2_15: '19:29 ~ 19:30 (集合競價)',
                    column_2_16: '19:30 - 05:30（夜場）',
                    column_2_17: 'T 代表給定的交易日',
                    column_2_18: '日間時段結束是 T 的結束。',
                    column_2_19: '沒有有效期。',
                    column_2_20: '除交易所另有規定外，無每日漲跌幅限制。',
                    column_2_21: '給定日期的每日結算價格等於 Compass Financial Technologies SA（“Compass”）在 18:00（GMT+8）公佈的 Compass Crypto Reference Index Bitcoin (CCRIBTC) 價格。 每日結算價格四捨五入至最接近的 0.005 美元/milliBitcoin。 ',
                    column_2_22: '資金由持倉者完成您收取或支付使用資金費率計算的資金費的地方。 這適用於每個 T 時段和 T+1 時段結束時的所有未平倉頭寸。 資金費用在每個交易日結束的收盤日清算週期內計算和收取。',
                    column_2_23: '公式為: 資金費用 = 未平倉合約數量 x 合約規模 x Compass 加密貨幣實時指數比特幣 (CCRTBTC)，單位為 milliBitcoin x 資金費率 資金費率和方法公佈在 APEX 網站上。 ',
                    column_2_24: 'T 時段:18:00 (GMT+8)',
                    column_2_25: 'T+1 時段:05:30 (GMT+8)',
                    column_2_26: '沒有有效期。',
                    column_2_27: '根據每日結算價以美元現金結算',
                    column_2_28: '至少 20 手',
                    column_2_29: '基於 SPAN',
                    column_2_30: '500 手',


                },
                bitcoinMonthlyFutures: {
                    title: '比特幣月度期貨',
                    d1: '加密貨幣已經引起了投資者的廣泛關注，並顯示出被廣泛採用的跡象。 比特幣是第一種加密貨幣，儘管加密貨幣數量不斷增加，但它仍然是最受歡迎和最有價值的數字資產之一。',
                    d2: 'APEX 比特幣月度期貨合約使您能夠管理加密風險並在這一不斷增長的資產類別中獲得有價值的敞口。 它為機構客戶提供了交易組合的多樣化，並且由於它是一種期貨合約，它可以作為風險最小化工具來幫助您的投資組合抵禦加密貨幣的價格波動。',
                    d3: '注意:該產品不適合散戶投資者，因為標的資產的波動性遠高於其他資產類別。 因此，請聯繫 APEX 成員公司以評估您是否適合交易該產品。',
                    tableTh: 'APEX 比特幣月度期貨合約規格',
                    column_1_01: '合同代碼',
                    column_1_02: '合約大小',
                    column_1_03: '報價貨幣',
                    column_1_04: '最低價格變動',
                    column_1_05: '勾選值',
                    column_1_06: '合同系列',
                    column_1_07: '交易時間',
                    column_1_08: '最後交易日',
                    column_1_09: '每日價格限制',
                    column_1_10: '每日結算價',
                    column_1_11: '最終結算價',
                    column_1_12: '結算方式',
                    column_1_13: '大宗交易',
                    column_1_14: '初始保證金',
                    column_1_15: '最大訂單金額',

                    column_2_01: 'BTC',
                    column_2_02: '50 毫比特幣/lot (0.05 BTC = 50 毫比特幣)',
                    column_2_03: 'USD',
                    column_2_04: 'US $0.005/毫比特幣',
                    column_2_05: 'US $0.25',
                    column_2_06: '過去連續 6 個月',
                    column_2_07: '新加坡時段交易時段:',
                    column_2_08: '週一至週五，包括新加坡的公共假期',
                    column_2_09: 'T 時段:',
                    column_2_10: '06:55 - 06:59（開市前時段）',
                    column_2_11: '06:59 ~ 07:00 (集合競價)',
                    column_2_12: '07:00 – 18:00（日间交易时间段）',
                    column_2_13: 'T+1 時段:',
                    column_2_14: '19:25 - 19:29（開市前時段）',
                    column_2_15: '19:29 ~ 19:30 (集合競價)',
                    column_2_16: '19:30 - 05:30（夜場）',
                    column_2_17: 'T代表給定的交易日。 日間交易時段 的結束是T的結束',
                    column_2_18: '合約的最後交易日是合約月份的最後一個星期五',
                    column_2_19: '除交易所另有規定外，無每日漲跌幅限制。',
                    column_2_20: '給定日期的每日結算價格是當天交易最後一分鐘發生的所有交易的成交量加權平均值，或清算所確定的其他方法。 每日結算價格四捨五入至最接近的 0.005 美元/milliBitcoin。 ',
                    column_2_21: '最終結算價格等於 Compass Financial Technologies SA（“Compass”）在最後一個交易日 18:00（GMT+8）公佈的 Compass Crypto Reference Index Bitcoin (CCRIBTC) 價格。 最終結算價四捨五入至最接近的 0.005 美元/milliBitcoin。 ',
                    column_2_22: '美元現金支付',
                    column_2_23: '至少 20 手',
                    column_2_24: '基於 SPAN',
                    column_2_25: '500 手',


                }
            },
            preciousMetals: {
                goldFutures: {
                    title: '黃金期貨',
                    d1: '黃金，長期以來一直被視為避險資產，通常被用作投資對沖工具。 憑藉其有限的供應和堅不可摧的特性，黃金是一種很好的保值手段。 APEX 黃金永續期貨合約可作為滿足您對這種貴金屬的交易、對沖和風險管理需求的工具。',
                    tableTh: 'APEX 黃金永續合約規範(V2021.08.06)',
                    column_1_01: '合同代碼',
                    column_1_02: '合約大小',
                    column_1_03: '報價貨幣',
                    column_1_04: '最低價格變動',
                    column_1_05: '勾選值',
                    column_1_06: '合同系列',
                    column_1_07: '交易時間',
                    column_1_08: '最後交易日',
                    column_1_09: '每日價格限制',
                    column_1_10: '每日結算價',
                    column_1_11: '最終結算價',
                    column_1_12: '結算方式',
                    column_1_13: '隔夜利息',
                    column_1_14: '大宗交易',
                    column_1_15: '初始保證金',
                    column_1_16: '最大訂單數量',


                    column_2_01: 'AUP',
                    column_2_02: '100 克/批次',
                    column_2_03: 'USD',
                    column_2_04: 'US $0.01/克',
                    column_2_05: 'US $1',
                    column_2_06: '永久合同',
                    column_2_07: '倫敦黃金市場協會 (LBMA) 上午黃金公告日期',
                    column_2_08: 'T 時段:',
                    column_2_09: '06:55 至 06:59（開市前時段）',
                    column_2_10: '06:59hr – 07:00hr (集合競價)',
                    column_2_11: '07:00 - 18:00（日间交易时间段）',
                    column_2_12: 'T+1 時段:',
                    column_2_13: '20:25 - 20:29（開市前時段）',
                    column_2_14: '20:29 - 20:30（集合競價）',
                    column_2_15: '20:30 - 05:00（夜場）',
                    column_2_16: 'T 代表給定的交易日',
                    column_2_17: '日間時段結束是 T 的結束。',
                    column_2_18: '在非 LBMA 黃金上午公開日或非 LBMA 黃金上午公開日之前的交易日沒有 T+1 時段。',
                    column_2_19: '不適用，因為沒有到期。',
                    column_2_20: '前一交易日每日結算價的+/-10%',
                    column_2_21: '給定日期的每日結算價必須等於 IBA 發布的 LBMA 黃金上午價格，四捨五入為 0.01 美元/克。 ',
                    column_2_22: '不適用，因為沒有到期。',
                    column_2_23: '美元現金結算與每日結算價',
                    column_2_24: '隔夜利息適用於會話 T 結束時的所有未平倉頭寸。 隔夜利息在每個交易日結束時的收盤日清算週期內計算和收取。',
                    column_2_25: '公式如下',
                    column_2_26: '未平倉合約數量 x 合約大小 x 每日結算價 x (隔夜利息) ÷ 365',
                    column_2_27: '用於計算隔夜利息的隔夜利息公佈在 APEX 網站上。',
                    column_2_28: '最少 30 手',
                    column_2_29: '基於 SPAN',
                    column_2_30: '500 手',


                },
                silverFutures: {
                    title: '白銀期貨',
                    d1: '與黃金相比，白銀的工業需求更高，尤其是在攝影和醫療領域。與黃金有著高度的相關性，白銀同樣被視為避險資產和有用的對沖工具。APEX白銀永續期貨合約是一種工具，可滿足貴金屬的交易、對沖和風險管理需求。',
                    tableTh: 'APEX 白銀永續期貨合約規格',
                    column_1_01: '合同代碼',
                    column_1_02: '合約大小',
                    column_1_03: '報價貨幣',
                    column_1_04: '最低價格變動',
                    column_1_05: '勾選值',
                    column_1_06: '合同系列',
                    column_1_07: '交易時間',
                    column_1_08: '最後交易日',
                    column_1_09: '每日價格限制',
                    column_1_10: '每日結算價',
                    column_1_11: '最終結算價',
                    column_1_12: '結算方式',
                    column_1_13: '隔夜利息',
                    column_1_14: '大宗交易',
                    column_1_15: '初始保證金',
                    column_1_16: '最大訂單數量',


                    column_2_01: 'AGP',
                    column_2_02: '5 kg/批次',
                    column_2_03: 'USD',
                    column_2_04: 'US $0.2/公斤',
                    column_2_05: 'US $1',
                    column_2_06: '永久合同',
                    column_2_07: '倫敦黃金市場協會 (LBMA) 白銀價格公佈日期',
                    column_2_08: 'T 時段:',
                    column_2_09: '06:55 至 06:59（開市前時段）',
                    column_2_10: '06:59hr – 07:00hr (集合競價)',
                    column_2_11: '07:00 - 18:00（日間交易時段）',
                    column_2_12: 'T+1 時段:',
                    column_2_13: '20:25 - 20:29（開市前時段）',
                    column_2_14: '20:29 - 20:30（集合競價）',
                    column_2_15: '20:30 - 05:00（夜場）',
                    column_2_16: 'T 代表給定的交易日',
                    column_2_17: '日間時段結束是 T 的結束。',
                    column_2_18: '在非 LBMA 白銀髮行日或非 LBMA 白銀髮行日之前的交易日沒有 T+1 時段。',
                    column_2_19: '不適用，因為沒有到期。',
                    column_2_20: '前一交易日每日結算價的+/-15%',
                    column_2_21: '特定日期的每日結算價必須等於 IBA 公佈的 LBMA 白銀價格，四捨五入為 0.2 美元/公斤',
                    column_2_22: '不適用，因為沒有到期。',
                    column_2_23: '美元現金結算與每日結算價',
                    column_2_24: '隔夜利息適用於會話 T 結束時的所有未平倉頭寸。 隔夜利息在每個交易日結束時的收盤日清算週期內計算和收取。',
                    column_2_25: '公式如下',
                    column_2_26: '未平倉合約數量 x 合約大小 x 每日結算價 x (隔夜利息) ÷ 365',
                    column_2_27: '用於計算隔夜利息的隔夜利息公佈在 APEX 網站上。',
                    column_2_28: '最少 50 手',
                    column_2_29: '基於 SPAN',
                    column_2_30: '500 手',


                }
            },
            forex: {
                USDCNHFutures: {
                    title: 'USD/CNH 期貨',
                    d1: '隨著人民幣國際化進程的加快，人民幣的國際地位不斷提升。人民幣廣泛用於世界貿易和投資，正成為日益重要的國際儲備貨幣之一。人民幣匯率形成機制改革步伐加快，國際貿易流量加大，匯率雙向波動和波動趨勢加劇。',
                    tableTh: 'APEX 美元/離岸人民幣期貨合約規格',
                    column_1_01: '合同代碼',
                    column_1_02: '股票代碼',
                    column_1_03: '合約大小',
                    column_1_04: '報價',
                    column_1_05: '最低價格變動',
                    column_1_06: '刻度值',
                    column_1_07: '合同系列',
                    column_1_08: '交易時間',
                    column_1_09: '最後交易日',
                    column_1_10: '每日價格限制',
                    column_1_11: '每日結算價',
                    column_1_12: '最終結算價',
                    column_1_13: '結算方式',
                    column_1_14: '大宗交易',
                    column_1_15: '初始保證金',
                    column_1_16: '最大訂單數量',


                    column_2_01: 'UC',
                    column_2_02: '合同用 UCyymmW# 表示，其中“yy”、“mm”和“W#”代表合同到期的年、月和序號周。 出於象徵意義，該月的第一周是該月的第一周，星期三。',
                    column_2_03: '示例:UC1901W3 代表在 2019 年 1 月的第三周到期的合同。',
                    column_2_04: '10,000 美元',
                    column_2_05: 'CNH 兌 USD',
                    column_2_06: 'CNH 0.0001（小數點後 4 位）',
                    column_2_07: 'CNH 1',
                    column_2_08: '交易所應列出 4 個在接下來連續 4 週內到期的合約，另外 3 個合約在接下來連續 3 個月的第三周到期，以及接下來的 3 個合約在接下來連續 3 個月的第三周到期。 在接下來的三個季度月（三月、六月、九月、十二月）的第三周到期。',
                    column_2_09: '當合約到期時，交易所必須在下一個交易日開市前時段列出新合約。',
                    column_2_10: '新加坡時段交易時段:新加坡營業日',
                    column_2_11: '週一至週五，上午 8:55 - 8:59（新加坡公共假期除外）',
                    column_2_12: '08:59am – 09:00am（日間時段開幕日）',
                    column_2_13: '09:00am – 18:00pm (日間交易時段) 合約交易於最後交易日上午 11:00 結束',
                    column_2_14: '如果是香港營業日和新加坡營業日，合約的最後交易日是合約週的星期一，否則如果是香港營業日和新加坡營業日，則為最早的次日天。 下午。 儘管有上述規定，交易所保留通過通知確定最終交易日期的權利。',
                    column_2_15: '除交易所另有規定外，無每日漲跌幅限制。',
                    column_2_16: '給定日期的每日結算價格是當天交易時段最後 5 分鐘內發生的所有交易的成交量加權平均值，或清算所確定的其他方法。',
                    column_2_17: '最終結算價必須等於香港財資及市場公會 (TMA) 在香港時間最後交易日上午 11:30 左右公佈的 USD/CNY (HK) 即期匯率，或其他設定的方法由票據交換所提出。',
                    column_2_18: 'CNH 現金結算',
                    column_2_19: '至少 200 手',
                    column_2_20: '基於 SPAN',
                    column_2_21: '500 手',


                },

            },
            commodities: {
                palmOleinFutures: {
                    title: '棕櫚油期貨',
                    d1: 'APEX 的目標是推出其第一個產品，即精煉棕櫚油期貨合約。 棕櫚油期貨的主要優勢包括: FOB通過位於馬六甲海峽沿岸的馬來西亞和印度尼西亞的印度港口進行實物交割。 馬來西亞和印度尼西亞佔全球棕櫚油產量的 85% 和國際棕櫚貿易的 91%。',
                    d2: '通過以美元作為定價貨幣，接受人民幣等貨幣作為可接受的保證金抵押品，APEX的棕櫚油精期貨合約符合國際貿易慣例，規避了貨幣價格波動的風險。',
                    d3: '隨著 APEX 的棕櫚油期貨合約即將推出，它旨在為全球棕櫚油貿易行業提供一個替代場所，並作為一種新的對沖和套利工具。',
                    tableTh: 'APEX RBD 棕櫚油精期貨合約規格 (V2019.04.03)',
                    column_1_01: '合同代碼',
                    column_1_02: '合約大小',
                    column_1_03: '報價貨幣',
                    column_1_04: '最低價格變動',
                    column_1_05: '交易時間',
                    column_1_06: '每日價格限制',
                    column_1_07: '合同系列',
                    column_1_08: '最後交易日',
                    column_1_09: '送貨方式',
                    column_1_10: '最小輸出尺寸',
                    column_1_11: '初始保證金',
                    column_1_12: '最後發貨日期',
                    column_1_13: '最大訂單金額',

                    column_2_01: 'PF',
                    column_2_02: '10MT/合約',
                    column_2_03: 'USD',
                    column_2_04: 'US$0.25 / MT',
                    column_2_05: '新加坡時段交易時段:新加坡營業日',
                    column_2_06: '週一至週五，不包括新加坡的公共假期',
                    column_2_07: ' ',
                    column_2_08: '08:55am – 08:59am（開市前時段）',
                    column_2_09: '08:59am – 09:00am（集合競價）',
                    column_2_10: '09:00am – 12:30pm (上午/第一輪)',
                    column_2_11: '13:30pm – 18:00pm（下午時段/第二次時段）',
                    column_2_12: '合約交易結束於最後交易日下午18:00',
                    column_2_13: '最終結算價的+/-10%。 每日價格限制不適用於合約的最後交易日。',
                    column_2_14: '月度合約（連續12個月合約）',
                    column_2_15: '如果是工作日則為前一個合約月份的第 15 個日曆日，否則為前一個工作日',
                    column_2_16: '從巴西古當（馬來西亞）、巴生港（馬來西亞）、拉哈達圖（馬來西亞）、勿拉灣（印度尼西亞）、杜邁（印度尼西亞）或其他經 APEX 批准的港口的實物交割離岸價',
                    column_2_17: '50 份合約（即總共 500 MT）',
                    column_2_18: '基於 SPAN（點擊查看保證金計劃）',
                    column_2_19: '最後交割月份',
                    column_2_20: '500 手',


                },
                crudePalmOilFutures: {
                    title: '毛棕櫚油期貨',
                    d1: ' APEX 毛棕櫚油期貨合約是棕櫚油系列中的第二個產品，與第一個產品 APEX RBD 棕櫚油精期貨合約具有相似的特徵。 APEX 毛棕櫚油期貨將與 APEX RBD 棕櫚油精期貨同時運作，並將增加棕櫚油市場的套利機會。',
                    tableTh: 'APEX 毛棕櫚油期貨合約規格',
                    column_1_01: '合同代碼',
                    column_1_02: '合約大小',
                    column_1_03: '報價貨幣',
                    column_1_04: '最低價格變動',
                    column_1_05: '交易時間',
                    column_1_06: '每日價格限制',
                    column_1_07: '合同系列',
                    column_1_08: '最後交易日',
                    column_1_09: '送貨方式',
                    column_1_10: '最小輸出尺寸',
                    column_1_11: '初始保證金',
                    column_1_12: '最後發貨日期',
                    column_1_13: '最大訂單金額',

                    column_2_01: 'CPF',
                    column_2_02: '10MT/合約',
                    column_2_03: 'USD',
                    column_2_04: 'US$0.25 / MT',
                    column_2_05: '新加坡時段交易時段:新加坡營業日',
                    column_2_06: 'Mon - Fri, 8:55 am - 08:59 am (不包括新加坡公共假期)',
                    column_2_07: '08:59am – 09:00am（集合競價）',
                    column_2_08: '09:00am – 12:30pm（上午時段）',
                    column_2_09: '13:30pm – 18:00pm（下午時段）',
                    column_2_10: ' ',
                    column_2_11: '合約交易結束於最後交易日下午18:00',
                    column_2_12: '最終結算價的+/-10%。 每日價格限制不適用於合約的最後交易日。',
                    column_2_13: '月度合約（連續12個月合約）',
                    column_2_14: '第一個合約月份的 15 日（如果是工作日則為前一個工作日）',
                    column_2_15: '到 APEX 網站附件 A 中列出的所有 APEX 批准港口的 FOB 實物交付',
                    column_2_16: '50 份合約（即總共 500 MT）',
                    column_2_17: '基於 SPAN',
                    column_2_18: '印度月的最後一個日曆日',
                    column_2_19: '每筆訂單 500 份合約',

                },
                fuelOilFutures: {
                    title: '燃料油期貨',
                    d1: '該合約目前處於休眠狀態，無法交易。 請直接聯繫 APEX 或您的經紀人了解詳情。',
                    d2: '新加坡目前是世界上最大的加油港，每年輸送約 5000 萬噸海上燃料油。 海運業是新加坡經濟的重要組成部分，佔新加坡國內生產總值 (GDP) 的 7% 左右。',
                    d3: '由於燃料成本仍然是航運公司最大的成本項目，船舶燃料價格的巨大價格波動可能會對許多公司的運營成本產生負面影響，並可能對新加坡經濟產生連鎖反應。',
                    d4: '鑑於當前形勢，APEX推出了380cst燃料油期貨合約，為能源行業和金融機構打造了一個對沖和投資燃料油市場的平台',
                    tableTh: 'APEX 380cst 燃料油期貨合約規格',
                    column_1_01: '合同代碼',
                    column_1_02: '合約大小',
                    column_1_03: '報價貨幣',
                    column_1_04: '最低價格變動',
                    column_1_05: '交易時間',
                    column_1_06: '每日價格限制',
                    column_1_07: '合同系列',
                    column_1_08: '最後交易日',
                    column_1_09: '送貨方式',
                    column_1_10: '最小輸出尺寸',
                    column_1_11: '初始保證金',
                    column_1_12: '最大訂單金額',

                    column_2_01: 'FO',
                    column_2_02: '10MT/合約',
                    column_2_03: 'USD',
                    column_2_04: 'US$0.10 / MT',
                    column_2_05: '新加坡時段交易時段:新加坡營業日',
                    column_2_06: 'Mon - Fri, 8:55 am - 08:59 am (不包括新加坡公共假期)',
                    column_2_07: '08:59am – 09:00am（集合競價）',
                    column_2_08: '09:00am – 12:30pm (上午/第一輪)',
                    column_2_09: '13:30pm – 18:00pm (下午時段/第二時段) 合約交易於最後交易日下午18:00結束。',
                    column_2_10: '/-10% 的最終結算價。 每日價格限制不適用於合約的最後交易日。',
                    column_2_11: '月度合約（連續12個月合約）',
                    column_2_12: '初始合約月份的最後一個營業日，否則為前一個營業日',
                    column_2_13: '通過 FOB 或罐對罐移動從認可倉庫進行實物交割',
                    column_2_14: '100 份合約（即總共 1,000 MT）',
                    column_2_15: '基於 SPAN',
                    column_2_16: '每筆訂單 500 份合約',


                },
                lowSulphurFuelOilFutures: {
                    title: '低硫燃料油期貨',
                    d1: '該合約目前處於休眠狀態，無法交易。 請直接聯繫 APEX 或您的經紀人了解詳情。',
                    d2: '亞太交易所 (APEX) 於 2019 年 11 月 21 日晚上 9 點推出了低硫燃料油期貨合約（LFO 合約）。 LFO合約是全球首個促進低硫燃料油套期保值和FOB實物交割的可交割產品。 .',
                    tableTh: '低硫燃料油期貨合約聲明',
                    column_1_01: '合同代碼',
                    column_1_02: '合約大小',
                    column_1_03: '報價貨幣',
                    column_1_04: '最低價格變動',
                    column_1_05: '交易時間',
                    column_1_06: '每日價格限制',
                    column_1_07: '合同系列',
                    column_1_08: '最後交易日',
                    column_1_09: '最後發貨日期',
                    column_1_10: '送貨方式',
                    column_1_11: '最小輸出尺寸',
                    column_1_12: '初始保證金',
                    column_1_13: '最大訂單金額',

                    column_2_01: '低頻振盪器',
                    column_2_02: '10 MT/合約',
                    column_2_03: 'USD',
                    column_2_04: 'US $0.10 / MT',
                    column_2_05: '新加坡時段交易時段:新加坡營業日',
                    column_2_06: 'Mon - Fri, 8:55 am - 08:59 am (不包括新加坡公共假期)',
                    column_2_07: '08:59am – 09:00am（集合競價）',
                    column_2_08: '09:00am – 12:30pm (上午/第一輪)',
                    column_2_09: '13:30pm – 18:00pm (下午時段/第二時段) 合約交易於最後交易日下午18:00結束。',
                    column_2_10: '最終結算價的+/-10%',
                    column_2_11: '每日價格限制不適用於最後交易日的合約',
                    column_2_12: '月度合約（連續12個月合約）',
                    column_2_13: '第一個合約月份的最後一個營業日',
                    column_2_14: '合約月份的最後一天',
                    column_2_15: '通過 FOB 或罐對罐移動從認可倉庫進行實物交割',
                    column_2_16: '100 份合約（即總共 1,000 MT）',
                    column_2_17: '基於 SPAN',
                    column_2_18: '每筆訂單 500 份合約',

                },
            },
            privacyPolicy: {
                title: '隱私政策',
                d1: '總結:我們是 Alpha Fund Management Pte. 有限公司（“Alpha”、“我們”或“我們”）。 我們尊重您的隱私，並致力於保護您提供的信息的機密性。 我們僅在必要時與第三方共享您的個人數據，以便為您提供您所請求的服務和數據（如本隱私政策中所述）。',
                d2: '關於我們',
                d3: 'Alpha 是負責您的個人數據的數據控制者，並且是 Alpha 移動和桌面應用程序、Alpha 服務（每個“服務”）以及其中收集的所有信息的唯一所有者。 Alpha 通過多種方式從其用戶那裡收集信息（如本隱私政策中所述）。',
                d4: '本隱私政策適用於我們在您創建帳戶並使用我們的應用程序或服務時收集的個人數據（“您”或“您的”應作相應解釋）。 提供有關我們收集的個人數據、收集原因、使用方式、處理數據的法律依據、數據存儲方式以及信息共享方式的信息。',
                d5: '在本隱私政策中，“個人數據”是指與您有關的任何可以識別您身份的信息。',
                d6: '使用我們的服務或提交個人資料，即表示您同意接受本隱私政策的條款，因此請仔細閱讀。 如果您在任何時候不同意本隱私政策，您必須停止使用服務並刪除您的用戶帳戶。',
                d7: '我們收集的個人數據',
                d8: '您無需創建賬戶即可搜索和查看來自特定市場的信息，例如符號、報價、財務數據，以及來自 alpha 用戶的想法和公開數據。',
                d9: '1) 用戶帳號',
                d10: '如果您決定創建一個帳戶，您將被要求提供一些個人數據，以便我們可以為您提供訪問您受益的其他服務的權限。 您可以選擇提供有關您自己的其他信息，如下所述。',
                d11: '您必須在註冊時提供以下其中一項',
                d12: 'a) 你的用戶名，電子郵件地址，',
                d13: 'b) 以下社交互聯網服務之一的帳戶信息:Facebook、Twitter、Google、Yahoo!、LinkedIn 和',
                d14: 'c) 購買訂閱需要名字、姓氏和郵寄地址。',
                d15: 'd) 在某些情況下，您可能需要提供並驗證您的電話號碼。 您可以在個人資料設置中添加有關您自己的其他信息，但這不是必需的。',
                d16: '公開顯示以下信息:',
                d17: 'e) 您選擇用作用戶頭像、簽名、“關於我”、位置的圖像。',
                d18: 'f) 以下信息不公開顯示:',
                d19: 'g) 名字和姓氏、電話號碼、電子郵件、公司、地址和納稅人 ID。',
                d20: '2) 聯繫方式',
                d21: '當您通過電話、電子郵件或我們運營的任何其他服務訪問我們並與我們互動時，我們還會處理您選擇提供的任何個人數據。',
                d22: '3) 日誌文件',
                d23: '啟用網絡服務器日誌文件。 日誌文件中的記錄包括互聯網協議 (IP) 地址、瀏覽器類型、互聯網服務提供商、引用頁面、退出頁面、平台類型和日期/時間戳。 我們使用網絡服務器日誌文件來管理我們的網站，為網站規劃目的提供廣泛的流量信息，並確保遵守我們的服務條款。 IP 地址未鏈接到網絡服務器日誌文件中的個人數據。',
                d24: '4) 設備信息',
                d25: '我們收集有關您用於與我們的服務交互的設備的以下信息:您設備的 IP 地址（我們也可以推斷您連接的國家/地區）、操作系統和瀏覽器版本。 我們收集這些信息是為了防止垃圾郵件和濫用我們的服務。 IP 地址在某些司法管轄區可能被視為個人數據，並將根據本隱私政策進行處理。',
                d26: '5) 分析',
                d27: '當您使用我們的網站、移動和桌面應用程序或我們在其他網站上提供的小部件時，我們可能會自動收集分析信息。 此信息可能包括一般設備信息、設備標識符（例如，如果您使用的是 Android 設備，則為 Google 廣告 ID；如果您使用的是 iOS 設備，則為廣告標識符 (IDFA)）、網絡信息以及網站和應用程序。 互動事件。 我們僅收集匿名數據，並且此分析信息不包括您個人資料中的任何個人身份信息。 我們使用這些信息來分析和改進我們的服務和應用程序的運行，並進一步開發、測試和改進我們的服務。 為此，我們可能會使用內部分析和第三方服務提供商。 請參閱下面的分析提供商部分，了解有關我們使用第三方服務提供商的更多信息。',
                d28: '6) 從第三方收到的數據',
                d29: '當您使用社交互聯網服務憑據（Facebook、Twitter、Google、Yahoo!、LinkedIn）登錄您的帳戶時，該服務會向您發送您的用戶名和與您使用的憑據關聯的電子郵件地址。 對於第三方如何提供有關您的信息和處理您的個人數據，我們不控制也不承擔責任。',
                d30: '我們如何使用您的個人數據',
                d31: '我們以各種必要方式使用個人數據，以便為您提供您打算使用的服務、某些合法利益，並且這是遵守適用法律所必需的，如下所述。',
                d32: '如果有其他法律依據，我們通常不會依賴您的同意來允許處理您的個人數據。 如果我們依賴於您的同意，我們會在那時向您說明這一點。',
                d33: '1) 配置文件',
                d34: '配置文件是關於單個用戶的一組信息，詳細說明了觀看偏好。 用戶設置（圖表設置、工具欄、按鈕等）僅用於在使用網站、應用程序或用戶帳戶時自定義用戶體驗。 我們不會與第三方共享個人資料設置。',
                d35: '一旦您創建了一個帳戶，其他用戶或訪問者就可以訪問您的公開資料並查看您公開提供的關於您自己的任何信息。',
                d36: '2) 使用用戶帳戶和配置文件',
                d37: '我們通過以下方式使用您的聯繫信息，例如您的用戶名、電子郵件地址或電話號碼:',
                d38: 'a) 驗證您的用戶帳戶並確保我們服務的安全性並防止垃圾郵件、欺詐和濫用；',
                d39: 'b) 授權、驗證和處理您的用戶帳戶；',
                d40: 'c) 向所有新用戶發送歡迎激活電子郵件以確認他們的電子郵件地址；',
                d41: 'd) 提供訂閱服務（針對付費用戶）並就您對這些服務的使用與您聯繫。 這是滿足您的要求所必需的。 和',
                d42: 'e) 如果自動續訂訂閱的卡收費有任何問題，付費用戶也會收到來自 PayPal 的通知電子郵件。 用戶可以刪除他們的帳戶，但他們不能拒絕這些消息。',
                d43: '3) 刪除用戶帳號',
                d44: '您可以選擇不使用我們的網站和/或應用程序，並在您的個人資料設置中請求刪除您的帳戶。 您的帳戶將在 30 天后被刪除。 請注意，在此期間，如果您要求刪除帳戶，我們將刪除您提供給我們的任何後續個人數據。 但是，一些數據（例如發布的想法/腳本和發送給其他用戶的消息）已經合併並存儲在系統中。 必須存儲此數據以維護平台的完整性以及合法的業務流程，包括用於審計和安全目的。',
                d45: '如果您的帳戶被刪除，搜索引擎和第三方可能會公開您的信息副本。 發布的想法保留在網站上，發送給其他用戶的消息無法刪除。',
                d46: '4) 營銷',
                d47: '我們可能會發送關於我們的產品或服務的營銷電子郵件，這些電子郵件與您訂閱的產品或服務類似，包括您可能感興趣的活動和其他促銷信息。 除非您已向我們表明您不希望以這種方式接收通信。 我們只會在您向我們提供個人數據時徵得您的同意後，通過電子郵件向您發送其他促銷信息。 您可以隨時通過點擊我們其中一封電子郵件中的“取消訂閱”來取消訂閱營銷通訊。',
                d48: '5) 社交通知',
                d49: '您可以通過電子郵件通知我們有關關注您的新用戶、喜歡您的想法的用戶、您關注的用戶的新想法等。 您可以隨時通過調整個人資料設置來選擇不接收這些通知。',
                d50: '6) 服務相關通知',
                d51: '在極少數情況下，可能需要發送與服務相關的通知。 例如，當某項服務長時間停止服務或發布主要功能升級時，您可以向所有用戶發送電子郵件消息。',
                d52: '7) 合法利益',
                d53: '我們將根據某些合法商業利益的需要處理您的個人數據，包括:',
                d54: 'a) 如果您被要求回答您的問題、評論或申訴；',
                d55: 'b) 管理我們的服務，以更好地了解訪問者如何與我們的網站和應用程序互動，並確保它們以最有效的方式呈現給您和您的計算機/設備；',
                d56: 'c) 開發和改進移動應用；',
                d57: 'd) 出於管理目的在關聯公司之間共享個人數據，提供訂閱服務以及與銷售和營銷活動有關，除非如上所述需要您的同意',
                d58: 'e) 向您發送與您已訂閱的產品或服務類似的信息（如果您在向我們提供電子郵件地址時選擇退出或未選擇不接收這些電子郵件，或者如果您以這種方式退出）向我們表明您不希望收到我們的通訊）。 由於我們的合法利益是確保我們的營銷與您相關，我們可能會處理您的數據以向您發送有關我們的產品或服務的信息，這些信息專門針對您的興趣量身定制。',
                d59: 'f) 我們可能會對我們收集的數據進行匿名化、假名化、匯總和去識別化處理，並且為此目的，可能會將這些數據用於我們自己的內部業務目的，包括與我們的業務合作夥伴、關聯公司、代理和其他人共享這些數據第三方。可以使用。 用於商業、統計和市場研究目的。 例如，允許這些各方分析人群之間的模式，並對人口統計、興趣和行為進行研究。',
                d60: 'g) 用於我們的內部業務/技術運營，包括用於故障排除、數據分析、測試、研究、統計和調查目的，以及作為我們努力確保我們的網站和應用程序、網絡和信息系統安全的一部分。 和',
                d61: 'h ) (a) 遵守法律義務；(b) 回應主管當局的要求； (c) 執行使用條款或房屋規則； (d) 保護我們或我們關聯公司的運營； (e) 保護我們的權利、安全或財產和/或我們的附屬公司、您或其他人的權利、安全或財產； (f) 行使或捍衛我們的合法權利或防止傷害； 這意味著如果我們認為為了遵守法律有必要披露您的信息，我們可能會存儲您的信息並將其披露給執法部門、州或政府機構。 這包括回應法院命令或傳票，以及其他司法或監管程序。',
                d62: '在本隱私政策中，“合法利益”是指 Alpha 及其附屬公司在開展和管理其業務方面的利益。 在為我們的合法利益處理您的個人數據時，我們會考慮並平衡對您和您在數據保護法下的權利的潛在影響。 我們的合法利益不會自動凌駕於您的利益之上。 我們不會將個人數據用於我們的利益優先於對您可能產生的影響的活動，除非我們明確同意或法律另有要求或允許此類活動。 出於與您的特定情況相關的原因，您有權隨時反對基於我們的合法利益處理您的個人數據。',
                d63: '你什麼時候分享個人信息？',
                d64: '我們在以下情況下與第三方共享您的個人數據',
                d65: 'a) 服務提供商:Alpha，與許多公司一樣，(i) 卡處理或支付服務，(ii) IT 供應商和承包商（即 (iii) 網絡和移動分析提供商，(iv) 數字提供商廣告服務和 ( v) 營銷和銷售軟件解決方案提供商，他們將根據我們的指示，僅在履行其對我們的義務和履行我們聘請他們提供的服務的過程中訪問、處理或使用您的個人數據。您可以保存。',
                d66: 'b) 支付信息:付費用戶必須提供支付信息（姓名、支付卡信息或支付賬戶信息）。 此信息僅用於計費目的，並由支付處理商處理。 當您付款時，我們無法訪問您的支付卡信息。 如果在處理您的訂單時出現問題，我們將使用您提供的電子郵件地址與您聯繫。',
                d67: 'c) 第三方內容提供商:當您從 IDC 或其他證券交易所購買市場數據時，我們會提供您的詳細聯繫方式、電話號碼、地址和電子郵件，並由您簽署協議。 此數據由 IDC 或其他證券交易所處理，以便您訪問交易所',
                d68: 'd) Alpha 附屬公司:為向您提供您向我們請求的服務，我們的附屬公司可能出於上述目的訪問和處理從您那裡收集的信息，包括為您提供產品和服務。 我們的關聯企業只會將您的數據用於我們最初收集數據的目的。',
                d69: 'e) 經紀人:在為經紀人填寫開戶表格時，我們會向您發送在此表格中填寫的個人數據:名字和姓氏、電話號碼、電子郵件地址、國家/地區。 此數據由經紀人處理以開設您的經紀賬戶。',
                d70: 'f) 分析提供商:我們可能會使用第三方服務提供商來監控和分析我們的服務和移動應用程序的使用情況。 我們使用這些信息來分析和改進我們的服務和移動應用程序的運行。',
                d71: 'g) 業務轉移:如果我們向第三方出售我們的業務或收購公司資產，我們持有的關於我們的普通賬戶用戶、訂閱賬戶用戶或客戶的個人數據可能屬於轉移資產。.',
                d72: 'h) 出於行政和法律原因:(i) 遵守法律義務和/或司法或監管程序、法院命令或其他法律程序；(ii) 我們需要披露您的個人數據以執行我們的條款; 如果您的用途適用，請遵守入住須知或其他適用的條款和條件；或 (iii) 保護我們或我們的承包商免受損失或損害。 這可能包括但不限於與警察、法院或執法機構交換信息。',
                d73: 'i) 廣告:我們可能會在我們的網站和應用程序上展示廣告，並使用再營銷和跟踪功能。 再營銷功能允許您以更有針對性的方式展示廣告，根據用戶在其他網站上的活動向他們展示他們感興趣的廣告。 跟踪使我們能夠收集有關印象、廣告點擊以及用戶通過我們的網站和應用程序完成的某些操作的信息。 這些數據還有助於我們評估廣告活動的效果。 請注意，我們只收集假名數據。',
                d74: '我們不與廣告商共享個人信息。 您將只能訪問一般統計信息，包括您的廣告產生的展示次數和點擊次數。',
                d75: '個人數據的安全',
                d76: '我們使用技術和組織保障措施來保護您的個人數據，並要求與我們合作的第三方也這樣做',
                d77: '我們使用傳輸層安全 (TLS) 加密技術來保護您提交的某些信息。 該技術可防止有人在您的信息傳輸到 Alpha 或支付處理商時攔截您的信息。 當您訪問安全頁面（例如訂單）時，瀏覽器窗口中會出現一個“鎖定”圖標，以確認已與網站和應用程序建立安全加密連接。 我們努力確保我們的服務安全並符合行業標準。 我們還使用防火牆、身份驗證系統（例如密碼和個人識別號碼）和訪問控制機制等其他保護措施來控制對我們系統和數據的未授權訪問。 如果您選擇創建一個帳戶，您有責任盡一切合理可能對您的訪問詳細信息保密。 您不得與任何人分享這些詳細信息。',
                d78: '我們也盡最大努力保護您的離線信息。 所有用戶的個人信息僅限於辦公室。 只有需要查看信息以完成工作的員工才能訪問它。',
                d79: '存儲您的個人數據的服務器位於鎖定設施的安全環境中。 我們不斷改進我們保護、存儲和處理我們收集的數據的方式，包括添加物理安全措施以幫助防止未經授權訪問我們的系統。 但是，沒有任何電子傳輸或存儲方法是 100% 安全的。 因此，不能保證絕對的安全。',


            },

        },
        register: {
            firstName: '姓名',
            lastName: '姓氏',
            phoneNumber: '電話號碼',
            select: '選擇',
            email: '電子郵件',
            phone: '電話',
            verificationCode: '驗證碼',
            getCode: '獲取驗證碼',
            password: '密碼',
            confirmPassword: '確認密碼',
            iDNumber: '身份證號碼',
            submit: '提交',
            required: '* 必填',
            step1: '註冊',//new
            step2: '開戶',//new
            success1: '提交成功',//new
            success2: '開戶審核將在1-3個工作日內完成',//new
            pass:'開戶審核已通過',
            noPass:'開戶審核未通過',
            back:'返回並重新提交',
            codeError:'請輸入正確的驗證碼',//new
            getImgError:'獲取新的圖形驗證碼需要5秒',//new
            d1: '為了驗證您的身份，我們需要知道您政府頒發的個人身份證號碼或護照。',
            d2: '身份證件正面',
            d3: '身份證件背面',
            d4: '瀏覽文件',
            d5: '或拖動圖片上傳',
            d6: '或',
            d7: '選擇樣本圖片',
            d8: '點擊下方的“提交”按鈕，即表示您同意我們的',
            d9: '隱私政策',
            d10: '.',
            d11: '這不是有效的電子郵件地址',
            d12: '* 選擇登錄帳號的郵箱或電話號碼',
            d13: '至少為8個字符',
            d14: '密碼不匹配',
            AD: '安道爾',
            AE: '阿拉伯聯合酋長國',
            AF: '阿富汗',
            AG: '安提瓜和巴布達',
            AI: '安圭拉',
            AL: '阿爾巴尼亞',
            AM: '亞美尼亞',
            AO: '安哥拉',
            AR: '阿根廷',
            AT: '奧地利',
            AU: '澳大利亞',
            AZ: '阿塞拜疆',
            BB: '巴巴多斯',
            BD: '孟加拉國',
            BE: '比利時',
            BF: '布基納法索',
            BG: '保加利亞',
            BH: '巴林',
            BI: '布隆迪',
            BJ: '貝寧',
            BL: '巴勒斯坦',
            BM: '百慕大群島',
            BN: '文萊',
            BO: '玻利維亞',
            BR: '巴西',
            BS: '巴哈馬',
            BW: '博茨瓦納',
            BY: '白俄羅斯',
            BZ: '伯利茲',
            CA: '加拿大',
            KY: '開曼群島',
            CF: '中非共和國',
            CG: '剛果',
            CH: '瑞士',
            CK: '庫克群島',
            CL: '智利',
            CM: '喀麥隆',
            CN: '中國',
            CO: '哥倫比亞',
            CR: '哥斯達黎加',
            CU: '古巴',
            CY: '塞浦路斯',
            CZ: '捷克共和國',
            DE: '德國',
            DJ: '吉布提',
            DK: '丹麥',
            DO: '多米尼加共和國',
            DZ: '阿爾及利亞',
            EC: '厄瓜多爾',
            EE: '愛沙尼亞',
            EG: '埃及',
            ES: '西班牙',
            ET: '埃塞俄比亞',
            FI: '芬蘭',
            FJ: '斐濟',
            FR: '法國',
            GA: '加蓬',
            GB: '英國',
            GD: '格林納達',
            GE: '格魯吉亞',
            GF: '法屬圭亞那',
            GH: '加納',
            GI: '直布羅陀',
            GM: '岡比亞',
            GN: '幾內亞',
            GR: '希臘',
            GT: '危地馬拉',
            GU: '關島',
            GY: '圭亞那',
            HK: '香港',
            HN: '洪都拉斯',
            HT: '海地',
            HU: '匈牙利',
            ID: '印度尼西亞',
            IE: '愛爾蘭',
            IL: '以色列',
            IN: '印度',
            IQ: '伊拉克',
            IR: '伊朗',
            IS: '冰島',
            IT: '意大利',
            CI: '象牙海岸',
            JM: '牙買加',
            JO: '約旦',
            JP: '日本',
            KE: '肯尼亞',
            KG: '吉爾吉斯斯坦',
            KH: '柬埔寨',
            KP: '朝鮮',
            KR: '韓國',
            KT: '科特迪瓦共和國',
            KW: '科威特',
            KZ: '哈薩克斯坦',
            LA: '老撾',
            LB: '黎巴嫩',
            LC: '聖盧西亞',
            LI: '列支敦士登',
            LK: '斯里蘭卡',
            LR: '利比里亞',
            LS: '萊索托',
            LT: '立陶宛',
            LU: '盧森堡',
            LV: '拉脫維亞',
            LY: '利比亞',
            MA: '摩洛哥',
            MC: '摩納哥',
            MD: '摩爾多瓦共和國',
            MG: '馬達加斯加',
            ML: '馬里',
            MM: '緬甸',
            MN: '蒙古',
            MO: '澳門',
            MS: '蒙特塞拉特',
            MT: '馬耳他',
            MQ: '馬提尼克島',
            MU: '毛里求斯',
            MV: '馬爾代夫',
            MW: '馬拉維',
            MX: '墨西哥',
            MY: '馬來西亞',
            MZ: '莫桑比克',
            NA: '納米比亞',
            NE: '尼日爾',
            NG: '尼日利亞',
            NI: '尼加拉瓜',
            NL: '荷蘭',
            NO: '挪威',
            NP: '尼泊爾',
            AN: '荷屬安的列斯群島',
            NR: '瑙魯',
            NZ: '新西蘭',
            OM: '阿曼',
            PA: '巴拿馬',
            PE: '秘魯',
            PF: '法屬波利尼西亞',
            PG: '巴布亞新幾內亞',
            PH: '菲律賓',
            PK: '巴基斯坦',
            PL: '波蘭',
            PR: '波多黎各',
            PT: '葡萄牙',
            PY: '巴拉圭',
            QA: '卡塔爾',
            RE: '留尼汪',
            RO: '羅馬尼亞',
            RU: '俄羅斯',
            SA: '沙特阿拉伯',
            SB: '所羅門',
            SC: '塞舌爾',
            SD: '苏丹',
            SE: '瑞典',
            SG: '新加坡',
            SI: '斯洛文尼亞',
            SK: '斯洛伐克',
            SL: '塞拉利昂',
            SM: '聖馬力諾',
            AS: '薩摩亞東部',
            WS: '聖馬力諾',
            SN: '塞內加爾',
            SO: '索馬里',
            SR: '蘇里南',
            ST: '聖多美和普林西比',
            SV: '薩爾瓦多',
            SY: '敘利亞',
            SZ: '斯威士蘭',
            TD: '乍得',
            TG: '多哥',
            TH: '泰國',
            TJ: '塔吉克斯坦',
            TM: '土庫曼斯坦',
            TN: '突尼斯',
            TO: '湯加',
            TR: '火雞',
            TT: '特立尼達和多巴哥',
            TW: '台灣',
            TZ: '坦桑尼亞',
            UA: '烏克蘭',
            UG: '烏干達',
            US: '美利堅合眾國',
            UY: '烏拉圭',
            UZ: '烏茲別克斯坦',
            VC: '聖文森特',
            VE: '委內瑞拉',
            VN: '越南',
            YE: '也門',
            YU: '南斯拉夫',
            ZA: '南非',
            ZM: '贊比亞',
            ZR: '扎伊爾',
            ZW: '津巴布韋',
        },
        login:{
            username:'電話號碼/郵箱',
            password:'密碼',
            login:'登 錄',
            required: '* 必填',
            have:'已有賬號，去登錄',
            other:'登錄其他賬號',
            create:'創建新的賬號'
        }
    }
}