module.exports = {
    views: {
        home: {
            title0: 'アジア初の規制対象ビットコイン無期限先物',
            subTitle: 'あなたの規制先物取引会場!',
            title1: '金のグラムを世界価格単位で取引してください。',
            d1: '取引統計',
            d2: '24時間取引額',
            d3: '10.18M',
            d4: '1 週間の取引額',
            d5: '57.98M',
            d6: '30d 取引額',
            d7: '368.04M',
            d8: '当社のビットコイン インデックス,コンパス クリプト リアルタイム インデックス ビットコインは,EU ベンチマーク規則 (EU BMR) に基づいて Autorité des Marchés Financiers (AMF) によってベンチマーク管理者として登録されている COMPASS Financial Technologies (フランス) によって提供されています。',
            d9: '免責事項を表示',
            d10: '当社の製品',
            d11: 'ビットコイン無期限先物',
            d12: 'ビットコイン月次先物',
            d13: 'もっと調べる',
            d14: '認可された取引所で今すぐ投資の旅を始めましょう',
            d15: 'APEX プロダクト エキスパートと連絡を取る',
            d16: 'APEX のメンバーの 1 つで口座を開設する',
            disclaimer: '無断複写・転載を禁じます。 Compass は,Compass Crypto Reference Index ビットコイン (\'インデックス\') に関するすべての所有権を有します。インデックスに基づく,またはインデックスに関連するサード パーティ製品 (\'製品\') は,Compass の事前の書面による承認,および Compass と製品の発売を意図する当事者との間のライセンス契約の締結時にのみ発行することができます。 Compass は,製品の発行および提供を後援したり,保証したり,その他の方法で関与したりすることはありません。また,製品の所有者または一般の人々に対して,投資の妥当性に関して,明示または黙示を問わず,いかなる表明または保証も行いません。商品または商品全般または特に先物,またはインデックスの使用または商品から得られる結果に関して。 Compass は,インデックスの基礎となるデータの不正確さ,インデックスの計算および/または配布における誤り,エラー,省略または中断,またはインデックスの適用方法について,いかなる当事者に対しても責任を負いません。製品の発行および提供との関連。 Compass は,利益の損失,または間接的,懲罰的,特別または結果的な損害または損失について,その可能性が通知されていたとしても,一切の責任を負わないものとします。これは,証券または投資の売買の申し出または勧誘ではありません。 Compass クリプト リファレンス インデックスの過去のパフォーマンスは,必ずしも将来の結果を示すものではありません。'

        },
        header: {
            cryptocurrencies: {
                title: '仮想通貨',
                d1: 'ビットコイン無期限先物',
                d2: 'ビットコイン月次先物'
            },
            preciousMetals: {
                title: '貴金属',
                d1: 'ゴールド先物',
                d2: '銀先物'
            },
            forex: {
                title: '外国為替',
                d1: 'USD/CNH 先物',
            },
            commodities: {
                title: '商品',
                d1: 'パームオレイン先物',
                d2: '原油パーム油先物',
                d3: '燃料油先物',
                d4: '低硫黄燃料油先物'
            },
            btn: 'アルファと取引する'
        },
        pages: {
            topTitle: 'EXCHANGE',
            cryptocurrencies: {
                bitcoinPerpetualFutures: {
                    title: 'ビットコイン無期限先物',
                    d1: '仮想通貨は投資家の間で大きな支持を得ており,より広範な採用の兆候を示しています。最初の暗号通貨であるビットコインは,暗号通貨の数が増え続けているにもかかわらず,依然として最も人気があり価値のあるデジタル資産の 1 つです。',
                    d2: 'APEX ビットコイン無期限先物契約により,暗号通貨のリスクを管理し,この成長する資産クラスで貴重なエクスポージャーを得ることができます。それは機関投資家向けの取引ポートフォリオに多様化を提供し,先物契約であるため,ポートフォリオが暗号通貨の価格変動に耐えるのに役立つリスク最小化手段として機能します。',
                    d3: '注: 原資産は他の資産クラスに比べて価格の変動性がはるかに高いため, 小売投資家には適していません。したがって, APEX会員に連絡して、この商品を取引するためのあなたの適合性を評価してください。',
                    tableTh: 'APEX ビットコイン無期限先物契約の仕様',
                    column_1_01: '契約コード',
                    column_1_02: '契約規模',
                    column_1_03: 'クオート通貨',
                    column_1_04: '最小価格変動',
                    column_1_05: 'ティック値',
                    column_1_06: '契約シリーズ',
                    column_1_07: '取引時間',
                    column_1_08: '最終取引日',
                    column_1_09: '毎日の価格制限',
                    column_1_10: '日々決済価格',
                    column_1_11: '資金調達手数料',
                    column_1_12: '資金調達間隔',
                    column_1_13: '最終決済価格',
                    column_1_14: '決済方法',
                    column_1_15: 'ブロック取引',
                    column_1_16: '当初証拠金',
                    column_1_17: '最大注文規模',

                    column_2_01: 'BTCP',
                    column_2_02: '50 ミリビットコイン/ロット (0.05 BTC = 50 ミリビットコイン)',
                    column_2_03: '米ドル',
                    column_2_04: 'US $0.005/ミリビットコイン',
                    column_2_05: 'US $0.25',
                    column_2_06: '永久契約',
                    column_2_07: 'シンガポールの時間取引セッション:',
                    column_2_08: 'シンガポールの祝日を含む月～金',
                    column_2_09: 'T セッション:',
                    column_2_10: '06:55時 – 06:59時 (プレオープニングセッション)',
                    column_2_11: '06:59時 – 07:00時 (試合開始)',
                    column_2_12: '07:00時 – 18:00時 (デイセッション)',
                    column_2_13: 'T+1 セッション:',
                    column_2_14: '19:25時 – 19:29時 (プレオープニングセッション)',
                    column_2_15: '19:29時 – 19:30時 (試合開始)',
                    column_2_16: '19:30時 – 05:30時 (ナイトセッション)',
                    column_2_17: 'T は特定の取引日を表します。',
                    column_2_18: 'デイ セッションの終わりは T の終わりです。',
                    column_2_19: '満期日がないため該当なし。',
                    column_2_20: '取引所が別段の定めをしない限り,1日の値幅制限はありません。',
                    column_2_21: '特定日々決済価格は, コンパス・フィナンシャル・テクノロジーズ SA (\'Compass\') が 18:00 (GMT+8) に発表した コンパス クリプト リファレンス インデックス ビットコイン(CCRIBTC) の価格に等しいものとします。日々決済価格は,0.005 米ドル/ミリビットコイン単位に丸められます。 ',
                    column_2_22: '資金調達は,ポジション保有者が資金調達率を使用して計算された資金調達手数料を受け取るか支払う場所で発生します。これは,各 T セッションおよび T+1 セッションの終わりにすべてのオープン ポジションに適用されます。資金調達手数料は,各取引日の終わりの 1 日の終わりの清算サイクル中に計算され,徴収されます。',
                    column_2_23: '計算式は次のとおりです。: 資金調達手数料 = オープン ポジション契約の数 x 契約規模 x コンパス クリプト リアルタイム インデックスビットコイン (CCRTBTC) (単位はミリビットコイン) x 資金調達率 資金調達率とその方法論は APEX の ウェブサイトで公開されます。 ',
                    column_2_24: 'T セッション: 18:00時 (GMT+8)',
                    column_2_25: 'T+1 セッション: 05:30時 (GMT+8)',
                    column_2_26: '満期日がないため該当しません。',
                    column_2_27: '日次決済価格に対する米ドルでの現金決済',
                    column_2_28: '最低 20 ロット',
                    column_2_29: ' SPAN 基準',
                    column_2_30: '500 ロット',


                },
                bitcoinMonthlyFutures: {
                    title: 'ビットコイン月次先物',
                    d1: '仮想通貨は投資家の間で大きな支持を得ており,より広範な採用の兆候を示しています。最初の暗号通貨であるビットコインは,暗号通貨の数が増え続けているにもかかわらず,依然として最も人気があり価値のあるデジタル資産の 1 つです。',
                    d2: 'APEX ビットコイン月次先物契約を使用すると,暗号通貨のリスクを管理し,この成長する資産クラスで貴重なエクスポージャーを得ることができます。機関投資家向けの取引ポートフォリオに多様化を提供し,先物契約であるため,ポートフォリオが暗号通貨の価格変動に耐えるのに役立つリスク最小化手段として機能します。',
                    d3: '注: 原資産は他の資産クラスに比べて価格の変動性がはるかに高いため,この商品は個人投資家には適していません。したがって,APEX のメンバーにアプローチして,この商品を取引するあなたの適性を評価してもらいます。',
                    tableTh: 'APEX ビットコイン月次先物約定仕様書',
                    column_1_01: '契約コード',
                    column_1_02: '契約規模',
                    column_1_03: 'クオート通貨',
                    column_1_04: '最小価格変動',
                    column_1_05: 'ティック値',
                    column_1_06: '契約シリーズ',
                    column_1_07: '取引時間',
                    column_1_08: '最終取引日',
                    column_1_09: '毎日価格制限',
                    column_1_10: '日々決済価格',
                    column_1_11: '最終決済価格',
                    column_1_12: '決済方法',
                    column_1_13: 'ブロック取引',
                    column_1_14: '当初証拠金',
                    column_1_15: '最大注文規模',

                    column_2_01: 'ビットコイン',
                    column_2_02: '50 ミリビットコイン/ロット (0.05 BTC = 50 ミリビットコイン)',
                    column_2_03: '米ドル',
                    column_2_04: 'US $0.005/ミリビットコイン',
                    column_2_05: 'US $0.25',
                    column_2_06: '最近連続6ヶ月',
                    column_2_07: 'シンガポールの時間取引セッション:',
                    column_2_08: 'シンガポールの祝日を含む月曜日 – 金曜日',
                    column_2_09: 'T セッション:',
                    column_2_10: '06:55時 – 06:59時 (プレオープニングセッション)',
                    column_2_11: '06:59時 – 07:00時 (試合開始)',
                    column_2_12: '07:00時 – 18:00時 (デイセッション)',
                    column_2_13: 'T+1 セッション:',
                    column_2_14: '19:25時 – 19:29時 (プレオープニングセッション)',
                    column_2_15: '19:29時 – 19:30時 (試合開始)',
                    column_2_16: '19:30時 – 05:30時 (ナイトセッション)',
                    column_2_17: 'T は特定の取引日を表します。デイ セッションの終わりは,T の終わりです',
                    column_2_18: '契約の最終取引日は契約月の最終金曜日です。',
                    column_2_19: '取引所が別段の定めをしない限り,1日の値幅制限はありません。',
                    column_2_20: '特定の日の日次決済価格は,特定の日のデイ セッションの最後の 1 分間に発生したすべての取引の取引価格の出来高加重平均,またはクリアリング ハウスが定めるその他の方法です。日次決済価格は,0.005 米ドル/ミリビットコイン単位に丸められます。 ',
                    column_2_21: '最終決済価格は,最終取引日の 18:00時 (GMT+8) に コンパス・フィナンシャル・テクノロジーズ SA (\'Compass\') によって公開された コンパス クリプト リファレンス インデックス ビットコインn (CCRIBTC) の価格と等しいものとします。最終決済価格は,0.005 米ドル/ミリビットコインの単位に丸められます。 ',
                    column_2_22: '米ドルでの現金決済',
                    column_2_23: '最低 20 ロット',
                    column_2_24: ' SPAN 基準',
                    column_2_25: '500 ロット',


                }
            },
            preciousMetals: {
                goldFutures: {
                    title: 'ゴールド先物',
                    d1: '長い間安全な資産と考えられてきた金は、一般的に投資ヘッジツールとして使用されます。限られた供給量と不滅の性質を持つゴールドは,優れた価値の保存手段です。 APEX 金無期限先物契約は,この貴金属の取引,ヘッジ,およびリスク管理のニーズに対応するツールとして機能します。',
                    tableTh: 'APEX 金無期限先物契約仕様書 (V2021.08.06)',
                    column_1_01: '契約コード',
                    column_1_02: '契約規模',
                    column_1_03: 'クオート通貨',
                    column_1_04: '最小価格変動',
                    column_1_05: 'ティック値',
                    column_1_06: '契約シリーズ',
                    column_1_07: '取引時間',
                    column_1_08: '最終取引日',
                    column_1_09: '毎日の価格制限',
                    column_1_10: '日々決済価格',
                    column_1_11: '最終決済価格',
                    column_1_12: '決済方法',
                    column_1_13: '繰越手数料',
                    column_1_14: 'ブロック取引',
                    column_1_15: '当初証拠金',
                    column_1_16: '最大注文規模',


                    column_2_01: 'AUP',
                    column_2_02: '100 グラム/ロット',
                    column_2_03: '米ドル',
                    column_2_04: 'US $0.01 / グラム',
                    column_2_05: 'US $1',
                    column_2_06: '永久契約',
                    column_2_07: 'ロンドン金市場協会 (LBMA) AM 金価格公表日',
                    column_2_08: 'T セッション:',
                    column_2_09: '06:55時 – 06:59時 (プレオープニング セッション)',
                    column_2_10: '06:59時 – 07:00時 (試合開始)',
                    column_2_11: '07:00時 – 18:00時 (デイセッション)',
                    column_2_12: 'T+1 セッション:',
                    column_2_13: '20:25時 – 20:29時 (プレオープニング セッション)',
                    column_2_14: '20:29時 – 20:30時 (試合開始)',
                    column_2_15: '20:30時 – 05:00時 (ナイトセッション)',
                    column_2_16: 'T は特定の取引日を表します。',
                    column_2_17: 'デイ セッションの終わりは T の終わりです。',
                    column_2_18: '非 LBMA ゴールド AM 発行日,または非 LBMA ゴールド AM 発行日の前の取引日に T+1 セッションはありません。',
                    column_2_19: '満期日がないため,該当しません。',
                    column_2_20: '前取引日の日次決済価格の +/-10%',
                    column_2_21: '特定の日の日次清算価格は,IBA が発行する LBMA 金価格 AM を 1 グラムあたり 0.01 米ドルに四捨五入したものと等しいものとします。 ',
                    column_2_22: '満期日がないため,該当しません。',
                    column_2_23: '日々決済価格に対する米ドルでの現金決済。',
                    column_2_24: 'ロールオーバー手数料は,T セッションの終了時にすべてのオープン ポジションに適用されます。ロールオーバー手数料は,各取引日の終わりの 1 日の終わりの清算サイクル中に計算され,徴収されます。',
                    column_2_25: '式は次のとおりです:',
                    column_2_26: 'オープン ポジション契約数 x 契約規模 x 日々決済価格 x (ロールオーバー レート) ÷ 365',
                    column_2_27: 'ロールオーバー料金の計算に使用されるロールオーバー レートは,APEX の ウェブサイトで公開されます。',
                    column_2_28: '最低 30 ロット',
                    column_2_29: ' SPAN 基準',
                    column_2_30: '500 ロット',


                },
                silverFutures: {
                    title: '銀先物',
                    d1: '金に比べて,銀は特に写真や医療の分野で産業上の需要が高いです。ゴールドと高い相関性を持つシルバーは,同様に安全な資産であり,有用なヘッジツールと見なされることがよくあります。 APEX 銀無期限先物契約は,この貴金属の取引,ヘッジ,およびリスク管理のニーズに対応するツールとして機能します。',
                    tableTh: 'APEX 銀無期限先物契約の仕様',
                    column_1_01: '契約コード',
                    column_1_02: '契約規模',
                    column_1_03: 'クオート通貨',
                    column_1_04: '最小価格変動',
                    column_1_05: 'ティック値',
                    column_1_06: '契約シリーズ',
                    column_1_07: '取引時間',
                    column_1_08: '最終取引日',
                    column_1_09: '毎日の価格制限',
                    column_1_10: '日々決済価格',
                    column_1_11: '最終決済価格',
                    column_1_12: '決済方法',
                    column_1_13: '繰越手数料',
                    column_1_14: 'ブロック取引',
                    column_1_15: '当初証拠金',
                    column_1_16: '最大注文規模',


                    column_2_01: 'AGP',
                    column_2_02: '5 キログラム/ロット',
                    column_2_03: '米ドル',
                    column_2_04: 'US $0.2/キログラム',
                    column_2_05: 'US $1',
                    column_2_06: '永久契約',
                    column_2_07: 'ロンドン金市場協会 (LBMA) 銀価格公表日',
                    column_2_08: 'T セッション:',
                    column_2_09: '06:55時 – 06:59時 (プレオープニング セッション)',
                    column_2_10: '06:59時 – 07:00時 (試合開始)',
                    column_2_11: '07:00時 – 18:00時 (デイセッション)',
                    column_2_12: 'T+1 セッション:',
                    column_2_13: '20:25時 – 20:29時 (プレオープニング セッション)',
                    column_2_14: '20:29時 – 20:30時 (試合開始)',
                    column_2_15: '20:30時 – 05:00時 (ナイトセッション)',
                    column_2_16: 'T は特定の取引日を表します。',
                    column_2_17: 'デイ セッションの終わりは T の終わりです。',
                    column_2_18: '非 LBMA シルバー発行日または非 LBMA シルバー発行日の前の取引日に T+1 セッションはありません。',
                    column_2_19: '満期日がないため,該当しません。',
                    column_2_20: '前取引日の日次決済価格の +/-15%',
                    column_2_21: '特定の日の日次清算価格は,IBA が発行する LBMA シルバー価格を 0.2 米ドル/キログラム単位で四捨五入したものと等しいものとします',
                    column_2_22: '満期日がないため,該当しません。',
                    column_2_23: '日々決済価格に対する米ドルでの現金決済。',
                    column_2_24: 'ロールオーバー手数料は,T セッションの終了時にすべてのオープン ポジションに適用されます。ロールオーバー手数料は,各取引日の終わりの 1 日の終わりの清算サイクル中に計算され,徴収されます。',
                    column_2_25: '式は次のとおりです:',
                    column_2_26: 'オープン ポジション契約数 x 契約規模 x 日々決済価格 x (ロールオーバー レート) ÷ 365',
                    column_2_27: 'ロールオーバー料金の計算に使用されるロールオーバー レートは,APEX の ウェブサイトで公開されます。',
                    column_2_28: '最低 50 ロット',
                    column_2_29: ' SPAN 基準',
                    column_2_30: '500 ロット',


                }
            },
            forex: {
                USDCNHFutures: {
                    title: 'USD/CNH 先物',
                    d1: '人民元 (RMB) の国際化の加速に伴い,RMB の国際的地位が高まっています。人民元は世界の貿易と投資で広く使用されており,ますます重要な国際準備通貨の 1 つになりつつあります。人民元為替レート体制の改革のペースは,国際貿易の流れの拡大と同時に勢いを増しており,その結果,変動率が高くなり,両方向の為替レートの変動が増加する傾向にある。 ',
                    tableTh: 'APEX USD/CNH 先物契約の仕様',
                    column_1_01: '契約コード',
                    column_1_02: 'ティッカーシンボル',
                    column_1_03: '契約規模',
                    column_1_04: '見積価格',
                    column_1_05: '最小価格変動',
                    column_1_06: 'ティック値',
                    column_1_07: '契約シリーズ',
                    column_1_08: '取引時間',
                    column_1_09: '最終取引日',
                    column_1_10: '毎日価格制限',
                    column_1_11: '日々決済価格',
                    column_1_12: '最終決済価格',
                    column_1_13: '決済方法',
                    column_1_14: 'ブロック取引',
                    column_1_15: '当初証拠金',
                    column_1_16: '最大注文規模',


                    column_2_01: 'UC',
                    column_2_02: '契約は UCyymmW# で表されます。ここで,\'yy\',\'mm\',および\'W#\'は,契約が満了する年,月,およびその月の序週を表します。記号の目的で,月の最初の週は水曜日のあるその月の最初の週です。',
                    column_2_03: '例: UC1901W3 は,2019 年 1 月の第 3 週に期限が切れる契約を指します。',
                    column_2_04: '10,000 米ドル',
                    column_2_05: '1 米ドルあたりの CNH',
                    column_2_06: 'CNH 0.0001 (小数点以下4桁)',
                    column_2_07: 'CNH 1',
                    column_2_08: '取引所は,次の 4 週間連続で期限が切れる 4 つの契約を一覧表示し,その後に,次の連続する 3 か月のそれぞれの第 3 週に期限が切れる別の 3 つの契約が続き,さらに次の 3 つの契約が続きます。次の 3 つの四半期ごとの月 (3 月,6 月,9 月,12 月) のそれぞれの第 3 週に失効します。',
                    column_2_09: '限月が満了すると,取引所は次の取引日の初めまでに新しい限月を取引用にリストします。',
                    column_2_10: 'シンガポールの時間取引セッション: シンガポールの営業日',
                    column_2_11: '月曜日～金曜日,シンガポールの祝日を除く午前8時55分 – 午前08時59分(事前開講) 08:59am',
                    column_2_12: '08:59am – 09:00am (デイセッションの試合開始)',
                    column_2_13: '09:00am – 18:00pm (デイ セッション)限月の取引は,最終取引日の午前 11:00 に終了するものとします。',
                    column_2_14: '契約の最終取引日は,香港営業日とシンガポール営業日の両方である場合,その限月週の月曜日,そうでない場合は,香港営業日とシンガポール営業日の両方である翌日の最も早い日になります。日。上記にかかわらず,取引所は通知をもって最終取引日を決定する権利を留保します。',
                    column_2_15: '取引所が別段の定めをしない限り,1日の値幅制限はありません。',
                    column_2_16: '特定の日の日次決済価格は,特定の日のデイ セッションの最後の 5 分間に発生したすべての取引の取引価格の出来高加重平均,またはクリアリング ハウスが定めるその他の方法です。',
                    column_2_17: '最終決済価格は,最終取引日の香港時間午前 11 時 30 分頃に香港財務市場協会 (TMA) が発行した USD/CNY(HK) スポット レート,または設定されたその他の方法と等しいものとします。クリアリング ハウスによる',
                    column_2_18: 'CNH での現金決済',
                    column_2_19: '最低 200 ロット',
                    column_2_20: ' SPAN 基準',
                    column_2_21: '500 ロット',


                },

            },
            commodities: {
                palmOleinFutures: {
                    title: 'パームオレイン先物',
                    d1: ' APEXは、最初の製品である精製パームオレイン先物契約を発売することを目指しています。パームオレインギフトの主な利点は次のとおりです。マラッカ海峡沿いのマレーシアとインドネシアに位置するインド港を通じたFOB実物インド。世界のパーム油生産の85％と国際ファーム取引の91％がマレーシアとインドネシアで行われています。',
                    d2: '米ドルを価格通貨として使用し、RMB およびその他の通貨を受け入れ可能な証拠金担保として許可することにより, APEX のファームオレイン先物契約は国際貿易慣行に準拠し、通貨価格変動のリスクを防止します。',
                    d3: ' APEXパームオレン先物契約の発売が差し迫っているため、グローバルなパーム油取引業界に代替場所を提供することを目指し、ヘッジおよび借入取引のための新しい手段として機能します。',
                    tableTh: 'APEX RBD パームオレイン先物契約仕様書 (V2019.04.03)',
                    column_1_01: '契約コード',
                    column_1_02: '契約規模',
                    column_1_03: 'クオート通貨',
                    column_1_04: '最小価格変動',
                    column_1_05: '取引時間',
                    column_1_06: '毎日の価格制限',
                    column_1_07: '契約シリーズ',
                    column_1_08: '最終取引日',
                    column_1_09: '配送方法',
                    column_1_10: '最小納品規模',
                    column_1_11: '当初証拠金',
                    column_1_12: '最終配達日',
                    column_1_13: '最大注文規模',

                    column_2_01: 'PF',
                    column_2_02: '10MT / 契約',
                    column_2_03: '米ドル',
                    column_2_04: 'US$0.25 / MT',
                    column_2_05: 'シンガポール時間取引セッション:シンガポール営業',
                    column_2_06: '月～金,シンガポールの祝日を除く',
                    column_2_07: ' ',
                    column_2_08: '08:55am – 08:59am (プレオープニング セッション)',
                    column_2_09: '08:59am – 09:00am (試合開始)',
                    column_2_10: '09:00am – 12:30pm (午前/1次セッション)',
                    column_2_11: '13:30pm – 18:00pm (午後/2次セッション)',
                    column_2_12: '限月の取引は,最終取引日の午後 18 時に終了します',
                    column_2_13: '最終決済の+/-10%。契約の最終取引日には日々決済価格制限は適用されません。',
                    column_2_14: '月次契約(連続12ヶ月契約)',
                    column_2_15: '営業日の場合は先行契約月15回目の逆日,それ以外の場合は前営業日',
                    column_2_16: 'Pasir Gudang (マレーシア),Port Klang (マレーシア),Lahad Datu (マレーシア),Belawan (インドネシア),Dumai (インドネシア),またはその他の APEX 承認済みポートでの物理的な配送 FOB',
                    column_2_17: '50 契約 (合計 500 MT)',
                    column_2_18: ' SPAN 基準 (マージンスケジュールを見るにはクリック)',
                    column_2_19: '納入月の最終暦日',
                    column_2_20: '500 ロット',


                },
                crudePalmOilFutures: {
                    title: '原油パーム油先物',
                    d1: ' APEX 原油パーム油先物契約は,パーム油シリーズの 2 番目であり,最初の商品である APEX RBD パーム オライン先物契約と同様の機能を備えています。 APEX原油パーム油先物は,APEX RBDパームオレイン先物と並行して運営され,パーム油市場で利用可能な裁定取引の機会を増やします。',
                    tableTh: 'APEX 粗パーム油先物契約の仕様',
                    column_1_01: '契約コード',
                    column_1_02: '契約規模',
                    column_1_03: 'クオート通貨',
                    column_1_04: '最小価格変動',
                    column_1_05: '取引時間',
                    column_1_06: '毎日の価格制限',
                    column_1_07: '契約シリーズ',
                    column_1_08: '最終取引日',
                    column_1_09: '配送方法',
                    column_1_10: '最小注文量',
                    column_1_11: '当初証拠金',
                    column_1_12: '最終配達日',
                    column_1_13: '最大注文量',

                    column_2_01: 'CPF',
                    column_2_02: '10MT / 契約',
                    column_2_03: '米ドル',
                    column_2_04: 'US$0.25 / MT',
                    column_2_05: 'シンガポール時間取引セッション:シンガポール営業',
                    column_2_06: '月曜日～金曜日,シンガポールの祝日を除く午前8時55分 – 午前08時59分(事前開講) 08:59am',
                    column_2_07: '08:59am – 09:00am (試合開始)',
                    column_2_08: '09:00am – 12:30pm (午前のセッション)',
                    column_2_09: '13:30pm – 18:00pm (午後のセッション)',
                    column_2_10: ' ',
                    column_2_11: '限月の取引は,最終取引日の午後 18 時に終了します',
                    column_2_12: '最終決済の+/-10%。契約の最終取引日には日々決済価格制限は適用されません。',
                    column_2_13: '月次契約(連続12ヶ月契約)',
                    column_2_14: '営業日の場合は先行契約月15回目の逆日,それ以外の場合は前営業日',
                    column_2_15: 'APEX ウェブサイトの ANNEX A に記載されている APEX 承認済み港での物理的な配送 FOB',
                    column_2_16: '50 契約 (合計 500 MT)',
                    column_2_17: ' SPAN 基準',
                    column_2_18: '納入月の最終暦日',
                    column_2_19: '注文ごとに 500 契約',

                },
                fuelOilFutures: {
                    title: '燃料油先物',
                    d1: '契約は現在休止状態であり、取引はできません。詳細については、APEXまたはそのブローカーに直接お問い合わせください。',
                    d2: 'シンガポールは現在,世界最大の燃料補給港であり,年間約 5,000 万トンの船舶用燃料油を供給しています。海事産業は依然としてシンガポール経済の大きな部分を占めており,シンガポールの国内総生産 (GDP) に約 7% 貢献しています',
                    d3: '燃料費は海運会社にとって依然として最大のコスト項目であるため,船舶用燃料油価格の大幅な価格変動は,多くの企業の運用コストに悪影響を及ぼす可能性があり,シンガポール経済に波及効果をもたらす可能性があります。',
                    d4: '現在の状況に照らして,APEX は 380cst 燃料油先物契約を開始し,エネルギー業界と金融機関が燃料油市場にヘッジおよび投資するためのプラットフォームを作成しました。',
                    tableTh: 'APEX 380cst 燃料油先物契約の仕様',
                    column_1_01: '契約コード',
                    column_1_02: '契約規模',
                    column_1_03: 'クオート通貨',
                    column_1_04: '最小価格変動',
                    column_1_05: '取引時間',
                    column_1_06: '毎日の価格制限',
                    column_1_07: '契約シリーズ',
                    column_1_08: '最終取引日',
                    column_1_09: '配送方法',
                    column_1_10: '最小注文量',
                    column_1_11: '当初証拠金',
                    column_1_12: '最大注文量',

                    column_2_01: 'FO',
                    column_2_02: '10MT / 契約',
                    column_2_03: '米ドル',
                    column_2_04: 'US$0.10 / MT',
                    column_2_05: 'シンガポール時間取引セッション:シンガポール営業',
                    column_2_06: '月曜日～金曜日,シンガポールの祝日を除く午前8時55分 – 午前08時59分(事前開講) 08:59am',
                    column_2_07: '08:59am – 09:00am (試合開始)',
                    column_2_08: '09:00am – 12:30pm (午前/1次セッション)',
                    column_2_09: '午後 13 時 30 分～午後 18 時 (午後セッション/2次セッション) 限月の取引は,最終取引日の午後 18 時に終了する',
                    column_2_10: '/-最終決済価格の 10%。取引最終日には,契約の毎日の価格制限は実施されないものとします',
                    column_2_11: '月次契約(連続12ヶ月契約)',
                    column_2_12: '最初の契約月の最終営業日、そうでなければ直前の営業日',
                    column_2_13: '承認された倉庫での FOB またはタンク間転送による物理的な配送',
                    column_2_14: '100 契約 (合計 1,000 MT)',
                    column_2_15: ' SPAN 基準',
                    column_2_16: '注文ごとに 500 契約',


                },
                lowSulphurFuelOilFutures: {
                    title: '低硫黄燃料油先物',
                    d1: '契約は現在休止状態であり、取引はできません。詳細については、APEXまたはそのブローカーに直接お問い合わせください。',
                    d2: 'アジア太平洋取引所 (APEX) は,2019 年 11 月 21 日午後 9 時に低硫黄燃料油先物契約 (LFO 契約) を開始しました。LFO 契約は,低硫黄燃料油のヘッジと FOB 物理的引渡しを容易にする世界初の配信可能な商品です。',
                    tableTh: '低硫黄燃料油先物契約仕様書',
                    column_1_01: '契約コード',
                    column_1_02: '契約規模',
                    column_1_03: 'クオート通貨',
                    column_1_04: '最小価格変動',
                    column_1_05: '取引時間',
                    column_1_06: '毎日の価格制限',
                    column_1_07: '契約シリーズ',
                    column_1_08: '最終取引日',
                    column_1_09: '最終配達日',
                    column_1_10: '配送方法',
                    column_1_11: '最小注文量',
                    column_1_12: '当初証拠金',
                    column_1_13: '最大注文量',

                    column_2_01: 'LFO',
                    column_2_02: '10 MT / 契約',
                    column_2_03: '米ドル',
                    column_2_04: 'US $0.10 / MT',
                    column_2_05: 'シンガポール時間取引セッション:シンガポール営業',
                    column_2_06: '月曜日～金曜日,シンガポールの祝日を除く午前8時55分 – 午前08時59分(事前開講) 08:59am',
                    column_2_07: '08:59am – 09:00am (試合開始)',
                    column_2_08: '09:00am – 12:30pm (午前/1次セッション)',
                    column_2_09: '午後 13 時 30 分～午後 18 時 (午後セッション/2次セッション) 限月の取引は,最終取引日の午後 18 時に終了する',
                    column_2_10: '最終決済価格の +/-10%',
                    column_2_11: '取引最終日に限月の有効な日次価格制限はありません。',
                    column_2_12: '月次契約(連続12ヶ月契約)',
                    column_2_13: '前限月の最終営業日',
                    column_2_14: '限月の最終暦日',
                    column_2_15: '承認された倉庫での FOB またはタンク間転送による物理的な配送',
                    column_2_16: '100 契約 (合計 1,000 MT)',
                    column_2_17: ' SPAN 基準',
                    column_2_18: '注文ごとに 500 契約',

                },
            },
            privacyPolicy: {
                title: 'プライバシー ポリシー',
                d1: '簡単に言うと,私たちはアルファ ファンド マネジメント Pte. Ltd. (\'アルファ\',\'私たち\'または\'私たち\')。私たちはあなたのプライバシーを尊重し,あなたが私たちに提供する情報の機密性を保護するために懸命に取り組んでいます。当社は,お客様が要求したサービスおよびデータの提供に必要な場合にのみ,お客様の個人データを第三者と共有します (このプライバシー ポリシーで詳細に説明されています)',
                d2: '私たちについて',
                d3: 'Alpha は,お客様の個人データの責任を負うデータ管理者であり,Alpha モバイルおよびデスクトップ アプリケーション,Alpha サービス (それぞれ\'サービス\'),およびそこで収集されたすべての情報の唯一の所有者です。 Alpha は,いくつかの方法でユーザーから情報を収集します (このプライバシー ポリシーで詳しく説明されています)',
                d4: 'このプライバシー ポリシーは,お客様がアカウントを作成したり,当社のアプリケーションやサービスを使用したりする際にお客様から収集する個人データに適用されます (\'お客様\'または\'お客様の\'は適宜解釈されます)。当社が収集する個人データ,個人データを収集する理由,使用方法,個人データを処理する法的根拠,保管方法,情報の共有方法に関する情報を提供します',
                d5: 'このプライバシー ポリシーで使用されている\'個人データ\'とは,あなたを特定できる,あなたに関連するすべての情報を意味します',
                d6: '当社のサービスを使用するか,個人データを送信することにより,このプライバシー ポリシーの条項に同意したことになりますので,よくお読みください。いつでもこのプライバシー ポリシーに同意しない場合は,当社のサービスの使用を停止し,ユーザー アカウントを削除する必要があります',
                d7: '当社が収集する個人データ',
                d8: 'シンボル,相場,財務データ,Alpha ユーザーのアイデアや公開データなど,特定の市場の情報を検索して表示する場合,アカウントを作成する必要はありません。',
                d9: '1) ユーザー アカウント',
                d10: 'アカウントを作成する場合は,ユーザーが恩恵を受ける追加サービスへのアクセスを提供できるように,いくつかの個人データを提供する必要があります.また,以下に説明するように,お客様に関する追加情報を当社に提供することもできます',
                d11: '登録時に,次のいずれかを提供する必要があります:',
                d12: 'a) ユーザー名,メールアドレス;',
                d13: 'b) 次のいずれかのソーシャル インターネット サービスのアカウント情報: Facebook,Twitter,Google,Yahoo!,LinkedIn。そして',
                d14: 'c) サブスクリプションを購入する場合は,姓,名,住所が必要です',
                d15: 'd) 場合によっては,電話番号の提供と確認が必要になることがあります。プロフィール設定で自分に関する追加情報を追加することもできますが,これは必須ではありません',
                d16: '次の情報が公開されます:',
                d17: 'e) ユーザー アバター,署名,\'自己紹介\',場所として提供することを選択した画像',
                d18: 'f) 次の情報は公開されません:',
                d19: 'g) 氏名,電話番号,電子メール,会社名,住所,および納税者 ID.',
                d20: '2) 連絡先データ',
                d21: 'また,電話,電子メール,またはその他の方法で当社が運営する他のサービスを訪問してやり取りする際に,お客様が提供することを選択した個人データを処理します。',
                d22: '3) ログファイル',
                d23: 'Web サーバーのログ ファイルを使用します。ログ ファイルの記録には,インターネット プロトコル (IP) アドレス,ブラウザーの種類,インターネット サービス プロバイダー,参照ページ,終了ページ,プラットフォームの種類,日付/タイム スタンプが含まれます。 Web サーバーのログ ファイルを使用して,サイトを管理し,サイト計画の目的で広範なトラフィック情報を提供し,サービス契約の条件が遵守されていることを確認します。 IP アドレスは,当社の Web サーバー ログ ファイル内の個人データに関連付けられていないことに注意してください',
                d24: '4) デバイス情報',
                d25: '当社は,お客様が当社のサービスと対話するために使用するデバイスに関する次の情報を収集します: デバイスの IP アドレス (そこから,接続している国を推測することもできます),オペレーティング システム,およびブラウザーのバージョン。この情報は,スパムやサービスの悪用を防ぐために収集されます。 IP アドレスは,特定の法域では個人データと見なされる場合があり,このプライバシー ポリシーに従って処理されます',
                d26: '5) 分析',
                d27: '当社のウェブサイト,モバイルおよびデスクトップ アプリケーション,または当社が別のサイトに提供するウィジェットを使用している場合,分析情報が自動的に収集される場合があります。この情報には,一般的なデバイス情報,デバイス識別子 (たとえば,Android デバイスを使用している場合は Google 広告 ID,iOS デバイスを使用している場合は広告識別子 (IDFA)),ネットワーク情報, ウェブサイト,およびアプリケーションが含まれる場合があります。インタラクションイベント。当社は仮名データのみを収集し,この分析情報には,お客様のプロフィールから個人を特定できる情報は含まれません。この情報を使用して,サービスとアプリケーションの動作を分析および改善し,サービスをさらに開発,テスト,および改善します。この目的のために,内部分析およびサードパーティのサービスプロバイダーを使用する場合があります。サード パーティのサービス プロバイダーの使用に関する詳細については,以下の分析プロバイダーのセクションを参照してください',
                d28: '6) 第三者から受け取るデータ',
                d29: 'ソーシャル インターネット サービスの資格情報 (Facebook,Twitter,Google,Yahoo!,LinkedIn) を使用してアカウントにサインインすると,そのようなサービスは,ユーザー名と,使用した資格情報に関連付けられた電子メール アドレスを当社に送信します。私たちは,第三者があなたに関する情報を提供したり,あなたの個人データを処理したりする方法について,管理も責任も負いません',
                d30: '個人情報の使用方法',
                d31: '当社は,お客様が使用しようとするサービスを提供するために必要なさまざまな方法,特定の正当な利益,および適用法を遵守するために必要なさまざまな方法で個人データを使用します。詳細については以下で説明します。',
                d32: '別の法的根拠がある場合,私たちは通常,あなたの個人データの処理を許可するためにあなたの同意に頼ることはありません。あなたの同意に頼る場合は,その時点でこれを明確にします。',
                d33: '1) プロファイル',
                d34: 'プロファイルは,個々のユーザーごとに,閲覧の好みを詳述する一連の情報です。ユーザー設定 (チャート設定,ツールバー,ボタンなど) は,ウェブサイト,アプリケーション,またはユーザー アカウントを使用する際のユーザー エクスペリエンスをカスタマイズする目的でのみ保持されます。プライベート プロフィールの設定を第三者と共有することはありません',
                d35: 'アカウントを作成すると,他のユーザーや訪問者があなたの公開プロフィールにアクセスし,あなたが公開した自分に関する情報を見ることができることに注意してください。',
                d36: '2) ユーザーアカウントとプロファイルの操作',
                d37: 'ユーザー名,メールアドレス,電話番号などの連絡先の詳細を次の方法で使用します:',
                d38: 'a) お客様のユーザー アカウントを認証し,そのセキュリティと当社のサービスのセキュリティを確保し,スパム,詐欺,悪用を防止するため;',
                d39: 'b) お客様のユーザー アカウントを承認,確認,処理するため。',
                d40: 'c) すべての新規ユーザーにウェルカム アクティベーション メールを送信して,メール アドレスを確認する。',
                d41: 'd) (有料ユーザー向け) サブスクリプション サービスを提供し,そのようなサービスの使用に関して連絡を取るため。これは,お客様のリクエストを実装するために必要です。そして',
                d42: 'e) 有料ユーザーは,自動更新サブスクリプションのカードへの請求に関して問題がある場合,PayPal から通知メールを受け取ります。ユーザーは自分のアカウントを削除することはできますが,これらのメッセージをオプトアウトすることはできません',
                d43: '3) ユーザーアカウントの削除',
                d44: 'お客様は,当社の ウェブサイトおよび/またはアプリケーションの使用を拒否することができ,プロファイル設定でお客様のアカウントを削除するよう当社に依頼することができます。アカウントは 30 日後に削除されます。この間,お客様はアカウントの削除を要求した場合,お客様が当社に提供したその後の個人データを削除することに注意してください。ただし,公開されたアイデア/スクリプトや他のユーザーに送信されたメッセージなどの一部のデータは,既にシステムに統合されているため保存されます。このデータは,プラットフォームの整合性を維持するため,および監査やセキュリティ目的を含む正当なビジネス プロセスのために保存する必要があります',
                d45: 'アカウントが削除されると,サード パーティと同様に検索エンジンも,公開されている情報のコピーを保持できることに注意してください。また,公開されたアイデアはサイトに残り,他のユーザーに送信されたメッセージは削除できないことに注意してください',
                d46: '4) マーケティング',
                d47: '当社は,お客様が購読している製品またはサービスに類似した当社の製品またはサービスに関するマーケティング メールを送信する場合があります。これには,お客様が興味を持つと思われるイベントやその他のプロモーションに関する情報が含まれます。これは,このような方法での通信の受信を希望しないことをお客様が当社に示していない限りです。当社は,お客様が個人データを当社に提供した時点でお客様の同意を得た場合にのみ,その他のプロモーション情報を電子メールで送信します。ユーザーは,いずれかの電子メールで\'登録解除\'をクリックすることにより,いつでもマーケティング コミュニケーションの受信をオプトアウトできます',
                d48: '5) ソーシャル通知',
                d49: 'あなたをフォローしている新しいユーザー,アイデアにいいね! した,あなたがフォローしているユーザーの新しいアイデアなどについて,メールで通知できます。プロファイル設定を調整することで,いつでもそのような通知の受信を拒否できます',
                d50: '6) サービス関連のお知らせ',
                d51: 'まれに,サービス関連のお知らせを送信する必要がある場合があります。たとえば,当社のサービスが長期間中断された場合,または主要な機能のアップグレードがリリースされた場合,すべてのユーザーに電子メール メッセージを送信することがあります',
                d52: '7) 正当な利益',
                d53: '当社は,以下を含む特定の正当なビジネス上の利益のために,必要に応じてあなたの個人データを処理します:',
                d54: 'a) お客様からのお問い合わせ,コメント,または苦情への対応を求められる場合',
                d55: 'b) 訪問者がウェブサイトおよびアプリケーションとどのようにやり取りするかをよりよく理解するために,当社のサービスを管理し,それらがお客様およびお客様のコンピューター/デバイスにとって最も効果的な方法で表示されるようにするため',
                d56: 'c) 当社のモバイル アプリケーションを開発および改善するため。',
                d57: 'd) 上記のように,お客様の同意が必要な場合を除き,管理目的で,サブスクリプション サービスを提供し,販売およびマーケティング活動に関連して,関連事業間で個人データを共有するため。',
                d58: 'e) お客様が購読している製品またはサービスに類似する当社の製品またはサービスに関する情報を送信するため (ただし,お客様が電子メール アドレスを当社に提供した時点で,またはこれらの電子メールの受信を拒否またはオプトアウトした場合を除きます)。この方法での通信の受信を希望しないことを当社に示しました)。当社の正当な利益は,当社のマーケティングがお客様に関連していることを確認することです。そのため,お客様のデータを処理して,お客様の興味に合わせて特別に調整された当社の製品またはサービスに関する情報を送信する場合があります',
                d59: 'f) 当社は,収集したデータを匿名化,仮名化,集約,および匿名化して,当社のビジネス パートナー,関連会社,代理店,およびその他の第三者と共有することを含め,当社の内部ビジネス目的でこのデータを使用することがあります。商業,統計,および市場調査の目的。たとえば,これらの当事者が人々のグループ間のパターンを分析し,人口統計,関心,および行動に関する調査を実施できるようにすること',
                d60: 'g) トラブルシューティング,データ分析,テスト,調査,統計,および調査の目的を含む社内のビジネス/技術業務のため,および当社の ウェブサイトとアプリケーション,ネットワーク,および情報システムを安全に保つための取り組みの一環として。そして',
                d61: 'h) (a) 法的義務を遵守するため,(b) 管轄当局からの要請に応じるため。 (c) 当社の利用規約またはハウスルールを施行する。 (d) 当社または当社の関連事業を保護するため。 (e) 当社の権利,安全または財産,および/または当社の関連事業,お客様または他者の権利,安全または財産を保護するため。 (f) 法的権利の行使または防御,または損害の防止。これは,法律を遵守するためにそのような開示が必要であると判断した場合,当社がお客様の情報を保存し,法執行機関,州,または政府機関に開示できることを意味します。これには,裁判所の命令または召喚状への対応,およびその他の司法または規制プロセスが含まれます',
                d62: 'このプライバシー ポリシーで使用されている\'正当な利益\'とは,Alpha およびその関連会社が,当社の事業を実施および管理する上での利益を意味します。当社が正当な利益のためにお客様の個人データを処理する場合,お客様およびデータ保護法に基づくお客様の権利に対する潜在的な影響を考慮し,バランスを取るようにします。当社の正当な利益が自動的にお客様の利益を無効にすることはありません。私たちは,明示的な同意がある場合,またはそれらの活動が法律で要求または許可されている場合を除き,私たちの利益がユーザーに与える可能性のある影響に優先する活動に個人データを使用しません。お客様は,お客様の特定の状況に関連する理由に基づいて,当社の正当な利益に基づくお客様の個人データの処理にいつでも異議を唱える権利を有します',
                d63: 'いつ個人情報を共有するか',
                d64: '次の状況で,お客様の個人データを第三者と共有します:',
                d65: 'a) サービス プロバイダー: Alpha は,多くの企業と同様に,(i) カード処理または支払いサービス,(ii) IT サプライヤーおよび請負業者 (つまり,データ ホスティング プロバイダーまたは配信パートナー) IT サポートを提供し,この ウェブサイトおよびアプリケーションで利用可能なサブスクリプション サービスおよびその他の商品/サービスを,またはユーザーに提供できるようにするために必要な場合,(iii) Web およびモバイル分析プロバイダー,(iv) デジタル データのプロバイダー広告サービス,および (v) マーケティングおよび販売ソフトウェア ソリューションのプロバイダー。当社の指示に従い,これらの当事者は,当社に対する職務を遂行する過程で,当社が彼らを雇って提供するサービスを実行するためだけに,お客様の個人データにアクセス,処理,または保存する場合があります',
                d66: 'b) 支払い情報: 有料ユーザーは,支払い情報 (名前,支払いカード情報,または支払いアカウント情報) を提供する必要があります。この情報は請求目的のみに使用され,当社の支払い処理業者によって処理されます。お客様が支払いを行っている場合,当社はお客様の支払いカード情報にアクセスできません。注文の処理で問題が発生した場合は,ご提供いただいたメール アドレスを使用してご連絡いたします',
                d67: 'c) サード パーティのコンテンツ プロバイダー: IDC またはその他の証券取引所から市場データを購入すると,連絡先の詳細,電話番号,住所,電子メールが提供され,契約に署名します。このデータは,取引所へのアクセスを提供するために,IDC または他の証券取引所によっても処理されます',
                d68: 'd) Alpha 関連企業: お客様が当社に要求するサービスを提供するために,当社の関連企業は,お客様に製品やサービスを提供することを含め,上記の目的でお客様から収集した情報にアクセスして処理する場合があります。当社の関連事業は,当社が最初にデータを収集した目的でのみ,お客様のデータを使用します',
                d69: 'e) ブローカー: ブローカーの口座開設フォームに記入すると,このフォームに記入された個人データがブローカーに送信されます: 氏名,電話番号,電子メール アドレス,国。このデータはブローカーによって処理され,あなたの証券口座が開設されます',
                d70: 'f) 分析プロバイダー: 当社は,サードパーティのサービス プロバイダーを使用して,当社のサービスおよびモバイル アプリケーションの使用を監視および分析する場合があります。この情報を使用して,サービスとモバイル アプリケーションの動作を分析および改善します',
                d71: 'g) 事業譲渡: 当社が事業を売却するか,または当社の資産が第三者に取得された場合,一般アカウント ユーザー,サブスクリプション アカウント ユーザーまたは顧客に関して当社が保有する個人データは,譲渡される資産の 1 つになる可能性があります',
                d72: 'h) 管理上および法律上の理由: (i) 法的義務および/または司法または規制手続き,裁判所命令,またはその他の法的手続きを遵守するため,(ii) 当社の条件を執行するために,当社がお客様の個人データを開示する必要がある場合(iii) 当社または当社の請負業者を損失または損害から保護するため。これには,警察,裁判所,または法執行機関との情報交換が含まれますが,これらに限定されません',
                d73: 'i) 広告: 当社は,ウェブサイトおよびアプリケーションに広告を表示し,リマーケティングおよび追跡機能を使用する場合があります。リマーケティング機能により,他の ウェブサイトでのユーザーの活動に基づいて,よりターゲットを絞った方法で広告を表示し,ユーザーが興味を持っている広告を表示することができます。追跡機能により,インプレッション,広告クリック,およびユーザーが当社の ウェブサイトおよびアプリケーションを通じて完了した特定のアクションに関する情報を収集できます。このデータは,広告キャンペーンのパフォーマンスを評価するのにも役立ちます。仮名データのみを収集することに注意してください',
                d74: '個人情報を広告主と共有しないことに注意してください。広告が生成したインプレッション数とクリック数を含む一般統計にのみアクセスできます',
                d75: '個人データのセキュリティ',
                d76: '私たちは技術的および組織的な保護手段を使用して個人データを保護し,私たちが協力する第三者にも同じことを要求します。',
                d77: 'お客様から送信された特定の情報を保護するために,トランスポート レイヤー セキュリティ (TLS) 暗号化技術を使用しています。この技術により,お客様の情報が Alpha または支払い処理業者に送信される際に,第三者に傍受されることを防ぎます。注文フォームなどの安全なページでは,ブラウザ ウィンドウに\'ロック\'アイコンが表示され,ウェブサイトおよびアプリケーションとの安全で暗号化された接続が確立されていることが確認されます。当社は,当社のサービスが安全であり,業界基準を満たしていることを確認するために懸命に取り組んでいます。また,システムやデータへの不正アクセスを制御するために,ファイアウォール,認証システム (パスワード,個人識別番号など),アクセス制御メカニズムなど,その他の保護手段も使用しています。アカウントを作成することを選択した場合,アクセスの詳細を秘密に保つために合理的にできるすべてのことを行う責任があります。これらの詳細を他の誰とも共有してはなりません',
                d78: 'オフラインでもユーザー情報を保護するために最善を尽くしています。すべてのユーザーの個人情報は,当社のオフィスに限定されています。仕事を遂行するために情報を見る必要がある従業員だけがアクセスを許可されます',
                d79: '個人データを保存するサーバーは,施錠された施設内の安全な環境にあります。収集したデータを保護,保存,処理する方法を常に改善しています。これには,システムへの不正アクセスに対抗するのに役立つ物理的なセキュリティ対策の追加が含まれます。ただし,電子的な送信または保存の方法は 100% 安全ではありません。したがって,絶対的なセキュリティを保証することはできません',


            },

        },
        register: {
            firstName: '名',
            lastName: '姓',
            phoneNumber: '電話番号',
            select:'選択',
            email: 'メール',
            phone:'電話',
            verificationCode:'検証コード',
            getCode:'コードを取得',
            password: 'パスワード',
            confirmPassword: 'パスワードの確認',
            iDNumber: 'ID番号',
            submit: '送信',
            required: '* 必須',
            step1: '登録',
            step2: '口座開設',
            success1: '正常に送信されました',
            success2: '口座開設の審査は 1 ～ 3 営業日以内に完了します',
            pass:'口座開設の審査が通過しました',
            noPass:'口座開設審査不合格',
            back:'戻って再提出する',
            codeError:'正しい検証コードを入力してください',
            getImgError:'新しいグラフィック キャプチャを取得するのに 5 秒かかります',
            d1: '身元を確認するには,政府発行の個人 ID 番号またはパスポートを知る必要があります。',
            d2: 'Identity Doc 前面',
            d3: '本人確認書類の裏面',
            d4: 'ファイルを参照',
            d5: ' または画像をドラッグしてアップロード',
            d6: 'または',
            d7: 'サンプル画像を選択',
            d8: '下の\'送信\'ボタンをクリックすることにより,あなたは私たちに同意したことになります',
            d9: 'プライバシー ポリシー',
            d10: '.',
            d11: '有効な電子メール アドレスではありません',
            d12:'* ログイン アカウントとして電子メールまたは電話番号を選択',
            d13:'8文字以上',
            d14:'パスワードが一致しません',
            AD: 'アンドラ',
            AE: 'アラブ首長国連邦',
            AF: 'アフガニスタン',
            AG: 'アンティグア・バーブーダ',
            AI: 'アンギラ',
            AL: 'アルバニア',
            AM: 'アルメニア',
            AO: 'アンゴラ',
            AR: 'アルゼンチン',
            AT: 'オーストリア',
            AU: 'オーストラリア',
            AZ: 'アゼルバイジャン',
            BB: 'バルバドス',
            BD: 'バングラデシュ',
            BE: 'ベルギー',
            BF: 'ブルキナファソ',
            BG: 'ブルガリア',
            BH: 'バーレーン',
            BI: 'ブルンジ',
            BJ: 'ベナン',
            BL: 'パレスチナ',
            BM: 'バミューダです。',
            BN: 'ブルネイ',
            BO: 'ボリビア',
            BR: 'ブラジル',
            BS: 'バハマ',
            BW: 'これほどのものはありません',
            BY: 'ベラルーシ',
            BZ: 'ベリーズ',
            CA: 'カナダ',
            KY: 'ケイマンです',
            CF: '中央アフリカ共和国',
            CG: 'コンゴ',
            CH: 'スイス',
            CK: 'クックです',
            CL: 'チリ',
            CM: 'カメルーン',
            CN: '中国',
            CO: 'コロンビア',
            CR: 'コスタリカ',
            CU: 'キューバ',
            CY: 'キプロス',
            CZ: 'チェコ共和国',
            DE: 'ドイツ',
            DJ: 'ジブチ',
            DK: 'デンマーク',
            DO: 'ドミニカ共和国',
            DZ: 'アルジェリア',
            EC: 'エクアドル',
            EE: 'エストニア',
            EG: 'エジプト',
            ES: 'スペイン',
            ET: 'エチオピア',
            FI: 'フィンランド',
            FJ: 'フィジー',
            FR: 'フランス',
            GA: 'ガボン',
            GB: 'イギリス',
            GD: 'グレナダ',
            GE: 'ジョージア',
            GF: 'フランス領ギアナ',
            GH: 'ガーナ',
            GI: 'ジブラルタル',
            GM: 'ガンビア',
            GN: 'ギニア',
            GR: 'ギリシャ',
            GT: 'グアテマラ',
            GU: 'グアム',
            GY: 'ガイアナ',
            HK: '香港',
            HN: 'ホンジュラス',
            HT: 'ハイチ',
            HU: 'ハンガリー',
            ID: 'インドネシア',
            IE: 'アイルランド',
            IL: 'イスラエル',
            IN: 'インド',
            IQ: 'イラク',
            IR: 'イラン',
            IS: 'アイスランド',
            IT: 'イタリア',
            CI: 'コートジボワール',
            JM: 'ジャマイカ',
            JO: 'ヨルダン',
            JP: '日本',
            KE: 'ケニア',
            KG: 'キルギス',
            KH: 'カンプチア(カンボジア)',
            KP: '北朝鮮',
            KR: '韓国',
            KT: 'コートジボワール共和国',
            KW: 'クウェート',
            KZ: 'カザフスタン',
            LA: 'ラオス',
            LB: 'レバノン',
            LC: 'セントルシア',
            LI: 'リヒテンシュタイン',
            LK: 'スリランカ',
            LR: 'リベリア',
            LS: 'レソト',
            LT: 'リトアニア',
            LU: 'ルクセンブルグ',
            LV: 'ラトビア',
            LY: 'リビア',
            MA: 'モロッコ',
            MC: 'モナコ',
            MD: 'モルドバ共和国',
            MG: 'マダガスカル',
            ML: 'マリ',
            MM: 'ビルマ',
            MN: 'モンゴル',
            MO: 'マカオ',
            MS: 'モントセラト島',
            MT: 'マルタ',
            MQ: 'マルティニーク',
            MU: 'モーリシャス',
            MV: 'モルディブ',
            MW: 'マラウイ',
            MX: 'メキシコ',
            MY: 'マレーシア',
            MZ: 'モザンビーク',
            NA: 'ナミビア',
            NE: 'ニジェール',
            NG: 'ナイジェリア',
            NI: 'ニカラグア',
            NL: 'オランダ',
            NO: 'ノルウェー',
            NP: 'ネパール',
            AN: 'オランダ領アンティル諸島',
            NR: 'ナウル',
            NZ: 'ニュージーランド',
            OM: 'オマーン',
            PA: 'パナマ',
            PE: 'ペルー',
            PF: 'フランス領ポリネシア',
            PG: 'パプアニューギニア',
            PH: 'フィリピン',
            PK: 'パキスタン',
            PL: 'ポーランド',
            PR: 'プエルトリコ',
            PT: 'ポルトガル',
            PY: 'パラグアイ',
            QA: 'カタール',
            RE: '再会',
            RO: 'ルーマニア',
            RU: 'ロシア',
            SA: 'サウジアラビア',
            SB: 'ソロモンは',
            SC: 'セーシェル',
            SD: 'スーダン',
            SE: 'スウェーデン',
            SG: 'シンガポール',
            SI: 'スロベニア',
            SK: 'スロバキア',
            SL: 'シエラレオネ',
            SM: 'サンマリノ',
            AS: '東サモア',
            WS: 'サンマリノ',
            SN: 'セネガル',
            SO: 'ソマリア',
            SR: 'スリナム',
            ST: 'サントメと王子様',
            SV: '救世主',
            SY: 'シリア',
            SZ: 'スワジランド',
            TD: 'チャド',
            TG: 'トーゴ',
            TH: 'タイ',
            TJ: 'タジキスタン',
            TM: 'トルクメニスタン',
            TN: 'チュニジア',
            TO: 'ビルド',
            TR: 'トルコ',
            TT: 'トリニダード・トバゴ',
            TW: '台湾',
            TZ: 'タンザニア',
            UA: 'ウクライナ',
            UG: 'ウガンダ',
            US: 'アメリカ合衆国',
            UY: 'ウルグアイ',
            UZ: 'ウズベキスタン',
            VC: 'セントビンセント',
            VE: 'ベネズエラ',
            VN: 'ベトナム',
            YE: 'イエメン',
            YU: 'ユーゴスラビア',
            ZA: '南アフリカ',
            ZM: 'ザンビア',
            ZR: 'ザイール',
            ZW: 'ジンバブエ',
        },
        login: {
            username: '電話番号/メール',
            password: 'パスワード',
            login: 'ログイン',
            required: '* 必須',
            have: '既にアカウントを持っていますか？ログイン',
            other: '別のアカウントにログイン',
            create: '新しいアカウントを作成する'
        }

    }
}
