import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import BitcoinPerpetualFutures from '../views/cryptocurrencies/BitcoinPerpetualFutures'
import BitcoinMonthlyFutures from '../views/cryptocurrencies/BitcoinMonthlyFutures'
import GoldFutures from "../views/precious_metals/GoldFutures";
import SilverFutures from "../views/precious_metals/SilverFutures";
import USDCNHFutures from "../views/forex/USDCNHFutures";
import PalmOleinFutures from "../views/commodities/PalmOleinFutures";
import CrudePalmOilFutures from "../views/commodities/CrudePalmOilFutures";
import FuelOilFutures from "../views/commodities/FuelOilFutures";
import LowSulphurFuelOilFutures from "../views/commodities/LowSulphurFuelOilFutures";
import PrivacyPolicy from "../views/PrivacyPolicy";

Vue.use(VueRouter)

const routes = [
    {
        path: '*',
        redirect: '/'
    },
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/bitcoin-perpetual-futures',
        name: 'BitcoinPerpetualFutures',
        component: BitcoinPerpetualFutures
    },
    {
        path: '/bitcoin-monthly-futures',
        name: 'BitcoinMonthlyFutures',
        component: BitcoinMonthlyFutures
    },
    {
        path: '/gold-futures',
        name: 'GoldFutures',
        component: GoldFutures
    },
    {
        path: '/silver-futures',
        name: 'SilverFutures',
        component: SilverFutures
    },
    {
        path: '/usd-cnh-futures',
        name: 'USDCNHFutures',
        component: USDCNHFutures
    },
    {
        path: '/palm-olein-futures',
        name: 'PalmOleinFutures',
        component: PalmOleinFutures
    },
    {
        path: '/crude-palm-oil-futures',
        name: 'CrudePalmOilFutures',
        component: CrudePalmOilFutures
    },
    {
        path: '/fuel-oil-futures',
        name: 'FuelOilFutures',
        component: FuelOilFutures
    },
    {
        path: '/low-sulphur-fuel-oil-futures',
        name: 'LowSulphurFuelOilFutures',
        component: LowSulphurFuelOilFutures
    },
    {
        path: '/privacy-policy',
        name: 'PrivacyPolicy',
        component: PrivacyPolicy
    },


]

const router = new VueRouter({
    routes,
    mode: "history",
})

export default router
