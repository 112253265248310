import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		openAccountShow: false,
		disclaimerShow: false,
		codeTime: 0,
		url: process.env.NODE_ENV === 'development' ? 'http://192.168.0.51:8107' : 'https://crm.alphaapexsg.com',
	},
	mutations: {},
	actions: {},
	modules: {},
})
