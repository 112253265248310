<template>
  <div class="describeWrap">
    <div class="d_top_banner">
      <img class="d_top_banner_img" :src="topBanner">
      <div class="d_top_banner_main">
        <div class="d_top_banner_title">
          <p class="d_top_banner_p1">
            {{ $t('views.pages.topTitle') }}
          </p>
          <p class="d_top_banner_p2">
            {{ $t('views.pages.privacyPolicy.title') }}
          </p>
        </div>
      </div>
    </div>
    <div class="d_main">
      <div class="d_left">

      </div>
      <div class="d_right">
        <div class="d_title">
          {{ $t('views.pages.privacyPolicy.title') }}
        </div>
        <div class="d_line"/>
        <div class="d_describe">
          <p>{{ $t('views.pages.privacyPolicy.d1') }}</p><br>
          <p style="font-weight: bold">{{ $t('views.pages.privacyPolicy.d2') }}</p>
          <br>
          <p>{{ $t('views.pages.privacyPolicy.d3') }}</p>
          <br>
          <p>{{ $t('views.pages.privacyPolicy.d4') }}</p>
          <br>
          <P>{{ $t('views.pages.privacyPolicy.d5') }}</P>
          <br>
          <P>{{ $t('views.pages.privacyPolicy.d6') }}</P>
          <br>
          <P style="font-weight: bold">{{ $t('views.pages.privacyPolicy.d7') }}</P>
          <br>
          <P>{{ $t('views.pages.privacyPolicy.d8') }}</P>
          <br>
          <P style="font-weight: bold">{{ $t('views.pages.privacyPolicy.d9') }}</P>
          <br>
          <P>{{ $t('views.pages.privacyPolicy.d10') }}</P>
          <br>
          <P>{{ $t('views.pages.privacyPolicy.d11') }}</P>
          <br>
          <P style="margin-left: 24px">{{ $t('views.pages.privacyPolicy.d12') }}</P>
          <br>
          <P style="margin-left: 24px">{{ $t('views.pages.privacyPolicy.d13') }}</P>
          <br>
          <P style="margin-left: 24px">{{ $t('views.pages.privacyPolicy.d14') }}</P>
          <br>
          <P style="margin-left: 24px">{{ $t('views.pages.privacyPolicy.d15') }}</P>
          <br>
          <P>{{ $t('views.pages.privacyPolicy.d16') }}</P>
          <br>
          <p style="margin-left: 24px">{{ $t('views.pages.privacyPolicy.d17') }}</P>
          <br>
          <P style="margin-left: 24px">{{ $t('views.pages.privacyPolicy.d18') }}</P>
          <br>
          <P style="margin-left: 24px">{{ $t('views.pages.privacyPolicy.d19') }}</P>
          <br>
          <P style="font-weight: bold">{{ $t('views.pages.privacyPolicy.d20') }}</P>
          <br>
          <P>{{ $t('views.pages.privacyPolicy.d21') }}</P>
          <br>
          <P style="font-weight: bold">{{ $t('views.pages.privacyPolicy.d22') }}</P>
          <br>
          <P>{{ $t('views.pages.privacyPolicy.d23') }}</P>
          <br>
          <P style="font-weight: bold">{{ $t('views.pages.privacyPolicy.d24') }}</P>
          <br>
          <P>{{ $t('views.pages.privacyPolicy.d25') }}</P>
          <br>
          <P style="font-weight: bold">{{ $t('views.pages.privacyPolicy.d26') }}</P>
          <br>
          <P>{{ $t('views.pages.privacyPolicy.d27') }}</P>
          <br>
          <P style="font-weight: bold">{{ $t('views.pages.privacyPolicy.d28') }}</P>
          <br>
          <P>{{ $t('views.pages.privacyPolicy.d29') }}</P>
          <br>
          <P style="font-weight: bold">{{ $t('views.pages.privacyPolicy.d30') }}</P>
          <br>
          <P>{{ $t('views.pages.privacyPolicy.d31') }}</P>
          <br>
          <P>{{ $t('views.pages.privacyPolicy.d32') }}</P>
          <br>
          <P style="font-weight: bold">{{ $t('views.pages.privacyPolicy.d33') }}</P>
          <br>
          <P>{{ $t('views.pages.privacyPolicy.d34') }}</P>
          <br>
          <P>{{ $t('views.pages.privacyPolicy.d35') }}</P>
          <br>
          <P style="font-weight: bold">{{ $t('views.pages.privacyPolicy.d36') }}</P>
          <br>
          <P>{{ $t('views.pages.privacyPolicy.d37') }}</P>
          <br>
          <P style="margin-left: 24px">{{ $t('views.pages.privacyPolicy.d38') }}</P>
          <br>
          <P style="margin-left: 24px">{{ $t('views.pages.privacyPolicy.d39') }}</P>
          <br>
          <P style="margin-left: 24px">{{ $t('views.pages.privacyPolicy.d40') }}</P>
          <br>
          <P style="margin-left: 24px">{{ $t('views.pages.privacyPolicy.d41') }}</P>
          <br>
          <P style="margin-left: 24px">{{ $t('views.pages.privacyPolicy.d42') }}</P>
          <br>
          <P style="font-weight: bold">{{ $t('views.pages.privacyPolicy.d43') }}</P>
          <br>
          <P>{{ $t('views.pages.privacyPolicy.d44') }}</P>
          <br>
          <P>{{ $t('views.pages.privacyPolicy.d45') }}</P>
          <br>
          <P style="font-weight: bold">{{ $t('views.pages.privacyPolicy.d46') }}</P>
          <br>
          <P>{{ $t('views.pages.privacyPolicy.d47') }}</P>
          <br>
          <P style="font-weight: bold">{{ $t('views.pages.privacyPolicy.d48') }}</P>
          <br>
          <P>{{ $t('views.pages.privacyPolicy.d49') }}</P>
          <br>
          <P style="font-weight: bold">{{ $t('views.pages.privacyPolicy.d50') }}</P>
          <br>
          <P>{{ $t('views.pages.privacyPolicy.d51') }}</P>
          <br>
          <P style="font-weight: bold">{{ $t('views.pages.privacyPolicy.d52') }}</P>
          <br>
          <P>{{ $t('views.pages.privacyPolicy.d53') }}</P>
          <br>
          <P style="margin-left: 24px">{{ $t('views.pages.privacyPolicy.d54') }}</P>
          <br>
          <P style="margin-left: 24px">{{ $t('views.pages.privacyPolicy.d55') }}</P>
          <br>
          <P style="margin-left: 24px">{{ $t('views.pages.privacyPolicy.d56') }}</P>
          <br>
          <P style="margin-left: 24px">{{ $t('views.pages.privacyPolicy.d57') }}</P>
          <br>
          <P style="margin-left: 24px">{{ $t('views.pages.privacyPolicy.d58') }}</P>
          <br>
          <P style="margin-left: 24px">{{ $t('views.pages.privacyPolicy.d59') }}</P>
          <br>
          <P style="margin-left: 24px">{{ $t('views.pages.privacyPolicy.d60') }}</P>
          <br>
          <P style="margin-left: 24px">{{ $t('views.pages.privacyPolicy.d61') }}</P>
          <br>
          <P>{{ $t('views.pages.privacyPolicy.d62') }}</P>
          <br>
          <P style="font-weight: bold">{{ $t('views.pages.privacyPolicy.d63') }}</P>
          <br>
          <P>{{ $t('views.pages.privacyPolicy.d64') }}</P>
          <br>
          <P style="margin-left: 24px">{{ $t('views.pages.privacyPolicy.d65') }}</P>
          <br>
          <P style="margin-left: 24px">{{ $t('views.pages.privacyPolicy.d66') }} </P>
          <br>
          <P style="margin-left: 24px">{{ $t('views.pages.privacyPolicy.d67') }}</P>
          <br>
          <P style="margin-left: 24px">{{ $t('views.pages.privacyPolicy.d68') }}</P>
          <br>
          <P style="margin-left: 24px">{{ $t('views.pages.privacyPolicy.d69') }}</P>
          <br>
          <P style="margin-left: 24px">{{ $t('views.pages.privacyPolicy.d70') }}</P>
          <br>
          <P style="margin-left: 24px">{{ $t('views.pages.privacyPolicy.d71') }}</P>
          <br>
          <P style="margin-left: 24px">{{ $t('views.pages.privacyPolicy.d72') }}</P>
          <br>
          <P style="margin-left: 24px">{{ $t('views.pages.privacyPolicy.d73') }}</P>
          <br>
          <P>{{ $t('views.pages.privacyPolicy.d74') }}</P>
          <br>
          <P style="font-weight: bold">{{ $t('views.pages.privacyPolicy.d75') }}</P>
          <br>
          <P>{{ $t('views.pages.privacyPolicy.d76') }}</P>
          <br>
          <P>{{ $t('views.pages.privacyPolicy.d77') }}</P>
          <br>
          <P>{{ $t('views.pages.privacyPolicy.d78') }}</P>
          <br>
          <P>{{ $t('views.pages.privacyPolicy.d79') }}</P>

        </div>


      </div>
    </div>
  </div>
</template>

<script>


export default {
  name: "PrivacyPolicy",

  data() {
    return {
      topBanner: require('@/assets/img/describe/header-banner.jpg')
    }
  },

}
</script>

<style lang="scss" scoped>

</style>