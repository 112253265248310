import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/css/reset.scss'
import VueI18n from 'vue-i18n'
import './public_js/units'
import {get, post} from './public_js/http'
import {Message, Steps, Step, Result,Loading} from 'element-ui'

Vue.use(Steps);
Vue.use(Step);
Vue.use(Result);
Vue.use(Loading);
Vue.use(VueI18n);
Vue.prototype.$message = Message;
Vue.prototype.$get = get;
Vue.prototype.$post = post;
const i18n = new VueI18n({
    locale: localStorage.getItem('lang') || 'en', // 语言标识 //this.$i18n.locale // 通过切换locale的值来实现语言切换
    messages: {
        'en': require('@/assets/language/en'),
        'cn': require('@/assets/language/cn'),
        'kr': require('@/assets/language/kr'),
        'jp': require('@/assets/language/jp')
    }
})

Vue.config.productionTip = false


new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount('#app')
