<template>
  <div id="app">
    <header-page/>
    <router-view class="view"/>
    <bottom/>
  </div>
</template>

<style>
#app {

}

.view {
  padding-top: 74px;
}
</style>
<script>


import HeaderPage from "./components/public/headerPage";
import Bottom from "./components/public/bottom";


export default {
  components: {Bottom, HeaderPage},
  watch: {
    $route() {
      window.scrollTo(0, 0);
    }
  },
  mounted() {

  },
  beforeDestroy() {
    localStorage.setItem('lang',"kr");
  }
}
</script>