<template>
  <div class="wrap">
    <div class="main">
      <div class="top_banner">
        <div class="to_banner_img">
          <carousel/>
        </div>
<!--        <div class="title"><p style="word-break : break-word;">Trade the gold in grams, with the global price.</p>-->
<!--          <P class="subtitle">Your Regulated Futures Trading Venue!</P>-->
<!--        </div>-->
      </div>
      <div class="section_2">
        <div class="statistics">
          <p>{{$t('views.home.d1')}}</p>
          <div class="row">
            <div class="col" v-for="(item,index) in statistics" :key="index">
              <p class="t">{{ item.title }}</p>
              <p class="c">{{ item.content }}</p>
            </div>
          </div>
        </div>
      </div>


      <div class="disclaimer"><p>{{$t('views.home.d8')}}
        <br><a @click="$store.state.disclaimerShow=true">{{$t('views.home.d9')}}</a></p></div>

      <disclaimer v-if="$store.state.disclaimerShow"/>


      <h1 class="row_2">{{$t('views.home.d10')}}</h1>
      <div class="row_3">

        <div class="box" v-for="(item,index) in row_3" :key="index" @click="toPage(item.path)">
          <img :src="item.img">
          <div class="tit">
            <div>
              <p style="word-break : break-word;">{{ item.title }}</p>
              <p class="more">{{$t('views.home.d13')}}</p>
            </div>
          </div>
        </div>

      </div>
      <div style="padding: 54px 0 0;">
        <div class="row_4">
          <div class="row_4_main">
            <img :src="row_4Icon">
            <div class="row_4_right">
              <p class="row_title">{{$t('views.home.d14')}}</p>
              <div class="row_4_frame">
                <div class="row_4_box" style="margin-right: 6.186%;background: #fff">
                  <h3 style="color: #000">{{$t('views.home.d15')}}</h3>
                  <!--                  <p class="more_b" style="color: #000">Find out more</p>-->
                </div>
                <div class="row_4_box" style="background: #000">
                  <h3>{{$t('views.home.d16')}}</h3>
                  <!--                  <p class="more">Find out more</p>-->
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>


import Disclaimer from "../components/public/disclaimer";
import Carousel from "../components/public/carousel";

export default {
  name: 'Home',
  components: {Carousel, Disclaimer},
  data() {
    return {

      row_4Icon: require('@/assets/img/home/start-your-journey-graphics.png'),
      statistics: [],
      row_3: []
    }
  },
  methods: {
    toPage(path) {
      this.$router.push(path);
    }
  },
  mounted() {
    this.statistics=[{
      title: this.$t('views.home.d2'),
      content:  this.$t('views.home.d3'),
    },
      {
        title:  this.$t('views.home.d4'),
        content:  this.$t('views.home.d5'),
      },
      {
        title:  this.$t('views.home.d6'),
        content:  this.$t('views.home.d7'),
      },];
    this.row_3 = [
      {
        img: require('@/assets/img/home/monthly-futures.png'),
        title: this.$t('views.home.d11'),
        path: '/bitcoin-perpetual-futures'
      },
      {
        img: require('@/assets/img/home/perpetual-futures-1.png'),
        title: this.$t('views.home.d12'),
        path: '/bitcoin-monthly-futures'
      }
    ]

    // this.$get('https://www.asiapacificex.com/chart/?pa=globalAPIcall&action=tradingVolume',{}).then(res=>{
    //   console.log(res)
    // })
  }

}
</script>

<style lang="scss" scoped>

.wrap {
  min-height: 100vh;
  width: 100%;
  background-color: #0a0e17;

  .main {
    color: #fff;

    .top_banner {
      width: 100%;
      position: relative;
      margin: 0;
      padding: 0;


      .title {
        font-size: clamp(30px, 8vw, 58px);
        font-weight: 700;
        position: absolute;
        width: 90%;
        top: 40%;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
        text-shadow: 0 1px 10px rgba(0, 0, 0, 1);
        word-wrap: break-word;
        animation: title 1s ease;
        word-break: break-word;

        .subtitle {
          font-size: clamp(18px, 3vw, 25px);
          font-weight: 300;
          margin-top: 32px;
          text-align: center;
          text-shadow: 0 1px 3px rgba(0, 0, 0, .3);
          word-wrap: break-word;

        }
      }

      @keyframes title {
        0% {
          opacity: 0;
          transform: translate(-50%, 100%);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, 0);
        }
      }

    }

    .section_2 {
      width: 100%;
      background-color: #0a0e17;
      padding: 54px 0;

      .statistics {
        max-width: 1152px;
        width: 60%;
        min-height: 160px;
        background-image: linear-gradient(180deg, #1f96a8 0%, #1b3044 100%);
        border-radius: 10px 10px 10px 10px;
        overflow: hidden;
        padding: 20px;
        margin: 27px auto;

        p {
          font-size: 26px;
          text-align: center;
          font-weight: 900;
        }

        .row {
          width: 100%;
          margin-top: 32px;
          display: flex;

          .col {
            text-align: center;
            width: 33.3%;

            .t {
              font-weight: normal;
              font-size: 18px;
              margin-bottom: 5px;
            }

            .c {
              font-weight: bold;
              font-size: 24px;
            }
          }


        }
      }

    }

    .disclaimer {
      max-width: 1152px;
      width: 60%;
      margin: 20px auto;
      text-align: center;
      font-size: 14px;
      font-weight: 600;
      padding: 27px 0;
      line-height: 20px;

      a {
        text-decoration: underline;
        cursor: pointer;
      }
    }

    .row_2 {
      text-align: center;
      padding: 27px 0;

    }

    .row_3 {
      width: 90%;
      display: flex;
      justify-content: space-between;
      max-width: 1728px;
      margin: 0 auto;
      padding: 27px 0;

      .box {
        background: #1C495D;
        cursor: pointer;
        font-size: clamp(14px, 2vw, 16px);
        border-radius: 10px;
        display: flex;
        align-items: center;

        &:hover {
          filter: brightness(90%);
        }

        img {
          width: 21.25vw;
          padding: 25px 24px;
        }

        .tit {
          width: 21.25vw;
          padding: 25px 24px;
          font-size: clamp(20px, 4vw, 33px);
          font-weight: bold;


          .more {
            font-size: clamp(14px, 2vw, 16px);
            font-weight: normal;
            margin-top: 20px;

            &::after {
              width: 12px;
              height: 12px;
              background: url("../assets/img/home/right.png") no-repeat;
              content: "";
              display: inline-block;
              margin-left: 4px;
              vertical-align: 0;
              transition: 0.5s;
            }

            &:hover {


              &::after {
                width: 12px;
                height: 12px;
                background: url("../assets/img/home/right.png") no-repeat;
                content: "";
                display: inline-block;
                margin-left: 10px;
                vertical-align: 0;
              }
            }
          }
        }

      }
    }

    .row_4 {
      padding: 54px 0;
      background-image: linear-gradient(180deg, #1f96a8 0%, #1b3044 100%);

      .row_4_main {
        width: 90%;
        max-width: 1728px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;

        img {
          max-width: 741px;
          width: 38.59375vw;
        }

        .row_4_right {
          width: 47.25%;

          .row_title {
            padding: 5.82% 0;
            font-weight: bold;
            font-size: clamp(24px, 1.9vw, 48px);
            word-break: break-word;

          }

          .row_4_frame {
            width: 100%;
            padding: 5.82% 0;
            display: flex;


            .row_4_box {
              cursor: pointer;
              padding: 40px;
              width: 46.90722%;
              border-radius: 10px;

              h3 {
                font-size: clamp(24px, 1vw, 24px);
              }

              .more {
                font-size: clamp(14px, 2vw, 16px);
                font-weight: normal;
                margin-top: 20px;

                &::after {
                  width: 12px;
                  height: 12px;
                  background: url("../assets/img/home/right.png") no-repeat;
                  content: "";
                  display: inline-block;
                  margin-left: 4px;
                  vertical-align: 0;
                  transition: 0.5s;
                }

                &:hover {


                  &::after {
                    width: 12px;
                    height: 12px;
                    background: url("../assets/img/home/right.png") no-repeat;
                    content: "";
                    display: inline-block;
                    margin-left: 10px;
                    vertical-align: 0;
                  }
                }
              }

              .more_b {
                font-size: clamp(14px, 2vw, 16px);
                font-weight: normal;
                margin-top: 20px;

                &::after {
                  width: 12px;
                  height: 12px;
                  background: url("../assets/img/home/right_black.png") no-repeat;
                  content: "";
                  display: inline-block;
                  margin-left: 4px;
                  vertical-align: 0;
                  transition: 0.5s;
                }

                &:hover {


                  &::after {
                    width: 12px;
                    height: 12px;
                    background: url("../assets/img/home/right_black.png") no-repeat;
                    content: "";
                    display: inline-block;
                    margin-left: 10px;
                    vertical-align: 0;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

</style>
