<template>
  <div class="describeWrap">
    <div class="d_top_banner">
      <img class="d_top_banner_img" :src="topBanner">
      <div class="d_top_banner_main">
        <div class="d_top_banner_title">
          <p class="d_top_banner_p1">
            {{ $t('views.pages.topTitle') }}
          </p>
          <p class="d_top_banner_p2">
            {{ $t('views.header.forex.title') }}
          </p>
        </div>
      </div>
    </div>
    <div class="d_main">
      <div class="d_left">
        <route-page :routeIndex="2"/>
      </div>
      <div class="d_right">
        <div class="d_title">
          {{ $t('views.pages.forex.USDCNHFutures.title') }}
        </div>
        <div class="d_line"/>
        <div class="d_describe">
          <p>{{ $t('views.pages.forex.USDCNHFutures.d1') }}</p>
        </div>

        <div class="table">
          <table cellspacing="1" cellpadding="1" border="1">
            <thead>
            <tr>
              <th colspan="2" scope="col"><span
                  style="font-weight: bold;">{{ $t('views.pages.forex.USDCNHFutures.tableTh') }}</span></th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td><strong>{{ $t('views.pages.forex.USDCNHFutures.column_1_01') }}</strong></td>
              <td>{{ $t('views.pages.forex.USDCNHFutures.column_2_01') }}</td>
            </tr>
            <tr>
              <td><strong>{{ $t('views.pages.forex.USDCNHFutures.column_1_02') }}</strong></td>
              <td>
                <p>{{ $t('views.pages.forex.USDCNHFutures.column_2_02') }}</p>
                <p>{{ $t('views.pages.forex.USDCNHFutures.column_2_03') }}</p>
              </td>
            </tr>
            <tr>
              <td><strong>{{ $t('views.pages.forex.USDCNHFutures.column_1_03') }}</strong></td>
              <td>{{ $t('views.pages.forex.USDCNHFutures.column_2_04') }}</td>
            </tr>
            <tr>
              <td><strong>{{ $t('views.pages.forex.USDCNHFutures.column_1_04') }}</strong></td>
              <td>{{ $t('views.pages.forex.USDCNHFutures.column_2_05') }}</td>
            </tr>
            <tr>
              <td><strong>{{ $t('views.pages.forex.USDCNHFutures.column_1_05') }}</strong></td>
              <td>{{ $t('views.pages.forex.USDCNHFutures.column_2_06') }}</td>
            </tr>
            <tr>
              <td><strong>{{ $t('views.pages.forex.USDCNHFutures.column_1_06') }}</strong></td>
              <td>{{ $t('views.pages.forex.USDCNHFutures.column_2_07') }}</td>
            </tr>
            <tr>
              <td><strong>{{ $t('views.pages.forex.USDCNHFutures.column_1_07') }}</strong></td>
              <td>
                <p>{{ $t('views.pages.forex.USDCNHFutures.column_2_08') }}</p>
                <p>{{ $t('views.pages.forex.USDCNHFutures.column_2_09') }}</p>
              </td>
            </tr>
            <tr>
              <td><strong>{{ $t('views.pages.forex.USDCNHFutures.column_1_08') }}</strong></td>
              <td>
                {{
                  $t('views.pages.forex.USDCNHFutures.column_2_10')
                }}<br>{{
                  $t('views.pages.forex.USDCNHFutures.column_2_11')
                }}<br>{{
                  $t('views.pages.forex.USDCNHFutures.column_2_12')
                }}<br>{{ $t('views.pages.forex.USDCNHFutures.column_2_13') }}
              </td>
            </tr>
            <tr>
              <td><strong>{{ $t('views.pages.forex.USDCNHFutures.column_1_09') }}</strong></td>
              <td>{{$t('views.pages.forex.USDCNHFutures.column_2_14')}}
              </td>
            </tr>
            <tr>
              <td><strong>{{ $t('views.pages.forex.USDCNHFutures.column_1_10') }}</strong></td>
              <td>{{$t('views.pages.forex.USDCNHFutures.column_2_15')}}</td>
            </tr>
            <tr>
              <td><strong>{{ $t('views.pages.forex.USDCNHFutures.column_1_11') }}</strong></td>
              <td>{{$t('views.pages.forex.USDCNHFutures.column_2_16')}}
              </td>
            </tr>
            <tr>
              <td><strong>{{ $t('views.pages.forex.USDCNHFutures.column_1_12') }}</strong></td>
              <td>{{$t('views.pages.forex.USDCNHFutures.column_2_17')}}
              </td>
            </tr>
            <tr>
              <td><strong>{{ $t('views.pages.forex.USDCNHFutures.column_1_13') }}</strong></td>
              <td>{{$t('views.pages.forex.USDCNHFutures.column_2_18')}}</td>
            </tr>
            <tr>
              <td><strong>{{ $t('views.pages.forex.USDCNHFutures.column_1_14') }}</strong></td>
              <td>{{$t('views.pages.forex.USDCNHFutures.column_2_19')}}</td>
            </tr>
            <tr>
              <td><strong>{{ $t('views.pages.forex.USDCNHFutures.column_1_15') }}</strong></td>
              <td>{{$t('views.pages.forex.USDCNHFutures.column_2_20')}}
              </td>
            </tr>
            <tr>
              <td><strong>{{ $t('views.pages.forex.USDCNHFutures.column_1_16') }}</strong></td>
              <td>{{$t('views.pages.forex.USDCNHFutures.column_2_21')}}</td>
            </tr>
            </tbody>
          </table>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import RoutePage from "../../components/public/routerPage";

export default {
  name: "BitcoinPerpetualFutures",
  components: {RoutePage},
  data() {
    return {
      topBanner: require('@/assets/img/describe/header-banner.jpg')
    }
  },

}
</script>

<style lang="scss" scoped>

</style>