module.exports = {
	views: {
		home: {
			title0: '아시아 최초의 정식 비트코인 선물거래',
			subTitle: '당신의 정식 선물 거래 장소',
			title1: '그램 단위의 골드를 글로벌 시세로 거래하세요',
			d1: '거래통계',
			d2: '24 시간 거래금액',
			d3: '10.18M',
			d4: '1주일 거래 금액',
			d5: '57.98M',
			d6: '30일 거래 금액',
			d7: '368.04M',
			d8: '저희 비트코인 지수인 Compass Crypto Real Time Index Bitcoin은/는 EU BMR(EU 벤치마크 규정)에 따라 AMF(Autorité des Marchés Financiers)의 벤치마크 관리자로 등록된 COMPASS Financial Technologies(프랑스)에서 제공합니다.',
			d9: '면책조항 보기',
			d10: '상품',
			d11: '비트코인 무기한 선물',
			d12: '비트코인 월간 선물',
			d13: '자세히 알아보기',
			d14: '지금 라이센스 거래소와 함께 투자를 시작하세요',
			d15: 'APEX 상품 전문가에게 문의하기',
			d16: 'APEX 회원 계좌 개설',
			disclaimer:
				'모든 권리 보유. Compass는 Compass Crypto Reference Index Bitcoin("인덱스")에 대한 모든 소유권을 보유합니다. 인덱스를 기반으로 하거나 인덱스와 관련된 모든 제3자 제품("제품")은 Compass의 사전 서면 승인과 제품 출시를 의도하는 당사자 간의 라이센스 계약 실행 시에만 발행될 수 있습니다. Compass는 어떠한 경우에도 제품의 발행 및 제공에 대해 후원, 보증 또는 달리 관여하지 않으며 제품 소유자 또는 대중에게 투자의 타당성과 관련하여 명시적 또는 묵시적 진술이나 보증을 하지 않습니다. 일반적으로 또는 미래에 특히 제품 또는 상품, 또는 인덱스 또는 제품의 사용으로 얻은 결과. Compass는 지수의 기반이 되는 데이터의 부정확성, 지수 계산 및/또는 보급의 실수, 오류, 누락 또는 중단, 또는 지수가 적용되는 방식에 대해 어떠한 당사자에게도 책임을 지지 않습니다. 제품의 발행 및 제공과의 연결. 어떠한 경우에도 Compass는 이익 손실이나 간접적, 징벌적, 특수적 또는 결과적 손해나 손실에 대해 그 가능성에 대해 통지를 받은 경우에도 책임을 지지 않습니다. 이것은 증권이나 투자를 매매하겠다는 제안이나 권유가 아닙니다. Compass Crypto Reference Index의 과거 실적이 반드시 미래 결과를 나타내는 것은 아닙니다.',
		},
		header: {
			cryptocurrencies: {
				title: '암호화폐',
				d1: '비트코인 무기한 선물',
				d2: '비트코인 월간 선물',
			},
			preciousMetals: {
				title: '귀금속',
				d1: '골드 선물',
				d2: '실버 선물',
			},
			forex: {
				title: '외환',
				d1: 'USD/CNH 선물',
			},
			commodities: {
				title: '상품',
				d1: '팜 올레인 선물',
				d2: '원유 팜유 선물',
				d3: '연료유 선물',
				d4: '저유황 연료유 선물',
			},
			btn: '알파와 거래하기',
		},
		pages: {
			topTitle: '교환',
			cryptocurrencies: {
				bitcoinPerpetualFutures: {
					title: '비트코인 무기한 선물',
					d1: '암호화폐는 투자자들 사이에서 많은 관심을 끌었으며 더 넓은 범위의 채택 조짐을 보이고 있습니다. 최초의 암호화폐인 비트코인은 계속해서 증가하는 암호화폐에도 불구하고 가장 인기 있고 가치 있는 디지털 자산 중 하나로 남아 있습니다.',
					d2: 'APEX 비트코인 무기한 선물 계약을 통해 암호화폐의 위험을 관리하고 성장하는 과정에서 가치 있는 투자 성과를 얻을 수 있습니다. 기관 및 고객을 위한 거래 포트폴리오에 다양화를 제공하며, 선물 계약이기 때문에 귀하의 포트폴리오가 암호 화폐의 가격 변동성을 견딜 수 있도록 도와주는 위험 최소화하는 역할을 할 수 있습니다.',
					d3: '참고: 이 상품은 기초 자산이 다른 자산에 비해 가격 변동성이 훨씬 높기 때문에 소량 자산규모의 투자자에게 적합하지 않습니다. 따라서 APEX 회원사에 연락하여 이 상품을 거래할 수 있는 귀하의 적합성을 평가하십시오.',
					tableTh: 'APEX 비트코인 무기한 선물 계약 사양',
					column_1_01: '계약 코드',
					column_1_02: '계약규모',
					column_1_03: '호가 통화',
					column_1_04: '최소 가격변동',
					column_1_05: '틱 값',
					column_1_06: '계약 시리즈',
					column_1_07: '거래시간',
					column_1_08: '마지막 거래일',
					column_1_09: '일일 가격제한',
					column_1_10: '일일 정산가',
					column_1_11: '펀딩 수수료',
					column_1_12: '펀딩 간격',
					column_1_13: '최종 정산가',
					column_1_14: '정산방식',
					column_1_15: '대량매매',
					column_1_16: '초기 증거금',
					column_1_17: '최대 주문량',

					column_2_01: 'BTCP',
					column_2_02: '50밀리비트코인/랏(0.05 BTC = 50밀리비트코인)',
					column_2_03: 'USD',
					column_2_04: 'US $0.005/밀리비트코인',
					column_2_05: 'US $0.25',
					column_2_06: '영구계약',
					column_2_07: '싱가포르 시간 거래 세션:',
					column_2_08: '싱가포르 공휴일을 포함한 월요일 – 금요일',
					column_2_09: 'T 세션:',
					column_2_10: '06:55시 – 06:59시(사전 오픈 세션)',
					column_2_11: '06:59시 ~ 07:00시(경기 시작)',
					column_2_12: '07:00시 – 18:00시(주간 세션)',
					column_2_13: 'T+1 세션:',
					column_2_14: '19:25시 – 19:29시(사전 오픈 세션)',
					column_2_15: '19:29시 ~ 19:30시(경기 시작)',
					column_2_16: '19:30시 – 05:30시(야간 세션)',
					column_2_17: 'T는 주어진 거래일을 의미합니다.',
					column_2_18: 'Day Session의 끝은 T의 끝입니다.',
					column_2_19: '만기가 없어 해당사항이 없습니다.',
					column_2_20: '거래소에서 별도로 규정하지 않는 한 일별 가격 제한은 없습니다.',
					column_2_21:
						'특정 날짜의 일일 정산 가격은 18:00시(GMT+8)에 Compass Financial Technologies SA("나침반")에서 발표한 CCRIBTC(Compass Crypto Reference Index Bitcoin) 가격과 동일합니다. 일일 정산 가격은 가장 가까운 US $0.005/milliBitcoin으로 반올림됩니다. ',
					column_2_22:
						'펀딩은 포지션 보유자가 펀딩 요율을 사용하여 계산된 펀딩 수수료를 받거나 지불하는 곳에서 발생합니다. 이것은 각 T 세션 및 T+1 세션이 끝날 때 모든 오픈 포지션에 적용됩니다. 펀딩 수수료는 각 거래일이 끝나는 마감일 청산 주기 동안 계산 및 징수됩니다.',
					column_2_23:
						'공식은 다음과 같습니다. 펀딩 수수료 = 오픈 포지션 계약 수 x 계약 크기 x Compass Crypto Real Time Index Bitcoin(CCRTBTC)(단위: milliBitcoin) x 펀딩 비율 펀딩 비율과 방법론은 APEX 웹사이트에 게시됩니다. ',
					column_2_24: 'T 세션: 18:00시(GMT+8)',
					column_2_25: 'T+1 세션: 05:30시(GMT+8)',
					column_2_26: '만기가 없어 해당사항이 없습니다.',
					column_2_27: '일일 정산가 대비 USD 현금 정산',
					column_2_28: '주문당 최소 20계약',
					column_2_29: 'SPAN 기준',
					column_2_30: '주문당 최대 500계약',
				},
				bitcoinMonthlyFutures: {
					title: ' 비트코인 월간 선물',
					d1: '암호화폐는 투자자들 사이에서 많은 관심을 끌었으며 더 넓은 범위의 채택 조짐을 보이고 있습니다. 최초의 암호화폐인 비트코인은 계속해서 증가하는 암호화폐 임에도 불구하고 가장 인기 있고 가치 있는 디지털 자산 중 하나로 남아 있습니다.',
					d2: 'APEX 비트코인 월간 선물 계약을 통해 암호화폐의 위험을 관리하고 성장하는 과정에서 가치 있는 투자 성과를 얻을 수 있습니다. 기관 및 고객을 위한 거래 포트폴리오에 다양화를 제공하며, 선물 계약이기 때문에 귀하의 포트폴리오가 암호 화폐의 가격 변동성을 견딜 수 있도록 도와주는 위험을 최소화하는 역할을 할 수 있습니다.',
					d3: '참고: 이 상품은 기초 자산이 다른 자산에 비해 가격 변동성이 훨씬 높기 때문에 소량 자산규모의 투자자에게 적합하지 않습니다. 따라서 APEX 회원사에 연락하여 이 상품을 거래할 수 있는 귀하의 적합성을 평가하십시오.',
					tableTh: 'APEX 비트코인 월간 선물 계약 사양',
					column_1_01: '계약 코드',
					column_1_02: '계약규모',
					column_1_03: '호가 통화',
					column_1_04: '최소 가격변동',
					column_1_05: '틱 값',
					column_1_06: '계약 시리즈',
					column_1_07: '거래시간',
					column_1_08: '마지막 거래일',
					column_1_09: '일일 가격제한',
					column_1_10: '일일 정산가',
					column_1_11: '최종 정산가',
					column_1_12: '정산방법',
					column_1_13: '대량매매',
					column_1_14: '초기 증거금',
					column_1_15: '최대 주문량',

					column_2_01: '비트코인',
					column_2_02: '50밀리비트코인/랏(0.05 BTC = 50밀리비트코인)',
					column_2_03: 'USD',
					column_2_04: 'US $0.005/밀리비트코인',
					column_2_05: 'US $0.25',
					column_2_06: '최근 연속 6개월',
					column_2_07: '싱가포르 시간 거래 세션:',
					column_2_08: '싱가포르 공휴일을 포함한 월요일 – 금요일',
					column_2_09: 'T 세션:',
					column_2_10: '06:55시 – 06:59시(사전 오픈 세션)',
					column_2_11: '06:59시 ~ 07:00시(경기 시작)',
					column_2_12: '07:00시 – 18:00시(주간 세션)',
					column_2_13: 'T+1 세션:',
					column_2_14: '19:25시 – 19:29시(사전 오픈 세션)',
					column_2_15: '19:29시 ~ 19:30시(경기 시작)',
					column_2_16: '19:30시 – 05:30시(야간 세션)',
					column_2_17: 'T는 주어진 거래일을 나타냅니다. Day Session의 끝은 T의 끝입니다.',
					column_2_18: '계약의 최종 거래일은 계약월의 마지막 금요일입니다',
					column_2_19: '거래소에서 별도로 규정하지 않는 한 일별 가격 제한은 없습니다.',
					column_2_20:
						'특정일의 일별 정산가는 해당일 거래의 마지막 순간에 발생한 모든 거래의 거래가격에 대한 거래량 가중 평균 또는 청산소에서 정한 기타 방법론입니다. 일일 정산 가격은 가장 가까운 US $0.005/milliBitcoin으로 반올림됩니다. ',
					column_2_21:
						'최종 결제 가격은 최종 거래일 18:00시(GMT+8)에 Compass Financial Technologies SA("나침반")에서 발표한 CCRIBTC(Compass Crypto Reference Index Bitcoin) 가격과 동일합니다. 최종 결제 가격은 가장 가까운 US $0.005/milliBitcoin으로 반올림됩니다. ',
					column_2_22: 'USD 현금결제',
					column_2_23: '주문당 최소 20계약',
					column_2_24: 'SPAN 기준',
					column_2_25: '주문당 최대 500계약',
				},
			},
			preciousMetals: {
				goldFutures: {
					title: '골드 선물',
					d1: '오랫동안 안전한 자산으로 여겨져 온 금은 일반적으로 투자 헤징 도구로 사용됩니다. 한정된 공급량과 고유의 특성을 지닌 골드는 훌륭한 가치를 가지고있습니다. APEX 골드 영구 선물 계약은 귀금속에 대한 거래, 헤지 및 위험을 최소화하는 역할을 합니다.',
					tableTh: 'APEX 금 영구 선물 계약 사양(V2021.08.06)',
					column_1_01: '계약 코드',
					column_1_02: '계약규모',
					column_1_03: '호가 통화',
					column_1_04: '최소 가격변동',
					column_1_05: '틱 값',
					column_1_06: '계약 시리즈',
					column_1_07: '거래시간',
					column_1_08: '마지막 거래일',
					column_1_09: '일일 가격제한',
					column_1_10: '일일 정산가',
					column_1_11: '최종 정산가',
					column_1_12: '정산방법',
					column_1_13: '롤오버 수수료',
					column_1_14: '대량매매',
					column_1_15: '초기 증거금',
					column_1_16: '최대 주문량',

					column_2_01: 'AUP',
					column_2_02: '100g/로트',
					column_2_03: 'USD',
					column_2_04: 'US $0.01 / g',
					column_2_05: 'US $1',
					column_2_06: '영구계약',
					column_2_07: '런던 금시장협회(LBMA) AM 금 시세 공시일',
					column_2_08: 'T 세션:',
					column_2_09: '06:55시 ~ 06:59시(사전개강)',
					column_2_10: '06:59hr – 07:00hr (오프닝 매치 세션)',
					column_2_11: '07:00시 – 18:00시(주간 세션)',
					column_2_12: 'T+1 세션:',
					column_2_13: '20:25시 – 20:29시(사전 오픈 세션)',
					column_2_14: '20:29시 – 20:30시(오프닝 매치 세션)',
					column_2_15: '20:30시 – 05:00시(야간 세션)',
					column_2_16: 'T는 주어진 거래일을 나타냅니다.',
					column_2_17: 'Day Session의 끝은 T의 끝입니다.',
					column_2_18:
						'LBMA Gold AM이 아닌 공시일 또는 LBMA Gold AM이 아닌 공시일의 이전 거래일에는 T+1 세션이 없습니다.',
					column_2_19: '만기가 없으므로 해당되지 않습니다.',
					column_2_20: '전 거래일 일일 정산가의 +/-10%',
					column_2_21:
						'특정 날짜의 일일 결제 가격은 IBA가 게시한 LBMA 금 가격 AM과 같아야 하며 반올림하여 US $0.01/그램으로 반올림합니다. ',
					column_2_22: '만기가 없으므로 해당되지 않습니다.',
					column_2_23: '일일 정산가 대비 USD로 현금 정산',
					column_2_24:
						'롤오버 수수료는 T 세션 종료 시 모든 오픈 포지션에 적용됩니다. 롤오버 수수료는 각 거래일이 끝나는 마감일 청산 주기 동안 계산 및 징수됩니다.',
					column_2_25: '수식은 다음과 같습니다.',
					column_2_26: '오픈 포지션 계약 수 x 계약 규모 x 일일 정산 가격 x (롤오버 비율) ÷ 365',
					column_2_27:
						'롤오버 수수료를 계산하는 데 사용되는 롤오버 요금은 APEX 웹사이트에 게시됩니다.',
					column_2_28: '주문당 최소 30계약',
					column_2_29: 'SPAN 기준',
					column_2_30: '주문당 최대 500계약',
				},
				silverFutures: {
					title: '실버 선물',
					d1: '금에 비해 실버는 특히 사진 및 의료 분야에서 산업 수요가 더 높습니다. 골드와 높은 상관관계를 가지고 있는 실버는 종종 안전한 자산이자 유용한 헤징 도구로 간주됩니다. APEX의 무기한 선물 계약은 귀금속에 대한 거래, 헤징 및 위험을 최소화하는 역할을 합니다.',
					tableTh: 'APEX 은 무기한 선물 계약 사양',
					column_1_01: '계약 코드',
					column_1_02: '계약규모',
					column_1_03: '호가 통화',
					column_1_04: '최소 가격변동',
					column_1_05: '틱 값',
					column_1_06: '계약 시리즈',
					column_1_07: '거래시간',
					column_1_08: '마지막 거래일',
					column_1_09: '일일 가격제한',
					column_1_10: '일일 정산가',
					column_1_11: '최종 정산가',
					column_1_12: '정산방법',
					column_1_13: '롤오버 수수료',
					column_1_14: '대량매매',
					column_1_15: '초기 증거금',
					column_1_16: '최대 주문량',

					column_2_01: 'AGP',
					column_2_02: '5kg/로트',
					column_2_03: 'USD',
					column_2_04: 'US $0.2/kg',
					column_2_05: 'US $1',
					column_2_06: '영구계약',
					column_2_07: '런던 금시장협회(LBMA) 은가격 공시일',
					column_2_08: 'T 세션:',
					column_2_09: '06:55시 ~ 06:59시(사전개강)',
					column_2_10: '06:59hr – 07:00hr (오프닝 매치 세션)',
					column_2_11: '07:00시 – 18:00시(주간 세션)',
					column_2_12: 'T+1 세션:',
					column_2_13: '20:25시 – 20:29시(사전 오픈 세션)',
					column_2_14: '20:29시 – 20:30시(오프닝 매치 세션)',
					column_2_15: '20:30시 – 05:00시(야간 세션)',
					column_2_16: 'T는 주어진 거래일을 나타냅니다.',
					column_2_17: 'Day Session의 끝은 T의 끝입니다.',
					column_2_18:
						'LBMA 실버 발행일이 아닌 날 또는 비LBMA 실버 발행일의 이전 거래일에는 T+1 세션이 없습니다.',
					column_2_19: '만기가 없으므로 해당되지 않습니다.',
					column_2_20: '전 거래일 일일 정산가의 +/-15%',
					column_2_21:
						'특정 날짜의 일일 정산 가격은 IBA가 공시한 LBMA 은 가격과 같아야 하며 반올림한 US $0.2/kg',
					column_2_22: '만기가 없으므로 해당되지 않습니다.',
					column_2_23: '일일 정산가 대비 USD로 현금 정산',
					column_2_24:
						'롤오버 수수료는 T 세션 종료 시 모든 오픈 포지션에 적용됩니다. 롤오버 수수료는 각 거래일이 끝나는 마감일 청산 주기 동안 계산 및 징수됩니다.',
					column_2_25: '수식은 다음과 같습니다.',
					column_2_26: '오픈 포지션 계약 수 x 계약 규모 x 일일 정산 가격 x (롤오버 비율) ÷ 365',
					column_2_27:
						'롤오버 수수료를 계산하는 데 사용되는 롤오버 요금은 APEX 웹사이트에 게시됩니다.',
					column_2_28: '주문당 최소 50계약',
					column_2_29: 'SPAN 기준',
					column_2_30: '주문당 최대 500계약',
				},
			},
			forex: {
				USDCNHFutures: {
					title: ' USD/CNH 선물',
					d1: '위안화(RMB)의 국제화가 가속화되면서 위안화의 국제적 위상이 높아지고 있습니다. 위안화는 세계 무역 및 투자에 널리 사용되며 점점 더 중요한 국제통화 중 하나가 되고 있습니다. 위안화 환율 체제의 개혁 속도는 더 큰 국제 무역 흐름과 동시에 추진력을 얻고 있으며, 그 결과 환율의 양방향 변동성과 변동성이 증가하는 추세입니다.',
					tableTh: 'APEX USD/CNH 선물 계약 사양',
					column_1_01: '계약 코드',
					column_1_02: '티커 기호',
					column_1_03: '계약규모',
					column_1_04: '가격 견적',
					column_1_05: '최소 가격변동',
					column_1_06: '눈금 값',
					column_1_07: '계약 시리즈',
					column_1_08: '거래시간',
					column_1_09: '마지막 거래일',
					column_1_10: '일일 가격제한',
					column_1_11: '일일 정산가',
					column_1_12: '최종 정산가',
					column_1_13: '정산방법',
					column_1_14: '대량매매',
					column_1_15: '초기 증거금',
					column_1_16: '최대 주문량',

					column_2_01: 'UC',
					column_2_02:
						'계약은 UCyymmW#로 표시되며 여기서 "yy", "mm" 및 "W#"는 계약이 만료되는 연도, 월 및 해당 월의 서수 주를 나타냅니다. 상징적인 목적을 위해 한 달의 첫째 주는 수요일이 있는 그 달의 첫째 주입니다.',
					column_2_03: '예: UC1901W3는 2019년 1월 셋째 주에 만료되는 Contract를 나타냅니다.',
					column_2_04: '미화 10,000달러',
					column_2_05: 'USD당 CNH',
					column_2_06: 'CNH 0.0001(소수점 4자리)',
					column_2_07: 'CNH 1',
					column_2_08:
						'거래소는 다음 4주 연속으로 만료되는 4개의 계약을 나열하고, 다음 3개월 연속 세 번째 주에 만료되는 또 다른 3개의 계약을 나열하고, 다음 3개의 계약을 나열해야 합니다. 다음 3개 분기별 월(3월, 6월, 9월, 12월)의 세 번째 주에 만료됩니다.',
					column_2_09:
						'계약이 만료되면 거래소는 다음 거래일 시작까지 새로운 계약을 상장해야 합니다.',
					column_2_10: '싱가포르 시간 거래 세션: 싱가포르 영업일',
					column_2_11: '월 – 금, 싱가폴 공휴일 제외 오전 8시 55분 – 오전 8시 59분 (사전 개강)',
					column_2_12: '08:59am – 09:00am (Day Session의 개막전)',
					column_2_13:
						'09:00am – 18:00pm (Day Session) Contract의 거래는 최종 거래일 오전 11시에 종료됩니다',
					column_2_14:
						'계약의 최종 거래일은 홍콩 영업일과 싱가포르 영업일인 경우 해당 계약 주간의 월요일이며, 그렇지 않으면 홍콩 영업일과 싱가포르 영업일인 경우 가장 빠른 다음 날입니다. 낮. 위의 내용에도 불구하고 거래소는 통지를 통해 최종 거래일을 결정할 권리를 보유합니다.',
					column_2_15: '거래소에서 별도로 규정하지 않는 한 일일 가격 제한은 없습니다.',
					column_2_16:
						'특정일의 일별 정산가는 해당일 세션의 마지막 5분 동안 발생한 모든 거래의 거래 가격의 거래량 가중 평균 또는 청산소에서 정한 기타 방법론입니다.',
					column_2_17:
						'최종 결제 가격은 최종 거래일 홍콩 시간 오전 11시 30분경 홍콩 재무부 시장 협회(TMA)에서 발표한 USD/CNY(HK) 현물 환율 또는 설정된 다른 방법과 동일해야 합니다. 청산소 옆에 있습니다.',
					column_2_18: 'CNH로 현금결제',
					column_2_19: '주문당 최소 200계약',
					column_2_20: 'SPAN 기준',
					column_2_21: '주문당 최소 500계약',
				},
			},
			commodities: {
				palmOleinFutures: {
					title: 'Palm Olein Futures',
					d1: 'APEX는 첫 번째 제품인 정제된 팜 올레인 선물 계약을 출시하는 것을 목표로 하고 있습니다. 팜 올레인 선물의 주요 이점은 다음과 같습니다. 말라카 해협을 따라 말레이시아와 인도네시아에 위치한 인도 항구를 통한 FOB 실물 인도. 전 세계 팜유 생산의 85%와 국제 팜 거래의 91%가 말레이시아와 인도네시아에서 이루어집니다.',
					d2: 'USD를 가격 책정 통화로 사용하고 RMB 및 기타 통화를 수용 가능한 증거금 담보로 허용함으로써 APEX의 팜 올레인 선물 계약은 국제 무역 관행에 부합하고 통화 가격 변동의 위험을 방지합니다.',
					d3: 'APEX의 팜 올레인 선물 계약 출시가 임박함에 따라 글로벌 팜유 거래 산업에 대한 대체 장소를 제공하는 것을 목표로 하고 헤징 및 차익 거래를 위한 새로운 수단 역할을 합니다.',
					tableTh: 'APEX RBD Palm Olein 선물 계약 사양(V2019.04.03)',
					column_1_01: '계약 코드',
					column_1_02: '계약규모',
					column_1_03: '호가 통화',
					column_1_04: '최소 가격변동',
					column_1_05: '거래시간',
					column_1_06: '일일 가격제한',
					column_1_07: '계약 시리즈',
					column_1_08: '마지막 거래일',
					column_1_09: '배송방법',
					column_1_10: '최소 결과물 크기',
					column_1_11: '초기 증거금',
					column_1_12: '마지막 배송일',
					column_1_13: '최대 주문량',

					column_2_01: 'PF',
					column_2_02: '10MT / 계약',
					column_2_03: 'USD',
					column_2_04: 'US$0.25 / MT',
					column_2_05: '싱가포르 시간 거래 세션: 싱가포르 영업일',
					column_2_06: '월 – 금, 싱가폴 공휴일 제외',
					column_2_07: ' ',
					column_2_08: '08:55am – 08:59am (사전 개강)',
					column_2_09: '08:59am – 09:00am (개막 매치 세션)',
					column_2_10: '09:00am – 12:30pm (오전/1차)',
					column_2_11: '13:30pm – 18:00pm (오후 세션/2차 세션)',
					column_2_12: '계약의 거래는 최종 거래일 오후 18시에 종료됩니다',
					column_2_13:
						'최종 정산가의 +/-10%. 계약의 최종 거래일에는 일일 가격 제한이 적용되지 않습니다.',
					column_2_14: '월간 계약(12개월 연속 계약)',
					column_2_15: '영업일인 경우 선행 계약월의 15번째 역일, 그렇지 않은 경우 이전 영업일',
					column_2_16:
						'Pasir Gudang(말레이시아), Port Klang(말레이시아), Lahad Datu(말레이시아), Belawan(인도네시아), Dumai(인도네시아) 또는 기타 APEX 승인 항구에서 물리적 배송 FOB',
					column_2_17: '50 계약(즉, 총 500MT)',
					column_2_18: 'SPAN 기준 (마진 일정을 보려면 클릭)',
					column_2_19: '마지막 배송월',
					column_2_20: '주문당 최대 500계약',
				},
				crudePalmOilFutures: {
					title: '원유 팜유 선물',
					d1: ' APEX 원유 팜유 선물 계약은 팜유 시리즈의 두 번째 제품이며 첫 번째 제품인 APEX RBD 팜 올레인 선물 계약과 유사한 기능을 보유하고 있습니다. APEX Crude Palm Oil Futures는 APEX RBD Palm Olein Futures와 병행하여 운영되며 팜유 시장에서 이용 가능한 차익 거래 기회를 증가시킬 것입니다.',
					tableTh: 'APEX 원유 팜유 선물 계약 사양',
					column_1_01: '계약 코드',
					column_1_02: '계약규모',
					column_1_03: '호가 통화',
					column_1_04: '최소 가격변동',
					column_1_05: '거래시간',
					column_1_06: '일일 가격제한',
					column_1_07: '계약 시리즈',
					column_1_08: '마지막 거래일',
					column_1_09: '배송방법',
					column_1_10: '최소 결과물 크기',
					column_1_11: '초기 증거금',
					column_1_12: '마지막 배송일',
					column_1_13: '최대 주문량',

					column_2_01: 'CPF',
					column_2_02: '10MT / 계약',
					column_2_03: 'USD',
					column_2_04: 'US$0.25 / MT',
					column_2_05: '싱가포르 시간 거래 세션: 싱가포르 영업일',
					column_2_06: '월 – 금, 싱가폴 공휴일 제외 오전 8시 55분 – 오전 08시 59분 (사전 개강)',
					column_2_07: '08:59am – 09:00am (개막 매치 세션)',
					column_2_08: '09:00am – 12:30pm (오전 세션)',
					column_2_09: '13:30pm – 18:00pm (오후 세션)',
					column_2_10: ' ',
					column_2_11: '계약의 거래는 최종 거래일 오후 18시에 종료됩니다',
					column_2_12:
						'최종 정산가의 +/-10%. 계약의 최종 거래일에는 일일 가격 제한이 적용되지 않습니다.',
					column_2_13: '월간 계약(12개월 연속 계약)',
					column_2_14: '최초 계약월의 15일(영업일인 경우 직전 영업일)',
					column_2_15: 'APEX 웹사이트의 ANNEX A에 나열된 모든 APEX 승인 항구의 물리적 배송 FOB',
					column_2_16: '50 계약(즉, 총 500MT)',
					column_2_17: 'SPAN 기준',
					column_2_18: '인도월의 마지막 달력일',
					column_2_19: '주문당 500계약',
				},
				fuelOilFutures: {
					title: '연료유 선물',
					d1: '계약이 현재 휴면 상태이며 거래가 불가능합니다. 자세한 내용은 APEX 또는 해당 브로커에게 직접 문의하십시오.',
					d2: '싱가포르는 현재 세계에서 가장 큰 벙커링 항구로 연간 약 5천만 톤의 해상 연료유를 공급합니다. 해양 산업은 싱가포르 경제의 큰 부분을 차지하고 있으며 싱가포르 국내 총생산(GDP)의 약 7%를 차지합니다.',
					d3: '연료 비용은 해운 회사의 가장 큰 비용 항목으로 남아 있기 때문에 선박 연료 유가의 엄청난 가격 변동은 많은 회사의 운영 비용에 부정적인 영향을 미칠 수 있으며 잠재적으로 싱가포르 경제에 파급 효과를 일으킬 수 있습니다.',
					d4: '현재 상황에 비추어 APEX는 380cst 연료유 선물 계약을 시작하여 에너지 산업 및 금융 기관이 연료유 시장에서 헤지 및 투자할 수 있는 플랫폼을 만들었습니다.',
					tableTh: 'APEX 380cst 연료유 선물 계약 사양',
					column_1_01: '계약 코드',
					column_1_02: '계약규모',
					column_1_03: '호가 통화',
					column_1_04: '최소 가격변동',
					column_1_05: '거래시간',
					column_1_06: '일일 가격제한',
					column_1_07: '계약 시리즈',
					column_1_08: '마지막 거래일',
					column_1_09: '배송방법',
					column_1_10: '최소 결과물 크기',
					column_1_11: '초기 증거금',
					column_1_12: '최대 주문량',

					column_2_01: 'FO',
					column_2_02: '10MT / 계약',
					column_2_03: 'USD',
					column_2_04: 'US$0.10 / MT',
					column_2_05: '싱가포르 시간 거래 세션: 싱가포르 영업일',
					column_2_06: '월 – 금, 싱가폴 공휴일 제외 오전 8시 55분 – 오전 08시 59분 (사전 개강)',
					column_2_07: '08:59am – 09:00am (개막 매치 세션)',
					column_2_08: '09:00am – 12:30pm (오전/1차)',
					column_2_09:
						'13:30pm – 18:00pm (오후 세션/2차 세션) Contract의 거래는 최종 거래일의 18:00pm에 종료됩니다.',
					column_2_10:
						'/최종 정산가의 -10%. 계약의 최종 거래일에는 일일 가격 제한이 적용되지 않습니다.',
					column_2_11: '월간 계약(12개월 연속 계약)',
					column_2_12: '최초 계약월의 마지막 영업일, 그렇지 않으면 직전 영업일',
					column_2_13: 'FOB 또는 탱크 간 이동을 통해 인식된 창고에서 물리적 배송',
					column_2_14: '100 계약(즉, 총 1,000MT)',
					column_2_15: 'SPAN 기준',
					column_2_16: '주문당 500계약',
				},
				lowSulphurFuelOilFutures: {
					title: '저유황 연료유 선물',
					d1: '계약이 현재 휴면 상태이며 거래가 불가능합니다. 자세한 내용은 APEX 또는 해당 브로커에게 직접 문의하십시오.',
					d2: '아시아 태평양 거래소(APEX)는 2019년 11월 21일 오후 9시에 저황 연료유 선물 계약(LFO 계약)을 시작했습니다. LFO 계약은 저유황 연료유의 헤징 및 FOB 실물 인도를 용이하게 하는 전 세계 최초의 인도 가능한 상품입니다. .',
					tableTh: '저유황 연료유 선물 계약 명세서',
					column_1_01: '계약 코드',
					column_1_02: '계약규모',
					column_1_03: '호가 통화',
					column_1_04: '최소 가격변동',
					column_1_05: '거래시간',
					column_1_06: '일일 가격제한',
					column_1_07: '계약 시리즈',
					column_1_08: '마지막 거래일',
					column_1_09: '마지막 배송일',
					column_1_10: '배송방법',
					column_1_11: '최소 결과물 크기',
					column_1_12: '초기 증거금',
					column_1_13: '최대 주문량',

					column_2_01: 'LFO',
					column_2_02: '10MT /계약',
					column_2_03: 'USD',
					column_2_04: 'US $0.10 / MT',
					column_2_05: '싱가포르 시간 거래 세션: 싱가포르 영업일',
					column_2_06: '월 – 금, 싱가폴 공휴일 제외 오전 8시 55분 – 오전 08시 59분 (사전 개강)',
					column_2_07: '08:59am – 09:00am (개막 매치 세션)',
					column_2_08: '09:00am – 12:30pm (오전/1차)',
					column_2_09:
						'13:30pm – 18:00pm (오후 세션/2차 세션) Contract의 거래는 최종 거래일의 18:00pm에 종료됩니다.',
					column_2_10: '최종 정산가의 +/-10%',
					column_2_11: '마지막 거래일에는 계약에 대해 일일 가격 제한이 적용되지 않습니다.',
					column_2_12: '월간 계약(12개월 연속 계약)',
					column_2_13: '최초 계약월의 마지막 영업일',
					column_2_14: '계약월의 마지막 날',
					column_2_15: 'FOB 또는 탱크 간 이동을 통해 인식된 창고에서 물리적 배송',
					column_2_16: '100 계약(즉, 총 1,000MT)',
					column_2_17: 'SPAN 기준',
					column_2_18: '주문당 500계약',
				},
			},
			privacyPolicy: {
				title: '개인정보처리방침',
				d1: '요약: 우리는 Alpha 펀드 매니지먼트 입니다. Ltd.("알파", "당사" 또는 "당사를"). 우리는 귀하의 개인정보를 존중하며 귀하가 제공한 정보의 기밀성을 보호하기 위해 최선을 다합니다. 당사는 귀하가 요청한 서비스 및 데이터 제공을 위해 필요한 경우에만 귀하의 개인 데이터를 제3자와 공유합니다(자세한 내용은 이 개인 정보 보호 정책에 설명되어 있음).',
				d2: '회사 소개',
				d3: 'Alpha는 귀하의 개인 데이터를 책임지는 데이터 컨트롤러이며 Alpha 모바일 및 데스크톱 애플리케이션, Alpha 서비스(각각 "서비스") 및 여기에서 수집된 모든 정보의 유일한 소유자입니다. Alpha는 여러 가지 방법으로 사용자로부터 정보를 수집합니다(이 개인 정보 보호 정책에 자세히 설명되어 있음).',
				d4: '이 개인 정보 보호 정책은 귀하가 계정을 생성하고 당사의 애플리케이션 또는 서비스를 사용할 때 귀하로부터 수집하는 개인 데이터에 적용됩니다("귀하" 또는 "귀하의"는 그에 따라 해석됨). 수집하는 개인 데이터, 개인 데이터를 수집하는 이유, 사용 방법, 개인 데이터 처리의 법적 근거, 저장 방법 및 정보 공유 방법에 대한 정보를 제공합니다.',
				d5: '이 개인 정보 보호 정책에서 사용되는 "개인 데이터"는 귀하를 식별할 수 있는 귀하와 관련된 모든 정보를 의미합니다.',
				d6: '우리 서비스를 사용하거나 개인 데이터를 제출함으로써 귀하는 이 개인 정보 보호 정책의 조건을 수락하는 데 동의하는 것이므로 주의 깊게 읽으십시오. 언제라도 이 개인 정보 보호 정책에 동의하지 않는 경우 서비스 사용을 중단하고 사용자 계정을 삭제해야 합니다.',
				d7: '당사가 수집하는 개인 데이터',
				d8: '알파 사용자의 아이디어 및 공개 데이터뿐만 아니라 기호, 시세, 재무 데이터와 같은 특정 시장의 정보를 검색하고 볼 때 계정을 만들 필요가 없습니다.',
				d9: '1) 사용자 계정',
				d10: '계정을 만들기로 결정한 경우 사용자가 혜택을 받는 추가 서비스에 대한 액세스를 제공할 수 있도록 일부 개인 데이터를 제공해야 합니다. 귀하는 아래에 설명된 대로 귀하에 대한 추가 정보를 제공하도록 선택할 수도 있습니다.',
				d11: '등록하는 동안 다음 중 하나를 제공해야 합니다.',
				d12: 'a) 귀하의 사용자 이름, 이메일 주소,',
				d13: 'b) 다음 소셜 인터넷 서비스 중 하나의 계정 정보: Facebook, Twitter, Google, Yahoo!, LinkedIn 그리고',
				d14: 'c) 구독을 구매하려면 이름, 성, 우편 주소가 필요합니다.',
				d15: 'd) 경우에 따라 전화번호를 제공하고 확인해야 할 수도 있습니다. 프로필 설정에서 자신에 대한 추가 정보를 추가할 수도 있지만 필수 사항은 아닙니다.',
				d16: '다음 정보가 공개적으로 표시됩니다:',
				d17: 'e) 사용자 아바타, 서명, "내 정보", 위치로 제공하기로 선택한 이미지.',
				d18: 'f) 다음 정보는 공개적으로 표시되지 않습니다:',
				d19: 'g) 이름과 성, 전화번호, 이메일, 회사, 주소 및 납세자 ID.',
				d20: '2) 연락처 데이터',
				d21: '우리는 또한 전화, 이메일 또는 기타 우리가 운영하는 다른 서비스를 방문하고 상호 작용하여 우리와 연락할 때 제공하기로 선택한 모든 개인 데이터를 처리합니다.',
				d22: '3) 로그 파일',
				d23: '웹 서버 로그 파일을 사용합니다. 로그 파일의 기록에는 인터넷 프로토콜(IP) 주소, 브라우저 유형, 인터넷 서비스 공급자, 참조 페이지, 종료 페이지, 플랫폼 유형 및 날짜/시간 스탬프가 포함됩니다. 우리는 웹 서버 로그 파일을 사용하여 사이트를 관리하고, 사이트 계획 목적을 위한 광범위한 트래픽 정보를 제공하고, 서비스 약관이 준수되고 있는지 확인합니다. IP 주소는 웹 서버 로그 파일의 개인 데이터와 연결되어 있지 않습니다.',
				d24: '4) 기기 정보',
				d25: '우리는 귀하가 당사 서비스와 상호 작용하기 위해 사용하는 장치에 대해 다음 정보를 수집합니다: 장치의 IP 주소(연결하는 국가도 유추할 수 있음), 운영 체제 및 브라우저 버전. 스팸 및 서비스 남용을 방지하기 위해 이 정보를 수집합니다. IP 주소는 특정 관할권에서 개인 데이터로 간주될 수 있으며 이 개인 정보 보호 정책에 따라 처리됩니다.',
				d26: '5) 분석',
				d27: '귀하가 당사 웹사이트, 모바일 및 데스크톱 애플리케이션 또는 당사가 다른 사이트에 제공하는 위젯을 사용할 때 자동으로 분석 정보를 수집할 수 있습니다. 이 정보에는 일반 기기 정보, 기기 식별자(예: Android 기기를 사용하는 경우 Google 광고 ID, iOS 기기를 사용하는 경우 광고 식별자(IDFA)), 네트워크 정보, 웹사이트 및 애플리케이션이 포함될 수 있습니다. 상호 작용 이벤트. 당사는 익명의 데이터만 수집하며 이 분석 정보에는 프로필의 개인 식별 정보가 포함되지 않습니다. 당사는 이 정보를 사용하여 서비스 및 애플리케이션의 작업을 분석 및 개선하고 서비스를 추가로 개발, 테스트 및 개선합니다. 이를 위해 당사는 내부 분석 및 제3자 서비스 제공업체를 사용할 수 있습니다. 당사의 제3자 서비스 제공자 사용에 대한 자세한 내용은 아래 분석 제공자 섹션을 참조하십시오.',
				d28: '6) 제3자로부터 받은 데이터',
				d29: '소셜 인터넷 서비스 자격 증명(Facebook, Twitter, Google, Yahoo!, LinkedIn)을 사용하여 계정에 로그인하면 해당 서비스에서 사용자 이름과 사용한 자격 증명과 연결된 이메일 주소를 보냅니다. 당사는 제3자가 귀하에 대한 정보를 제공하고 귀하의 개인 데이터를 처리하는 방식을 통제하거나 책임지지 않습니다.',
				d30: '개인정보의 이용방법',
				d31: '저희는 귀하가 사용하려는 서비스, 특정한 정당한 이익을 제공하는 데 필요한 다양한 방식으로 개인 데이터를 사용하며, 아래에 자세히 설명된 바와 같이 해당 법률을 준수하기 위해 필요합니다.',
				d32: '우리는 일반적으로 다른 법적 근거가 있는 경우 귀하의 개인 데이터 처리를 허용하기 위해 귀하의 동의에 의존하지 않습니다. 우리가 귀하의 동의에 의존한다면, 그 당시에 귀하에게 이를 분명히 할 것입니다.',
				d33: '1) 프로필',
				d34: '프로필은 시청 선호도를 자세히 설명하는 개별 사용자에 대한 일련의 정보입니다. 사용자 설정(차트 설정, 도구 모음, 버튼 등)은 웹 사이트, 애플리케이션 또는 사용자 계정을 사용할 때 사용자 경험을 사용자 지정하기 위한 목적으로만 유지됩니다. 우리는 개인 프로필 설정을 제3자와 공유하지 않습니다.',
				d35: '귀하가 계정을 만들면 다른 사용자나 방문자가 귀하의 공개 프로필에 액세스하고 귀하가 자신에 대해 공개적으로 제공한 모든 정보를 볼 수 있습니다.',
				d36: '2) 사용자 계정 및 프로필 작업',
				d37: '사용자 이름, 이메일 주소 또는 전화번호와 같은 연락처 정보를 다음과 같은 방식으로 사용합니다:',
				d38: 'a) 귀하의 사용자 계정을 인증하고 당사 서비스의 보안과 함께 보안을 보장하며 스팸, 사기 및 남용을 방지하기 위해;',
				d39: 'b) 귀하의 사용자 계정을 승인, 확인 및 처리하기 위해;',
				d40: 'c) 이메일 주소를 확인하기 위해 모든 신규 사용자에게 환영 활성화 이메일을 보내기 위해;',
				d41: 'd) (유료 사용자의 경우) 구독 서비스를 제공하고 해당 서비스 사용에 관해 연락하기 위해. 이는 귀하의 요청을 이행하는 데 필요합니다. 그리고',
				d42: 'e) 유료 사용자는 또한 자동 갱신 구독에 대한 카드 청구와 관련하여 문제가 있는 경우 PayPal로부터 알림 이메일을 받게 됩니다. 사용자는 자신의 계정을 삭제할 수는 있지만 이러한 메시지를 거부할 수는 없습니다.',
				d43: '3) 사용자 계정 삭제',
				d44: '귀하는 당사 웹사이트 및/또는 애플리케이션 사용을 거부할 수 있으며 프로필 설정에서 계정 삭제를 요청할 수 있습니다. 귀하의 계정은 30일 후에 삭제됩니다. 이 시간 동안 귀하는 계정 삭제를 요청한 경우 귀하가 당사에 제공한 후속 개인 데이터를 삭제한다는 점에 유의하십시오. 그러나 게시된 아이디어/스크립트 및 다른 사용자에게 보낸 메시지와 같은 일부 데이터는 이미 시스템에 통합되어 저장됩니다. 이 데이터는 감사 및 보안 목적을 포함하여 합법적인 비즈니스 프로세스뿐만 아니라 플랫폼의 무결성을 유지하기 위해 저장되어야 합니다.',
				d45: '계정이 삭제되면 검색 엔진과 제3자가 공개적으로 사용 가능한 정보의 사본을 보관할 수 있습니다. 게시된 아이디어는 사이트에 남아 있으며 다른 사용자에게 보낸 메시지는 삭제할 수 없습니다.',
				d46: '4) 마케팅',
				d47: '우리는 귀하가 관심을 가질 만한 이벤트 및 기타 프로모션에 대한 정보를 포함하여 귀하가 구독하는 제품 또는 서비스와 유사한 당사 제품 또는 서비스에 대한 마케팅 이메일을 보낼 수 있습니다. 이는 귀하가 이러한 방식으로 커뮤니케이션 수신을 원하지 않는다고 당사에 명시하지 않은 경우입니다. 당사는 귀하가 귀하의 개인 데이터를 당사에 제공한 시점에 제공된 귀하의 동의가 있는 경우에만 이메일로 기타 판촉 정보를 보내드립니다. 사용자는 이메일 중 하나에서 "구독 취소"를 클릭하여 언제든지 마케팅 커뮤니케이션 수신을 거부할 수 있습니다.',
				d48: '5) 소셜 알림',
				d49: '당신을 팔로우한 새로운 사용자, 아이디어에 좋아요를 누른 사용자, 당신이 팔로우하는 사용자의 새로운 아이디어 등에 대해 이메일을 통해 알려드릴 수 있습니다. 프로필 설정을 조정하여 언제든지 이러한 알림 수신을 거부할 수 있습니다.',
				d50: '6) 서비스 관련 공지',
				d51: '드문 경우지만 서비스 관련 공지를 보내야 할 수도 있습니다. 예를 들어 서비스가 장기간 중단되거나 주요 기능 업그레이드가 릴리스되면 모든 사용자에게 이메일 메시지를 보낼 수 있습니다.',
				d52: '7) 정당한 이익',
				d53: '우리는 다음을 포함하는 특정한 합법적인 비즈니스 이익을 위해 필요에 따라 귀하의 개인 데이터를 처리할 것입니다:',
				d54: 'a) 귀하의 질문, 의견 또는 고충에 응답하도록 요청받은 경우;',
				d55: 'b) 방문자가 웹사이트 및 애플리케이션과 상호 작용하는 방법을 더 잘 이해하고 귀하와 귀하의 컴퓨터/장치에 가장 효과적인 방식으로 제공되도록 하기 위해 당사 서비스를 관리하기 위해;',
				d56: 'c) 모바일 애플리케이션을 개발하고 개선하기 위해;',
				d57: 'd) 위에서 설명한 대로 귀하의 동의가 필요한 경우를 제외하고 관리 목적으로 계열사 간에 개인 데이터를 공유하고 구독 서비스를 제공하고 판매 및 마케팅 활동과 관련하여 ',
				d58: 'e) 귀하가 구독한 제품 또는 서비스와 유사한 당사 제품 또는 서비스에 대한 정보를 보내기 위해(귀하가 이메일 주소를 당사에 제공했을 때 이러한 이메일 수신을 거부하거나 거부하지 않았거나 귀하가 이러한 방식으로 통신을 받고 싶지 않다고 당사에 표시함). 당사의 정당한 이익은 당사의 마케팅이 귀하와 관련이 있는지 확인하는 것이므로 귀하의 관심사에 특별히 맞춘 당사 제품 또는 서비스에 대한 정보를 귀하에게 보내기 위해 귀하의 데이터를 처리할 수 있습니다.',
				d59: 'f) 당사는 수집한 데이터를 익명화, 가명화, 집계 및 비식별화할 수 있으며, 이를 위해 비즈니스 파트너, 계열사, 대리인 및 기타 제3자와 공유하는 것을 포함하여 자체 내부 비즈니스 목적으로 이 데이터를 사용할 수 있습니다. 상업, 통계 및 시장 조사 목적. 예를 들어, 해당 당사자가 사람들 그룹 간의 패턴을 분석하고 인구 통계, 관심 및 행동에 대한 연구를 수행할 수 있도록 허용합니다.',
				d60: 'g) 문제 해결, 데이터 분석, 테스트, 연구, 통계 및 설문 조사 목적을 포함한 내부 비즈니스/기술 운영을 위해, 그리고 당사 웹 사이트와 애플리케이션, 네트워크 및 정보 시스템을 안전하게 유지하기 위한 노력의 일환으로; 그리고',
				d61: 'h ) to (a) 법적 의무 준수, (b) 관할 당국의 요청에 응답; (c) 이용 약관 또는 하우스 규칙을 시행합니다. (d) 당사 또는 당사 계열사의 운영을 보호합니다. (e) 당사의 권리, 안전 또는 재산 및/또는 당사 계열사, 귀하 또는 타인의 권리, 안전 또는 재산을 보호합니다. (f) 법적 권리를 행사 또는 방어하거나 피해를 방지합니다. 즉, 법을 준수하기 위해 공개가 필요하다고 판단되는 경우 법 집행 기관, 주 또는 정부 기관에 귀하의 정보를 저장하고 공개할 수 있습니다. 여기에는 법원 명령이나 소환장에 대한 대응뿐만 아니라 기타 사법 또는 규제 절차도 포함됩니다.',
				d62: '이 개인 정보 보호 정책에서 사용되는 "합법적인 이익"은 사업을 수행하고 관리하는 Alpha 및 제휴 사업체의 이익을 의미합니다. 당사의 정당한 이익을 위해 귀하의 개인 데이터를 처리할 때 당사는 귀하와 데이터 보호법에 따른 귀하의 권리에 대한 잠재적 영향을 고려하고 균형을 유지합니다. 당사의 정당한 이익이 자동으로 귀하의 이익보다 우선하지 않습니다. 당사는 명시적인 동의가 없거나 그러한 활동이 법에서 달리 요구하거나 허용하지 않는 한 당사의 이익이 사용자에게 미칠 수 있는 영향보다 우선하는 활동에 개인 데이터를 사용하지 않습니다. 귀하는 귀하의 특정 상황과 관련된 이유로 당사의 정당한 이익에 근거한 귀하의 개인 데이터 처리를 언제든지 거부할 권리가 있습니다.',
				d63: '개인 정보를 언제 공유합니까?',
				d64: '당사는 다음과 같은 상황에서 귀하의 개인 데이터를 제3자와 공유합니다.',
				d65: 'a) 서비스 제공자: Alpha는 많은 기업과 마찬가지로 (i) 카드 처리 또는 지불 서비스, (ii) IT 공급업체 및 계약자(즉, (iii) 웹 및 모바일 분석 제공자, (iv) 디지털 제공자 광고 서비스 및 (v) 마케팅 및 판매 소프트웨어 솔루션 제공업체. 당사의 지침에 따라 이러한 당사자는 당사에 대한 의무를 수행하는 과정에서 그리고 당사가 제공하도록 고용한 서비스를 수행하기 위해서만 귀하의 개인 데이터에 액세스, 처리 또는 저장할 수 있습니다.',
				d66: 'b) 결제 정보: 유료 사용자는 결제 정보(이름, 결제 카드 정보 또는 결제 계정 정보)를 제공해야 합니다. 이 정보는 청구 목적으로만 사용되며 결제 프로세서에서 처리됩니다. 귀하가 결제할 때 당사는 귀하의 결제 카드 정보에 액세스할 수 없습니다. 주문을 처리하는 데 문제가 있는 경우 제공한 이메일 주소를 사용하여 연락을 드립니다.',
				d67: 'c) 제3자 콘텐츠 제공자: 귀하가 IDC 또는 기타 증권 거래소에서 시장 데이터를 구매할 때 당사는 귀하의 연락처 세부 정보, 전화 번호, 주소 및 이메일을 제공하고 귀하는 계약에 서명합니다. 이 데이터는 IDC 또는 기타 증권 거래소에서 처리되어 귀하에게 거래소에 대한 액세스를 제공합니다.',
				d68: 'd) Alpha 계열사: 귀하가 당사에 요청한 서비스를 제공하기 위해 당사 계열사는 귀하에게 제품 및 서비스를 제공하는 것을 포함하여 위에서 설명한 목적을 위해 귀하로부터 수집한 정보에 액세스하고 처리할 수 있습니다. 당사의 제휴 사업체는 당사가 해당 데이터를 원래 수집한 목적으로만 귀하의 데이터를 사용합니다.',
				d69: 'e) 중개인: 중개인을 위한 계좌 개설 양식을 작성할 때 이 양식에 기입된 귀하의 개인 데이터를 보내드립니다: 성과 이름, 전화번호, 이메일 주소, 국가. 이 데이터는 귀하의 중개 계좌를 개설하기 위해 중개인에 의해 처리됩니다.',
				d70: 'f) 분석 제공자: 당사는 당사 서비스 및 모바일 애플리케이션의 사용을 모니터링하고 분석하기 위해 제3자 서비스 제공자를 이용할 수 있습니다. 우리는 이 정보를 사용하여 서비스 및 모바일 애플리케이션 작업을 분석하고 개선합니다.',
				d71: 'g) 사업 양도: 우리가 사업을 매각하거나 회사 자산을 제3자에게 인수하는 경우, 일반 계정 사용자, 구독 계정 사용자 또는 고객에 대해 우리가 보유한 개인 데이터가 양도된 자산 중 하나일 수 있습니다.',
				d72: 'h) 관리 및 법적 이유: (i) 법적 의무 및/또는 사법 또는 규제 절차, 법원 명령 또는 기타 법적 절차를 준수하기 위해, (ii) 약관을 시행하기 위해 귀하의 개인 데이터를 공개해야 하는 경우 귀하가 적용되는 사용, 하우스 규칙 또는 기타 적용 가능한 계약 조건, 또는 (iii) 당사 또는 계약자를 손실 또는 손상으로부터 보호하기 위해. 여기에는 경찰, 법원 또는 법 집행 기관과의 정보 교환이 포함될 수 있지만 이에 국한되지 않습니다.',
				d73: 'i) 광고: 당사는 웹사이트 및 애플리케이션에 광고를 표시하고 리마케팅 및 추적 기능을 사용할 수 있습니다. 리마케팅 기능을 사용하면 다른 웹사이트에서의 사용자 활동을 기반으로 사용자에게 관심 있는 광고를 제공하기 위해 보다 타겟팅된 방식으로 광고를 표시할 수 있습니다. 추적 기능을 통해 노출, 광고 클릭 및 사용자가 당사 웹사이트 및 애플리케이션을 통해 완료하는 특정 작업에 대한 정보를 수집할 수 있습니다. 이 데이터는 또한 광고 캠페인의 성과를 평가하는 데 도움이 됩니다. 우리는 가명 데이터만 수집한다는 점에 유의하십시오.',
				d74: '우리는 광고주와 개인 정보를 공유하지 않습니다. 광고가 생성한 노출수와 클릭수를 포함하는 일반 통계에만 액세스할 수 있습니다.',
				d75: '개인 데이터의 보안',
				d76: '우리는 귀하의 개인 데이터를 보호하기 위해 기술적 및 조직적 안전 장치를 사용하고 우리와 협력하는 제3자에게도 동일한 작업을 수행하도록 요구합니다.',
				d77: '우리는 귀하가 제출한 특정 정보를 보호하기 위해 TLS(Transport Layer Security) 암호화 기술을 사용합니다. 이 기술은 귀하의 정보가 Alpha 또는 지불 프로세서로 전송되는 동안 누군가가 귀하의 정보를 가로채는 것을 방지합니다. 주문 양식과 같은 보안 페이지에 있는 동안 브라우저 창에 "잠금" 아이콘이 표시되어 웹 사이트 및 애플리케이션과의 안전하고 암호화된 연결이 설정되었음을 확인합니다. 우리는 서비스가 안전하고 업계 표준을 충족하도록 하기 위해 열심히 노력합니다. 또한 시스템 및 데이터에 대한 무단 액세스를 제어하기 위해 방화벽, 인증 시스템(예: 암호 및 개인 식별 번호) 및 액세스 제어 메커니즘과 같은 기타 안전 장치를 사용합니다. 계정을 만들기로 선택한 경우 액세스 세부 정보를 비밀로 유지하기 위해 합리적으로 할 수 있는 모든 작업을 수행할 책임이 있습니다. 이러한 세부 정보를 다른 사람과 공유해서는 안 됩니다.',
				d78: '또한 오프라인에서 사용자 정보를 보호하기 위해 최선을 다합니다. 모든 사용자의 개인 정보는 당사로 제한됩니다. 작업을 수행하기 위해 정보를 볼 필요가 있는 직원만 액세스할 수 있습니다.',
				d79: '개인 데이터를 저장하는 서버는 보호 시설의 안전한 환경에 있습니다. 당사는 시스템에 의한 무단 액세스를 방지하는 데 도움이 되는 물리적 보안 조치를 추가하는 등 당사가 수집한 데이터를 보호, 저장 및 처리하는 방법을 지속적으로 개선하고 있습니다. 그러나 전자 전송 또는 저장 방법은 100% 안전하지 않습니다. 따라서 절대적인 보안을 보장할 수 없습니다.',
			},
		},
		register: {
			firstName: '이름',
			lastName: '성',
			phoneNumber: '전화번호',
			select: '고르다',
			email: '이메일',
			phone: '전화기',
			verificationCode: '인증 코드',
			getCode: '코드 받기',
			password: '비밀번호',
			confirmPassword: '비밀번호 확인',
			iDNumber: 'ID 번호',
			submit: '제출',
			required: '* 필수',
			step1: '등록',
			step2: '계좌 개설',
			success1: '제출 성공',
			success2: '계좌개설 심사는 1-3영업일 이내에 완료될 것이다',
			pass: '계좌 개설 검토가 통과되었습니다.',
			noPass: '계좌 개설 검토 실패',
			back: '돌아가서 다시 제출',
			codeError: '정확한 인증번호를 입력해주세요',
			getImgError: '새로운 그래픽 인증 코드를 얻는 데 5초가 걸립니다',
			d1: '신분 확인을 위해 정부에서 발급한 개인 ID 번호 또는 여권을 첨부해주세요.',
			d2: '신분 자료 앞면',
			d3: '신분 자료 뒷면',
			d4: '파일 찾아보기',
			d5: ' 또는 업로드할 이미지를 드래그',
			d6: '또는',
			d7: '샘플 이미지 선택',
			d8: '아래의 "제출" 버튼을 클릭하면 ',
			d9: '개인정보 보호정책',
			d10: '.',
			d11: '올바른 이메일 주소가 아닙니다.',
			d12: '* 로그인 계정으로 이메일 또는 전화번호 선택',
			d13: '최소 8자 길이',
			d14: '암호 불일치',
			AD: '안도라',
			AE: '아랍에미리트',
			AF: '아프가니스탄',
			AG: '앤티가 바부다',
			AI: '앵귈라',
			AL: '알바니아',
			AM: '아르메니아',
			AO: '앙골라',
			AR: '아르헨티나',
			AT: '오스트리아',
			AU: '호주',
			AZ: '아제르바이잔',
			BB: '바베이도스',
			BD: '방글라데시',
			BE: '벨기에',
			BF: '부르키나파소',
			BG: '불가리아',
			BH: '바레인',
			BI: '부룬디',
			BJ: '베냉',
			BL: '팔레스타인',
			BM: '버뮤다입니다.',
			BN: '브루나이',
			BO: '볼리비아',
			BR: '브라질',
			BS: '바하마',
			BW: '보츠와나',
			BY: '벨로루시',
			BZ: '벨리즈',
			CA: '캐나다',
			KY: '케이맨입니다.',
			CF: '중앙 아프리카 공화국',
			CG: '콩고',
			CH: '스위스',
			CK: '쿡 이즈.',
			CL: '칠레',
			CM: '카메룬',
			CN: '중국',
			CO: '콜롬비아',
			CR: '코스타리카',
			CS: '체코어',
			CU: '쿠바',
			CY: '키프로스',
			CZ: '체코',
			DE: '독일',
			DJ: '지부티',
			DK: '덴마크',
			DO: '도미니카 공화국 대표',
			DZ: '알제리',
			EC: '에콰도르',
			EE: '에스토니아',
			EG: '이집트',
			ES: '스페인',
			ET: '에티오피아',
			FI: '핀란드',
			FJ: '피지',
			FR: '프랑스',
			GA: '가봉',
			GB: '영국',
			GD: '그레나다',
			GE: '조지아',
			GF: '프랑스령 기아나',
			GH: '가나',
			GI: '지브롤터',
			GM: '감비아',
			GN: '기니',
			GR: '그리스',
			GT: '과테말라',
			GU: '괌',
			GY: '가이아나',
			HK: '홍콩',
			HN: '온두라스',
			HT: '아이티',
			HU: '헝가리',
			ID: '인도네시아',
			IE: '아일랜드',
			IL: '이스라엘',
			IN: '인도',
			IQ: '이라크',
			IR: '이란',
			IS: '아이슬란드',
			IT: '이탈리아',
			CI: '아이보리코스트',
			JM: '자메이카',
			JO: '조던',
			JP: '일본',
			KE: '케냐',
			KG: '키르기스스탄',
			KH: '캄보디아',
			KP: '북한',
			KR: '한국',
			KT: '코트디부아르 공화국',
			KW: '쿠웨이트',
			KZ: '카자흐스탄',
			LA: '라오스',
			LB: '레바논',
			LC: '세인트 루시아',
			LI: '리히텐슈타인',
			LK: '스리랑카',
			LR: '라이베리아',
			LS: '레소토',
			LT: '리투아니아',
			LU: '룩셈부르크',
			LV: '라트비아',
			LY: '리비아',
			MA: '모로코',
			MC: '모나코',
			MD: '몰도바 공화국',
			MG: '마다가스카르',
			ML: '말리',
			MM: '버마',
			MN: '몽골',
			MO: '마카오',
			MS: '몬세라트는',
			MT: '몰타',
			MQ: '마르티니크',
			MU: '모리셔스',
			MV: '몰디브',
			MW: '말라위',
			MX: '멕시코',
			MY: '말레이시아',
			MZ: '모잠비크',
			NA: '나미비아',
			NE: '니제르',
			NG: '나이지리아',
			NI: '니카라과',
			NL: '네덜란드',
			NO: '노르웨이',
			NP: '네팔',
			AN: '네덜란드 안틸레스',
			NR: '나우루',
			NZ: '뉴질랜드',
			OM: '오만',
			PA: '파나마',
			PE: '페루',
			PF: '프랑스령 폴리네시아',
			PG: '파푸아뉴기니',
			PH: '필리핀',
			PK: '파키스탄',
			PL: '폴란드',
			PR: '푸에르토리코',
			PT: '포르투갈',
			PY: '파라과이',
			QA: '카타르',
			RE: '재회',
			RO: '루마니아',
			RU: '러시아',
			SA: '사우디아라비아',
			SB: '솔로몬은',
			SC: '세이셸',
			SD: '수단',
			SE: '스웨덴',
			SG: '싱가포르',
			SI: '슬로베니아',
			SK: '슬로바키아',
			SL: '시에라리온',
			SM: '산마리노',
			AS: '사모아 이스턴',
			WS: '산마리노',
			SN: '세네갈',
			SO: '소말리',
			SR: '수리남',
			ST: '상투메 프린시페',
			SV: '엘살바도르',
			SY: '시리아',
			SZ: '스와질란드',
			TD: '차드',
			TG: '토고',
			TH: '태국',
			TJ: '타지키스탄',
			TM: '투르크메니스탄',
			TN: '튀니지',
			TO: '통가',
			TR: '터키',
			TT: '트리니다드 토바고',
			TW: '대만',
			TZ: '탄자니아',
			UA: '우크라이나',
			UG: '우간다',
			US: '미합중국',
			UY: '우루과이',
			UZ: '우즈베키스탄',
			VC: '세인트 빈센트',
			VE: '베네수엘라',
			VN: '베트남',
			YE: '예멘',
			YU: '유고슬라비아',
			ZA: '남아프리카공화국',
			ZM: '잠비아',
			ZR: '자이르',
			ZW: '짐바브웨',
		},
		login: {
			username: '전화번호/이메일',
			password: '비밀번호',
			login: '로그인',
			required: '* 필수',
			have: '이미 계정이 있습니다. 로그인하세요.',
			other: '다른 계정에 로그인',
			create: '새 계정을 만들',
		},
	},
}
