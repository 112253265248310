<template>
  <div class="wrap">
    <div class="main" v-show="!loading">
      <div class="inputTable">
        <img class="close" :src="closeIcon" @click="$store.state.openAccountShow=false">
        <el-steps :active="step" finish-status="success" align-center style="transform: translateY(-28px)"
                  v-show="!loginShow">
          <el-step :title="$t('views.register.step1')"></el-step>
          <el-step :status="stepStatus" :title="$t('views.register.step2')"></el-step>
        </el-steps>
        <div class="registerFrame" v-show="step===0&&!loginShow">
          <p class="tips">{{ $t('views.register.d12') }}</p>
          <div class="row_form">
            <div class="row_account">
              <div class="accountChoose" @click="accountChooseShow = true;isSelect = true" ref="account"
                   :style="isSelect?'':'border: 1px solid #ff0000;background: #ffebeb'">
                <p :style="accountChoose == null?'color:#b2b2b2':''">
                  {{
                    accountChoose != null ? (accountChoose === 0 ? $t('views.register.phone') : $t('views.register.email')) : $t('views.register.select')
                  }}
                </p>
                <img :src="accountChooseShow?arrowOn:arrow" class="arrow" @mouseenter="arrowChangeOn"
                     @mouseleave="arrowChange">
              </div>
              <div class="accountChooseBox" v-if="accountChooseShow">
                <p @click="chooseAccount(0)">{{ $t('views.register.phone') }}</p>
                <p @click="chooseAccount(1)">{{ $t('views.register.email') }}</p>
              </div>


            </div>

            <div class=" account_right">
              <div class="control_wrap" style="width: 100%;" v-show="accountChoose===0">
                <div style="position: relative;width: 100%">
                  <input type="text" class="text_input" value="" size="40" aria-required="true" aria-invalid="false"
                         :placeholder="$t('views.register.phoneNumber')" oninput="value=value.replace(/[^\d]/g,'')"
                         :style="'width:100%;padding-left: 110px; '+(isPhoneNumber?'':'border: 1px solid #ff0000;background: #ffebeb')"
                         @focus="isPhoneNumber=true" @blur="phoneNumberCheck" v-model="message.phoneNumber"
                         ref="phoneNumber">
                  <p class="required" v-if="!isPhoneNumber">{{
                      $t('views.register.required')
                    }}</p>
                </div>
                <div :class="'areaCodeBefore '+(!isPhoneNumber?'redBefore':'')" ref="areaCode"
                     @click="openAreaCode">

                  <span class="short">{{ areaCode.short }}</span>
                  {{ areaCode.tel }}
                </div>
                <div class="areaCodeChooseBox" v-show="areaCodeShow">
                  <div class="chooseBox" v-for="(item,index) in countyOrRegionData" v-bind:key="index"
                       @click="chooseAreaCode(item)">
                    <span class="short">{{ item.short }}</span>
                    <p class="chooseText">{{ item.tel }}</p>
                  </div>
                </div>


              </div>

              <div class="control_wrap" style="width: 100%;" v-show="accountChoose===1">
                <div style="position: relative">
                  <input type="text" class="text_input" value="" size="40" aria-required="true" aria-invalid="false"
                         :placeholder="$t('views.register.email')" ref="email"
                         :style="'width:100%;'+(isEmail&&isEMailFormat?'':'border: 1px solid #ff0000;background: #ffebeb')"
                         @focus="isEmail=true;isEMailFormat=true" @blur="eMailCheck" v-model="message.eMail">
                  <p class="required" v-if="!isEmail">{{ $t('views.register.required') }}</p>
                  <p class="required" v-if="!isEMailFormat&&isEmail">{{ $t('views.register.d11') }}</p>
                </div>
              </div>

              <div style="position: relative;width:86%;margin-left:20px" v-if="accountChoose!=null">
                <input type="text" class="text_input_code" value="" size="40" aria-required="true" aria-invalid="false"
                       :placeholder="$t('views.register.verificationCode')" oninput="value=value.replace(/[^\d]/g,'')"
                       maxlength="8" ref="code"
                       :style="(isCode||!isCode&&captcha.show?'':'border: 1px solid #ff0000;background: #ffebeb;')"
                       @focus="isCode=true" @blur="codeCheck" v-model="message.code">
                <!--                <p class="required" style="right: 100px" v-if="!isCode&&!captcha.show">{{-->
                <!--                    $t('views.register.required')-->
                <!--                  }}</p>-->
                <p class="getCode" @click="getCode">
                  {{ $store.state.codeTime === 0 ? $t('views.register.getCode') : $store.state.codeTime + 's' }}</p>
                <div class="captcha_img" v-if="captcha.show">
                  <input maxlength="4" v-model="captcha.code" ref="captcha"
                         @input="captcha.code.length>=4?sendCode():''">
                  <img :src="captcha.img" @click="getcaptchaImg">
                </div>
              </div>
            </div>

          </div>

          <!--password-->
          <div class="row_form">
            <div class="control_wrap">
              <div style="position: relative">
                <input type="password" class="text_input" value="" size="40" aria-required="true" aria-invalid="false"
                       :placeholder="$t('views.register.password')"
                       :style="(isPassword?'':'border: 1px solid #ff0000;background: #ffebeb')"
                       @focus="isPassword=true;isPasswordLong=true" @blur="passwordCheck" v-model="message.password">
                <p class="required" v-if="!isPassword" style="right:calc(6% + 20px)">{{
                    $t('views.register.required')
                  }}</p>
                <p class="required passwordLong" v-if="!isPasswordLong&&isPassword" style="right:calc(6% + 20px)">
                  {{ $t('views.register.d13') }}</p>
              </div>
            </div>
            <div class="control_wrap">
              <div style="position: relative">
                <input type="password" class="text_input" value="" size="40" aria-required="true" aria-invalid="false"
                       :placeholder="$t('views.register.confirmPassword')"
                       :style="'float:right; '+(isConfirm?'':'border: 1px solid #ff0000;background: #ffebeb')"
                       @focus="isConfirm=true;isConfirmPassword=true" @blur="confirmCheck" v-model="message.confirm">
                <p class="required" v-if="!isConfirm">{{ $t('views.register.required') }}</p>
                <p class="required noConfirm" v-if="!isConfirmPassword&&isConfirm">{{ $t('views.register.d14') }}</p>
              </div>
            </div>
          </div>
          <!--country-->
          <div class="row_form">
            <div class="control_wrap">
              <div class="country" @click="openCOR" ref="country">
                <input class="search" type="text" maxlength="2" v-model="search" @focus="search=''">
                <img :src="countryOrRegionShow?arrowOn:arrow" class="arrow" @mouseenter="arrowChangeOn"
                     @mouseleave="arrowChange">
                <p>{{ countyOrRegion.en }}</p>
              </div>
              <div class="countryChooseBox" v-show="countryOrRegionShow">
                <div class="chooseBox" v-for="(item,index) in this.searchList" v-bind:key="index"
                     @click="chooseCountyOrRegion(item)">
                  <span class="short">{{ item.short }}</span>
                  <div class="chooseText">{{ item.en }}</div>
                </div>
              </div>
            </div>
          </div>

          <div class="row_btn">
            <div class="submit" @click="register()">
              <span>{{ $t('views.register.step1') }}</span>
              <img :src="submitArrow">
            </div>
          </div>
        </div>
        <div class="openAccountFrame" v-show="step===1&&!loginShow">
          <img class="close" :src="closeIcon" @click="$store.state.openAccountShow=false">
          <div class="row_form">
            <div class="control_wrap">
              <div style="position: relative">
                <input type="text" class="text_input" value="" size="40" aria-required="true" aria-invalid="false"
                       :placeholder="$t('views.register.firstName')"
                       :style="(isFirstName?'':'border: 1px solid #ff0000;background: #ffebeb')"
                       @focus="isFirstName=true" @blur="firstNameCheck" v-model="message.firstName">
                <p class="required" v-if="!isFirstName" style="right:calc(6% + 20px)">{{
                    $t('views.register.required')
                  }}</p>
              </div>
            </div>
            <div class="control_wrap">
              <div style="position: relative">
                <input type="text" class="text_input" value="" size="40" aria-required="true" aria-invalid="false"
                       :placeholder="$t('views.register.lastName')"
                       :style="'float:right; '+(isLastName?'':'border: 1px solid #ff0000;background: #ffebeb')"
                       @focus="isLastName=true" @blur="lastNameCheck" v-model="message.lastName">
                <p class="required" v-if="!isLastName">{{ $t('views.register.required') }}</p>
              </div>
            </div>
          </div>


          <div class="row_form">
            <p class="tips">{{ $t('views.register.d1') }}</p>
          </div>
          <div class="row_form">
            <div class="control_wrap" style="width: 100%">
              <div style="position: relative">
                <input type="text"
                       :style="'width: 100%;padding-left:110px;'+(isIDNumber?'':'border: 1px solid #ff0000;background: #ffebeb')"
                       class="text_input" value="" size="40"
                       aria-required="true"
                       aria-invalid="false"
                       :placeholder="$t('views.register.iDNumber')" ref="IDNumber"
                       @focus="isIDNumber=true" @blur="iDNumberCheck" v-model="message.iDNumber">
                <p class="required" v-if="!isIDNumber">{{ $t('views.register.required') }}</p>
              </div>
              <div :class="'cORBefore '+(!isIDNumber?'redBefore':'')" @click="openCOR" ref="main">

                <input class="search" type="text" maxlength="2" v-model="search" @focus="search=''">


                <img :src="countryOrRegionShow?arrowOn:arrow" class="arrow" @mouseenter="arrowChangeOn"
                     @mouseleave="arrowChange">
              </div>
              <div class="countryOrRegionChooseBox" v-show="countryOrRegionShow">
                <div class="chooseBox" v-for="(item,index) in this.searchList" v-bind:key="index"
                     @click="chooseCountyOrRegion(item)">
                  <span class="short">{{ item.short }}</span>
                  <div class="chooseText">{{ item.en }}</div>
                </div>
              </div>


            </div>
          </div>
          <div class="selectFrame">
            <div class="selectBox">
              <p>{{ $t('views.register.d2') }}</p>
              <input id="0" ref="front" type="file" accept="image/*" @change="updateImg">
              <div :class="'imgUpdate '+(this.isImg_0?'':'imgR')" @click="selectImg(0)" ref="box0">
                <div class="imgModel">
                  <img class="imgIcon" :src="updateImgIcon">
                </div>
                <p class="img_text"><span>{{ $t('views.register.d4') }}</span>{{
                    $t('views.register.d5')
                  }}<br>{{ $t('views.register.d6') }}<br><span>{{ $t('views.register.d7') }}</span>
                </p>
                <p class="requiredImg" v-if="!isImg_0">{{ $t('views.register.required') }}</p>
                <img class="imgUp" v-if="img_0" :src="img_0">
              </div>
            </div>
            <div class="selectBox">
              <p>{{ $t('views.register.d3') }}</p>
              <input id="1" ref="back" type="file" accept="image/*" @change="updateImg">
              <div :class="'imgUpdate '+(this.isImg_1?'':'imgR')" @click="selectImg(1)" ref="box1">
                <div class="imgModel">
                  <img class="imgIcon" :src="updateImgIcon">
                </div>
                <p class="img_text"><span>{{ $t('views.register.d4') }}</span> {{
                    $t('views.register.d5')
                  }}<br>{{ $t('views.register.d6') }}<br><span>{{ $t('views.register.d7') }}</span>
                </p>
                <p class="requiredImg" v-if="!isImg_1">{{ $t('views.register.required') }}</p>
                <img class="imgUp" v-if="img_1" :src="img_1">
              </div>
            </div>
          </div>
          <div class="privacy_policy">
            <input type="checkbox" v-model="isAgreement" @click="isAgreementR=false">
            <p>{{ $t('views.register.d8') }}<span
                @click="openWindow">{{ $t('views.register.d9') }}</span>{{ $t('views.register.d10') }}</p>
            <p style="font-size: 14px;color: #ff0000;" v-if="isAgreementR">{{ $t('views.register.required') }}</p>
          </div>
          <div class="row_btn">
            <div class="submit" @click="submit">
              <span>{{ $t('views.register.submit') }}</span>
              <img :src="submitArrow">
            </div>
          </div>

        </div>
        <div v-if="step===2">
          <el-result :icon="icon" :title="statusPage.title" :subTitle="statusPage.subTitle" v-if="!loginShow">
            <template slot="extra">
              <div class="backBtn" @click="back()" v-if="(audit_status==2||audit_status==3)">
                {{ $t('views.register.back') }}
              </div>
            </template>
          </el-result>
        </div>


        <div class="loginFrame" v-if="loginShow" style="width: 600px;margin: auto">
          <div class="control_wrap" style="margin-bottom: 27px">
            <div style="position: relative">
              <input type="text" class="text_input_login" value="" size="40" aria-required="true" aria-invalid="false"
                     :placeholder="$t('views.login.username')"
                     :style="(loginForm.isUsername?'':'border: 1px solid #ff0000;background: #ffebeb')"
                     @focus="loginForm.isUsername=true" @blur="loginUsernameCheck" v-model="loginForm.username">
              <p class="required" v-if="!loginForm.isUsername">{{
                  $t('views.login.required')
                }}</p>
            </div>
          </div>
          <div class="control_wrap" style="margin-bottom: 27px">
            <div style="position: relative">
              <input type="password" class="text_input_login" value="" size="40" aria-required="true"
                     aria-invalid="false"
                     :placeholder="$t('views.login.password')"
                     :style="(loginForm.isPassword?'':'border: 1px solid #ff0000;background: #ffebeb')"
                     @focus="loginForm.isPassword=true" @blur="loginPasswordCheck" v-model="loginForm.password">
              <p class="required" v-if="!loginForm.isPassword">{{
                  $t('views.login.required')
                }}</p>
            </div>
          </div>
          <div class="button_row">
            <div class="login_btn" @click="login">{{ $t('views.login.login') }}</div>
          </div>
        </div>


      </div>

      <p style="text-align: center">
        <span class="toLogin" @click="loginShow=true"
              v-if="!loginShow">{{ step == 0 ? $t('views.login.have') : $t('views.login.other') }}</span>
        <span class="toLogin" @click="step=0;stepStatus='process'" v-if="!loginShow&&step!==0"
              style="margin-left: 24px">{{ $t('views.login.create') }}</span>
      </p>
    </div>


    <div class="mask" @click="$store.state.openAccountShow= false" element-loading-background="rgba(0, 0, 0, 0)"
         v-loading="loading"/>
  </div>
</template>

<script>
export default {
  name: "openAccount",
  data() {
    return {
      step: 0,
      message: {
        firstName: "",
        lastName: "",
        phoneNumber: "",
        eMail: "",
        short: "",
        iDNumber: "",
        password: "",
        confirm: "",
        code: "",
        verify_uuid: ""
      },
      accountData: {
        id: null,
        token: null,

      },


      closeIcon: require('@/assets/img/header/close.png'),
      updateImgIcon: require('@/assets/img/header/image-add-fill.png'),
      submitArrow: require('@/assets/img/header/arrow-right-bold.png'),
      img_0: null,
      img_1: null,
      imgUp_0: null,
      imgUp_1: null,
      areaCodeShow: false,
      areaCode: {
        "short": "KR",
        "name": "韩国",
        "en": "Korea",
        "tel": "82",
        "pinyin": "hg"
      },
      countryOrRegionShow: false,
      cORLeft: 60,
      countyOrRegion: {
        "short": "KR",
        "name": "韩国",
        "en": "Korea",
        "tel": "82",
        "pinyin": "hg"
      },
      search: 'KR',
      searchList: null,
      arrow: require("@/assets/img/header/arrowFlag.png"),
      arrowOn: require("@/assets/img/header/arrowFlagOn.png"),

      isSelect: true,
      isFirstName: true,
      isLastName: true,
      isPhoneNumber: true,
      isEmail: true,
      isEMailFormat: true,
      isCode: true,
      isPassword: true,
      isPasswordLong: true,
      isConfirm: true,
      isConfirmPassword: true,
      isIDNumber: true,
      isAgreement: true,
      isImg_0: true,
      isImg_1: true,
      isAgreementR: false,
      accountChoose: null,//0:phone number,1:email,null:null
      accountChooseShow: false,
      countyOrRegionData: [
        {
          "short": "AD",
          "name": "安道尔共和国",
          "en": "Andorra",
          "tel": "376",
          "pinyin": "adeghg"
        },
        {
          "short": "AE",
          "name": "阿拉伯联合酋长国",
          "en": "United Arab Emirates",
          "tel": "971",
          "pinyin": "alblhqzg"
        },
        {
          "short": "AF",
          "name": "阿富汗",
          "en": "Afghanistan",
          "tel": "93",
          "pinyin": "afh"
        },
        {
          "short": "AG",
          "name": "安提瓜和巴布达",
          "en": "Antigua and Barbuda",
          "tel": "1268",
          "pinyin": "atghbbd"
        },
        {
          "short": "AI",
          "name": "安圭拉岛",
          "en": "Anguilla",
          "tel": "1264",
          "pinyin": "agld"
        },
        {
          "short": "AL",
          "name": "阿尔巴尼亚",
          "en": "Albania",
          "tel": "355",
          "pinyin": "aebny"
        },
        {
          "short": "AM",
          "name": "阿美尼亚",
          "en": "Armenia",
          "tel": "374",
          "pinyin": "amny"
        },
        {
          "short": "AO",
          "name": "安哥拉",
          "en": "Angola",
          "tel": "244",
          "pinyin": "agl"

        },
        {
          "short": "AR",
          "name": "阿根廷",
          "en": "Argentina",
          "tel": "54",
          "pinyin": "agt"

        },
        {
          "short": "AT",
          "name": "奥地利",
          "en": "Austria",
          "tel": "43",
          "pinyin": "adl"

        },
        {
          "short": "AU",
          "name": "澳大利亚",
          "en": "Australia",
          "tel": "61",
          "pinyin": "adly"

        },
        {
          "short": "AZ",
          "name": "阿塞拜疆",
          "en": "Azerbaijan",
          "tel": "994",
          "pinyin": "asbj"

        },
        {
          "short": "BB",
          "name": "巴巴多斯",
          "en": "Barbados",
          "tel": "1246",
          "pinyin": "bbds"

        },
        {
          "short": "BD",
          "name": "孟加拉国",
          "en": "Bangladesh",
          "tel": "880",
          "pinyin": "mjlg"

        },
        {
          "short": "BE",
          "name": "比利时",
          "en": "Belgium",
          "tel": "32",
          "pinyin": "bls"

        },
        {
          "short": "BF",
          "name": "布基纳法索",
          "en": "Burkina-faso",
          "tel": "226",
          "pinyin": "bjnfs"

        },
        {
          "short": "BG",
          "name": "保加利亚",
          "en": "Bulgaria",
          "tel": "359",
          "pinyin": "bjly"

        },
        {
          "short": "BH",
          "name": "巴林",
          "en": "Bahrain",
          "tel": "973",
          "pinyin": "bl"

        },
        {
          "short": "BI",
          "name": "布隆迪",
          "en": "Burundi",
          "tel": "257",
          "pinyin": "bld"

        },
        {
          "short": "BJ",
          "name": "贝宁",
          "en": "Benin",
          "tel": "229",
          "pinyin": "bl"

        },
        {
          "short": "BL",
          "name": "巴勒斯坦",
          "en": "Palestine",
          "tel": "970",
          "pinyin": "blst"

        },
        {
          "short": "BM",
          "name": "百慕大群岛",
          "en": "BermudaIs.",
          "tel": "1441",
          "pinyin": "bmdqd"

        },
        {
          "short": "BN",
          "name": "文莱",
          "en": "Brunei",
          "tel": "673",
          "pinyin": "wl"

        },
        {
          "short": "BO",
          "name": "玻利维亚",
          "en": "Bolivia",
          "tel": "591",
          "pinyin": "blwy"

        },
        {
          "short": "BR",
          "name": "巴西",
          "en": "Brazil",
          "tel": "55",
          "pinyin": "bx"

        },
        {
          "short": "BS",
          "name": "巴哈马",
          "en": "Bahamas",
          "tel": "1242",
          "pinyin": "bhm"

        },
        {
          "short": "BW",
          "name": "博茨瓦纳",
          "en": "Botswana",
          "tel": "267",
          "pinyin": "bcwn"

        },
        {
          "short": "BY",
          "name": "白俄罗斯",
          "en": "Belarus",
          "tel": "375",
          "pinyin": "bels"

        },
        {
          "short": "BZ",
          "name": "伯利兹",
          "en": "Belize",
          "tel": "501",
          "pinyin": "blz"

        },
        {
          "short": "CA",
          "name": "加拿大",
          "en": "Canada",
          "tel": "1",
          "pinyin": "jnd"

        },
        {
          "short": "KY",
          "name": "开曼群岛",
          "en": "CaymanIs.",
          "tel": "1345",
          "pinyin": "kmqd"

        },
        {
          "short": "CF",
          "name": "中非共和国",
          "en": "Central African Republic",
          "tel": "236",
          "pinyin": "zfghg"

        },
        {
          "short": "CG",
          "name": "刚果",
          "en": "Congo",
          "tel": "242",
          "pinyin": "gg"

        },
        {
          "short": "CH",
          "name": "瑞士",
          "en": "Switzerland",
          "tel": "41",
          "pinyin": "rs"

        },
        {
          "short": "CK",
          "name": "库克群岛",
          "en": "Cook Is.",
          "tel": "682",
          "pinyin": "kkqd"

        },
        {
          "short": "CL",
          "name": "智利",
          "en": "Chile",
          "tel": "56",
          "pinyin": "zl"

        },
        {
          "short": "CM",
          "name": "喀麦隆",
          "en": "Cameroon",
          "tel": "237",
          "pinyin": "kml"

        },
        {
          "short": "CN",
          "name": "中国",
          "en": "China",
          "tel": "86",
          "pinyin": "zg"

        },
        {
          "short": "CO",
          "name": "哥伦比亚",
          "en": "Colombia",
          "tel": "57",
          "pinyin": "glby"

        },
        {
          "short": "CR",
          "name": "哥斯达黎加",
          "en": "Costa Rica",
          "tel": "506",
          "pinyin": "gsdlj"

        },
        {
          "short": "CU",
          "name": "古巴",
          "en": "Cuba",
          "tel": "53",
          "pinyin": "gb"

        },
        {
          "short": "CY",
          "name": "塞浦路斯",
          "en": "Cyprus",
          "tel": "357",
          "pinyin": "spls"

        },
        {
          "short": "CZ",
          "name": "捷克",
          "en": "Czech Republic",
          "tel": "420",
          "pinyin": "jk"

        },
        {
          "short": "DE",
          "name": "德国",
          "en": "Germany",
          "tel": "49",
          "pinyin": "dg"

        },
        {
          "short": "DJ",
          "name": "吉布提",
          "en": "Djibouti",
          "tel": "253",
          "pinyin": "jbt"

        },
        {
          "short": "DK",
          "name": "丹麦",
          "en": "Denmark",
          "tel": "45",
          "pinyin": "dm"

        },
        {
          "short": "DO",
          "name": "多米尼加共和国",
          "en": "Dominica Rep.",
          "tel": "1890",
          "pinyin": "dmnjghg"

        },
        {
          "short": "DZ",
          "name": "阿尔及利亚",
          "en": "Algeria",
          "tel": "213",
          "pinyin": "aejly"

        },
        {
          "short": "EC",
          "name": "厄瓜多尔",
          "en": "Ecuador",
          "tel": "593",
          "pinyin": "egde"

        },
        {
          "short": "EE",
          "name": "爱沙尼亚",
          "en": "Estonia",
          "tel": "372",
          "pinyin": "asny"

        },
        {
          "short": "EG",
          "name": "埃及",
          "en": "Egypt",
          "tel": "20",
          "pinyin": "ej"

        },
        {
          "short": "ES",
          "name": "西班牙",
          "en": "Spain",
          "tel": "34",
          "pinyin": "xby"

        },
        {
          "short": "ET",
          "name": "埃塞俄比亚",
          "en": "Ethiopia",
          "tel": "251",
          "pinyin": "aseby"

        },
        {
          "short": "FI",
          "name": "芬兰",
          "en": "Finland",
          "tel": "358",
          "pinyin": "fl"

        },
        {
          "short": "FJ",
          "name": "斐济",
          "en": "Fiji",
          "tel": "679",
          "pinyin": "fj"

        },
        {
          "short": "FR",
          "name": "法国",
          "en": "France",
          "tel": "33",
          "pinyin": "fg"

        },
        {
          "short": "GA",
          "name": "加蓬",
          "en": "Gabon",
          "tel": "241",
          "pinyin": "jp"

        },
        {
          "short": "GB",
          "name": "英国",
          "en": "United Kingdom",
          "tel": "44",
          "pinyin": "yg"

        },
        {
          "short": "GD",
          "name": "格林纳达",
          "en": "Grenada",
          "tel": "1809",
          "pinyin": "glnd"

        },
        {
          "short": "GE",
          "name": "格鲁吉亚",
          "en": "Georgia",
          "tel": "995",
          "pinyin": "gljy"

        },
        {
          "short": "GF",
          "name": "法属圭亚那",
          "en": "French Guiana",
          "tel": "594",
          "pinyin": "fsgyn"

        },
        {
          "short": "GH",
          "name": "加纳",
          "en": "Ghana",
          "tel": "233",
          "pinyin": "jn"

        },
        {
          "short": "GI",
          "name": "直布罗陀",
          "en": "Gibraltar",
          "tel": "350",
          "pinyin": "zblt"

        },
        {
          "short": "GM",
          "name": "冈比亚",
          "en": "Gambia",
          "tel": "220",
          "pinyin": "gby"

        },
        {
          "short": "GN",
          "name": "几内亚",
          "en": "Guinea",
          "tel": "224",
          "pinyin": "jny"

        },
        {
          "short": "GR",
          "name": "希腊",
          "en": "Greece",
          "tel": "30",
          "pinyin": "xl"

        },
        {
          "short": "GT",
          "name": "危地马拉",
          "en": "Guatemala",
          "tel": "502",
          "pinyin": "wdml"

        },
        {
          "short": "GU",
          "name": "关岛",
          "en": "Guam",
          "tel": "1671",
          "pinyin": "gd"

        },
        {
          "short": "GY",
          "name": "圭亚那",
          "en": "Guyana",
          "tel": "592",
          "pinyin": "gyn"

        },
        {
          "short": "HK",
          "name": "香港(中国)",
          "en": "Hong Kong",
          "tel": "852",
          "pinyin": "xgzg"

        },
        {
          "short": "HN",
          "name": "洪都拉斯",
          "en": "Honduras",
          "tel": "504",
          "pinyin": "hdls"

        },
        {
          "short": "HT",
          "name": "海地",
          "en": "Haiti",
          "tel": "509",
          "pinyin": "hd"

        },
        {
          "short": "HU",
          "name": "匈牙利",
          "en": "Hungary",
          "tel": "36",
          "pinyin": "xyl"

        },
        {
          "short": "ID",
          "name": "印度尼西亚",
          "en": "Indonesia",
          "tel": "62",
          "pinyin": "ydnxy"

        },
        {
          "short": "IE",
          "name": "爱尔兰",
          "en": "Ireland",
          "tel": "353",
          "pinyin": "ael"

        },
        {
          "short": "IL",
          "name": "以色列",
          "en": "Israel",
          "tel": "972",
          "pinyin": "ysl"

        },
        {
          "short": "IN",
          "name": "印度",
          "en": "India",
          "tel": "91",
          "pinyin": "yd"

        },
        {
          "short": "IQ",
          "name": "伊拉克",
          "en": "Iraq",
          "tel": "964",
          "pinyin": "ylk"

        },
        {
          "short": "IR",
          "name": "伊朗",
          "en": "Iran",
          "tel": "98",
          "pinyin": "yl"

        },
        {
          "short": "IS",
          "name": "冰岛",
          "en": "Iceland",
          "tel": "354",
          "pinyin": "bd"

        },
        {
          "short": "IT",
          "name": "意大利",
          "en": "Italy",
          "tel": "39",
          "pinyin": "ydl"

        },
        {
          "short": "CI",
          "name": "科特迪瓦",
          "en": "Ivory Coast",
          "tel": "225",
          "pinyin": "ktdw"

        },
        {
          "short": "JM",
          "name": "牙买加",
          "en": "Jamaica",
          "tel": "1876",
          "pinyin": "ymj"

        },
        {
          "short": "JO",
          "name": "约旦",
          "en": "Jordan",
          "tel": "962",
          "pinyin": "yd"

        },
        {
          "short": "JP",
          "name": "日本",
          "en": "Japan",
          "tel": "81",
          "pinyin": "rb"

        },
        {
          "short": "KE",
          "name": "肯尼亚",
          "en": "Kenya",
          "tel": "254",
          "pinyin": "kny"

        },
        {
          "short": "KG",
          "name": "吉尔吉斯坦",
          "en": "Kyrgyzstan",
          "tel": "331",
          "pinyin": "jejst"

        },
        {
          "short": "KH",
          "name": "柬埔寨",
          "en": "Kampuchea(Cambodia)",
          "tel": "855",
          "pinyin": "jpz"

        },
        {
          "short": "KP",
          "name": "朝鲜",
          "en": "North Korea",
          "tel": "850",
          "pinyin": "cx"

        },
        {
          "short": "KR",
          "name": "韩国",
          "en": "Korea",
          "tel": "82",
          "pinyin": "hg"


        },
        {
          "short": "KT",
          "name": "科特迪瓦共和国",
          "en": "Republic of Ivory Coast",
          "tel": "225",
          "pinyin": "ktdwghg"

        },
        {
          "short": "KW",
          "name": "科威特",
          "en": "Kuwait",
          "tel": "965",
          "pinyin": "kwt"

        },
        {
          "short": "KZ",
          "name": "哈萨克斯坦",
          "en": "Kazakstan",
          "tel": "327",
          "pinyin": "hskst"

        },
        {
          "short": "LA",
          "name": "老挝",
          "en": "Laos",
          "tel": "856",
          "pinyin": "lw"

        },
        {
          "short": "LB",
          "name": "黎巴嫩",
          "en": "Lebanon",
          "tel": "961",
          "pinyin": "lbn"

        },
        {
          "short": "LC",
          "name": "圣卢西亚",
          "en": "St.Lucia",
          "tel": "1758",
          "pinyin": "slxy"

        },
        {
          "short": "LI",
          "name": "列支敦士登",
          "en": "Liechtenstein",
          "tel": "423",
          "pinyin": "lzdsd"

        },
        {
          "short": "LK",
          "name": "斯里兰卡",
          "en": "SriLanka",
          "tel": "94",
          "pinyin": "sllk"

        },
        {
          "short": "LR",
          "name": "利比里亚",
          "en": "Liberia",
          "tel": "231",
          "pinyin": "lbly"

        },
        {
          "short": "LS",
          "name": "莱索托",
          "en": "Lesotho",
          "tel": "266",
          "pinyin": "lst"

        },
        {
          "short": "LT",
          "name": "立陶宛",
          "en": "Lithuania",
          "tel": "370",
          "pinyin": "ltw"

        },
        {
          "short": "LU",
          "name": "卢森堡",
          "en": "Luxembourg",
          "tel": "352",
          "pinyin": "lsb"

        },
        {
          "short": "LV",
          "name": "拉脱维亚",
          "en": "Latvia",
          "tel": "371",
          "pinyin": "ltwy"

        },
        {
          "short": "LY",
          "name": "利比亚",
          "en": "Libya",
          "tel": "218",
          "pinyin": "lby"

        },
        {
          "short": "MA",
          "name": "摩洛哥",
          "en": "Morocco",
          "tel": "212",
          "pinyin": "mlg"

        },
        {
          "short": "MC",
          "name": "摩纳哥",
          "en": "Monaco",
          "tel": "377",
          "pinyin": "mng"

        },
        {
          "short": "MD",
          "name": "摩尔多瓦",
          "en": "Republic of Moldova",
          "tel": "373",
          "pinyin": "medw"

        },
        {
          "short": "MG",
          "name": "马达加斯加",
          "en": "Madagascar",
          "tel": "261",
          "pinyin": "mdjsj"

        },
        {
          "short": "ML",
          "name": "马里",
          "en": "Mali",
          "tel": "223",
          "pinyin": "ml"

        },
        {
          "short": "MM",
          "name": "缅甸",
          "en": "Burma",
          "tel": "95",
          "pinyin": "md"

        },
        {
          "short": "MN",
          "name": "蒙古",
          "en": "Mongolia",
          "tel": "976",
          "pinyin": "mg"

        },
        {
          "short": "MO",
          "name": "澳门（中国）",
          "en": "Macao",
          "tel": "853",
          "pinyin": "am zg"

        },
        {
          "short": "MS",
          "name": "蒙特塞拉特岛",
          "en": "Montserrat Is",
          "tel": "1664",
          "pinyin": "mtsstd"

        },
        {
          "short": "MT",
          "name": "马耳他",
          "en": "Malta",
          "tel": "356",
          "pinyin": "met"

        },
        {
          "short": "MQ",
          "name": "马提尼克",
          "en": "Martinique",
          "tel": "596",
          "pinyin": "mtnk"

        },
        {
          "short": "MU",
          "name": "毛里求斯",
          "en": "Mauritius",
          "tel": "230",
          "pinyin": "mlqs"

        },
        {
          "short": "MV",
          "name": "马尔代夫",
          "en": "Maldives",
          "tel": "960",
          "pinyin": "medf"


        },
        {
          "short": "MW",
          "name": "马拉维",
          "en": "Malawi",
          "tel": "265",
          "pinyin": "mlw"

        },
        {
          "short": "MX",
          "name": "墨西哥",
          "en": "Mexico",
          "tel": "52",
          "pinyin": "mxg"

        },
        {
          "short": "MY",
          "name": "马来西亚",
          "en": "Malaysia",
          "tel": "60",
          "pinyin": "mlxy"

        },
        {
          "short": "MZ",
          "name": "莫桑比克",
          "en": "Mozambique",
          "tel": "258",
          "pinyin": "msbk"

        },
        {
          "short": "NA",
          "name": "纳米比亚",
          "en": "Namibia",
          "tel": "264",
          "pinyin": "nmby"

        },
        {
          "short": "NE",
          "name": "尼日尔",
          "en": "Niger",
          "tel": "977",
          "pinyin": "nre"

        },
        {
          "short": "NG",
          "name": "尼日利亚",
          "en": "Nigeria",
          "tel": "234",
          "pinyin": "nrly"

        },
        {
          "short": "NI",
          "name": "尼加拉瓜",
          "en": "Nicaragua",
          "tel": "505",
          "pinyin": "njlg"

        },
        {
          "short": "NL",
          "name": "荷兰",
          "en": "Netherlands",
          "tel": "31",
          "pinyin": "hl"

        },
        {
          "short": "NO",
          "name": "挪威",
          "en": "Norway",
          "tel": "47",
          "pinyin": "nw"

        },
        {
          "short": "NP",
          "name": "尼泊尔",
          "en": "Nepal",
          "tel": "977",
          "pinyin": "nbe"

        },
        {
          "short": "AN",
          "name": "荷属安的列斯",
          "en": "Netherlands Antilles",
          "tel": "599",
          "pinyin": "hsadls"

        },
        {
          "short": "NR",
          "name": "瑙鲁",
          "en": "Nauru",
          "tel": "674",
          "pinyin": "nl"

        },
        {
          "short": "NZ",
          "name": "新西兰",
          "en": "New Zealand",
          "tel": "64",
          "pinyin": "xxl"

        },
        {
          "short": "OM",
          "name": "阿曼",
          "en": "Oman",
          "tel": "968",
          "pinyin": "am"

        },
        {
          "short": "PA",
          "name": "巴拿马",
          "en": "Panama",
          "tel": "507",
          "pinyin": "bnm"

        },
        {
          "short": "PE",
          "name": "秘鲁",
          "en": "Peru",
          "tel": "51",
          "pinyin": "bl"

        },
        {
          "short": "PF",
          "name": "法属玻利尼西亚",
          "en": "French Polynesia",
          "tel": "689",
          "pinyin": "fsblnxy"

        },
        {
          "short": "PG",
          "name": "巴布亚新几内亚",
          "en": "Papua New Guinea",
          "tel": "675",
          "pinyin": "bbyxjny"

        },
        {
          "short": "PH",
          "name": "菲律宾",
          "en": "Philippines",
          "tel": "63",
          "pinyin": "flb"

        },
        {
          "short": "PK",
          "name": "巴基斯坦",
          "en": "Pakistan",
          "tel": "92",
          "pinyin": "bjst"

        },
        {
          "short": "PL",
          "name": "波兰",
          "en": "Poland",
          "tel": "48",
          "pinyin": "bl"

        },
        {
          "short": "PR",
          "name": "波多黎各",
          "en": "Puerto Rico",
          "tel": "1787",
          "pinyin": "bdlg"

        },
        {
          "short": "PT",
          "name": "葡萄牙",
          "en": "Portugal",
          "tel": "351",
          "pinyin": "pty"

        },
        {
          "short": "PY",
          "name": "巴拉圭",
          "en": "Paraguay",
          "tel": "595",
          "pinyin": "blg"

        },
        {
          "short": "QA",
          "name": "卡塔尔",
          "en": "Qatar",
          "tel": "974",
          "pinyin": "kte"

        },
        {
          "short": "RE",
          "name": "留尼汪",
          "en": "Reunion",
          "tel": "262",
          "pinyin": "lnw"

        },
        {
          "short": "RO",
          "name": "罗马尼亚",
          "en": "Romania",
          "tel": "40",
          "pinyin": "lmny"

        },
        {
          "short": "RU",
          "name": "俄罗斯",
          "en": "Russia",
          "tel": "7",
          "pinyin": "els"

        },
        {
          "short": "SA",
          "name": "沙特阿拉伯",
          "en": "Saudi Arabia",
          "tel": "966",
          "pinyin": "stalb"

        },
        {
          "short": "SB",
          "name": "所罗门群岛",
          "en": "Solomon Is",
          "tel": "677",
          "pinyin": "slmqd"

        },
        {
          "short": "SC",
          "name": "塞舌尔",
          "en": "Seychelles",
          "tel": "248",
          "pinyin": "sse"

        },
        {
          "short": "SD",
          "name": "苏丹",
          "en": "Sudan",
          "tel": "249",
          "pinyin": "sd"

        },
        {
          "short": "SE",
          "name": "瑞典",
          "en": "Sweden",
          "tel": "46",
          "pinyin": "rd"

        },
        {
          "short": "SG",
          "name": "新加坡",
          "en": "Singapore",
          "tel": "65",
          "pinyin": "xjp"

        },
        {
          "short": "SI",
          "name": "斯洛文尼亚",
          "en": "Slovenia",
          "tel": "386",
          "pinyin": "slwny"

        },
        {
          "short": "SK",
          "name": "斯洛伐克",
          "en": "Slovakia",
          "tel": "421",
          "pinyin": "slfk"

        },
        {
          "short": "SL",
          "name": "塞拉利昂",
          "en": "Sierra Leone",
          "tel": "232",
          "pinyin": "slla"

        },
        {
          "short": "SM",
          "name": "圣马力诺",
          "en": "San Marino",
          "tel": "378",
          "pinyin": "smln"

        },
        {
          "short": "AS",
          "name": "东萨摩亚(美)",
          "en": "Samoa Eastern",
          "tel": "684",
          "pinyin": "dsmym"

        },
        {
          "short": "WS",
          "name": "西萨摩亚",
          "en": "San Marino",
          "tel": "685",
          "pinyin": "xsmy"

        },
        {
          "short": "SN",
          "name": "塞内加尔",
          "en": "Senegal",
          "tel": "221",
          "pinyin": "snje"

        },
        {
          "short": "SO",
          "name": "索马里",
          "en": "Somali",
          "tel": "252",
          "pinyin": "sml"

        },
        {
          "short": "SR",
          "name": "苏里南",
          "en": "Suriname",
          "tel": "597",
          "pinyin": "sln"

        },
        {
          "short": "ST",
          "name": "圣多美和普林西比",
          "en": "Sao Tome and Principe",
          "tel": "239",
          "pinyin": "sdmhplxb"

        },
        {
          "short": "SV",
          "name": "萨尔瓦多",
          "en": "El Salvador",
          "tel": "503",
          "pinyin": "sewd"

        },
        {
          "short": "SY",
          "name": "叙利亚",
          "en": "Syria",
          "tel": "963",
          "pinyin": "xly"

        },
        {
          "short": "SZ",
          "name": "斯威士兰",
          "en": "Swaziland",
          "tel": "268",
          "pinyin": "swsl"

        },
        {
          "short": "TD",
          "name": "乍得",
          "en": "Chad",
          "tel": "235",
          "pinyin": "zd"

        },
        {
          "short": "TG",
          "name": "多哥",
          "en": "Togo",
          "tel": "228",
          "pinyin": "dg"

        },
        {
          "short": "TH",
          "name": "泰国",
          "en": "Thailand",
          "tel": "66",
          "pinyin": "tg"

        },
        {
          "short": "TJ",
          "name": "塔吉克斯坦",
          "en": "Tajikistan",
          "tel": "992",
          "pinyin": "tjkst"

        },
        {
          "short": "TM",
          "name": "土库曼斯坦",
          "en": "Turkmenistan",
          "tel": "993",
          "pinyin": "tkmst"

        },
        {
          "short": "TN",
          "name": "突尼斯",
          "en": "Tunisia",
          "tel": "216",
          "pinyin": "tns"

        },
        {
          "short": "TO",
          "name": "汤加",
          "en": "Tonga",
          "tel": "676",
          "pinyin": "tj"

        },
        {
          "short": "TR",
          "name": "土耳其",
          "en": "Turkey",
          "tel": "90",
          "pinyin": "teq"

        },
        {
          "short": "TT",
          "name": "特立尼达和多巴哥",
          "en": "Trinidad and Tobago",
          "tel": "1809",
          "pinyin": "tlndhdbg"

        },
        {
          "short": "TW",
          "name": "台湾（中国）",
          "en": "Taiwan",
          "tel": "886",
          "pinyin": "twzg"

        },
        {
          "short": "TZ",
          "name": "坦桑尼亚",
          "en": "Tanzania",
          "tel": "255",
          "pinyin": "tsny"

        },
        {
          "short": "UA",
          "name": "乌克兰",
          "en": "Ukraine",
          "tel": "380",
          "pinyin": "wkl"

        },
        {
          "short": "UG",
          "name": "乌干达",
          "en": "Uganda",
          "tel": "256",
          "pinyin": "wgd"

        },
        {
          "short": "US",
          "name": "美国",
          "en": "United States of America",
          "tel": "1",
          "pinyin": "mg"

        },
        {
          "short": "UY",
          "name": "乌拉圭",
          "en": "Uruguay",
          "tel": "598",
          "pinyin": "wlg"

        },
        {
          "short": "UZ",
          "name": "乌兹别克斯坦",
          "en": "Uzbekistan",
          "tel": "233",
          "pinyin": "wzbkst"

        },
        {
          "short": "VC",
          "name": "圣文森特岛",
          "en": "Saint Vincent",
          "tel": "1784",
          "pinyin": "swstd"

        },
        {
          "short": "VE",
          "name": "委内瑞拉",
          "en": "Venezuela",
          "tel": "58",
          "pinyin": "wnrl"

        },
        {
          "short": "VN",
          "name": "越南",
          "en": "Vietnam",
          "tel": "84",
          "pinyin": "yn"

        },
        {
          "short": "YE",
          "name": "也门",
          "en": "Yemen",
          "tel": "967",
          "pinyin": "ym"

        },
        {
          "short": "YU",
          "name": "南斯拉夫",
          "en": "Yugoslavia",
          "tel": "381",
          "pinyin": "nslf"

        },
        {
          "short": "ZA",
          "name": "南非",
          "en": "South Africa",
          "tel": "27",
          "pinyin": "nf"

        },
        {
          "short": "ZM",
          "name": "赞比亚",
          "en": "Zambia",
          "tel": "260",
          "pinyin": "zby"

        },
        {
          "short": "ZR",
          "name": "扎伊尔",
          "en": "Zaire",
          "tel": "243",
          "pinyin": "zye"

        },
        {
          "short": "ZW",
          "name": "津巴布韦",
          "en": "Zimbabwe",
          "tel": "263",
          "pinyin": "jbbw"

        }
      ],

      captcha: {
        id: '',
        code: '',
        img: '',
        show: '',
        lock: false,
        imgLock: false,
        imgLockTime: 3
      },

      imgCode: {
        front: '',
        back: ''
      },
      audit_status: 0,
      stepStatus: 'process',
      loading: true,
      statusPage: {
        title: '',
        subTitle: ''
      },
      icon: 'success',
      loginShow: false,
      loginForm: {
        username: '',
        password: '',
        type: '',
        isUsername: true,
        isPassword: true
      }
    }
  },
  methods: {
    register() {
      this.phoneNumberCheck();
      this.eMailCheck();
      this.eMailFormatCheck();
      this.codeCheck();
      this.passwordCheck();
      this.confirmCheck();
      this.confirmPasswordCheck();
      if (this.accountChoose == null) {
        this.isSelect = false;
      }
      if ((this.isPhoneNumber || (this.isEmail && this.isEMailFormat)) && this.isCode && this.isPassword && this.isPasswordLong && this.isConfirm && this.isConfirmPassword) {
        if (this.message.verify_uuid !== '') {
          let login_type = this.accountChoose === 0 ? 1 : 2;//登录类型1手机2邮箱
          let user_name = this.accountChoose === 0 ? this.message.phoneNumber : this.message.eMail;
          let password = this.message.password;
          let verify_uuid = this.message.verify_uuid;
          let verify_code = this.message.code;
          let country = this.countyOrRegion.short;
          this.$post(this.$store.state.url + '/api/v1/account/register', this.$addQuery({
            login_type,
            user_name,
            password,
            verify_uuid,
            verify_code,
            country
          })).then(res => {
            if (res.status == 0) {
              this.step = 1;
              this.accountData.id = res.data.id;
              this.accountData.token = res.data.token;
              localStorage.setItem('id', res.data.id);
              localStorage.setItem('token', res.data.token);
              localStorage.setItem('username', user_name);
              localStorage.setItem('password', password);
              localStorage.setItem('type', login_type);
            } else {
              this.$message({
                message: res.msg,
                type: 'warning',
                duration: 2000
              });
            }
          })
        } else {
          this.$message({
            message: this.$t('views.register.codeError'),
            type: 'warning',
            duration: 2000
          });
        }
      }

    },

    checkAuditStatus() {
      // let user_name = localStorage.getItem('username') || '';
      // let password = localStorage.getItem('password') || '';
      // let login_type = localStorage.getItem('type') || '';
      const aid = localStorage.getItem('id') || ''
      const token = localStorage.getItem('token') || ''
      if (aid && token) {
        this.$post(this.$store.state.url + '/api/v1/auth/account/info', this.$addQuery({
          aid,
          token
        })).then(res => {
          if (res.status == 0) {
            this.audit_status = res.data.audit_status;
            if (this.audit_status == -1) {
              this.step = 1;

            } else if (this.audit_status == 0) {
              this.step = 2;
              this.icon = 'success'
              this.audit_status = 1;
              this.statusPage = {
                title: this.$t('views.register.success1'),
                subTitle: this.$t('views.register.success2')
              }
              this.stepStatus = 'success'

            } else if (this.audit_status == 1) {
              this.statusPage = {
                title: this.$t('views.register.pass'),
                subTitle: res.data.audit_remark
              }
              this.icon = 'success'
              this.stepStatus = 'success'
              this.step = 2;
            } else if (this.audit_status == 2) {
              this.statusPage = {
                title: this.$t('views.register.noPass'),
                subTitle: res.data.audit_remark
              }
              this.icon = 'error'
              this.stepStatus = 'error'
              this.step = 2;
            } else if (this.audit_status == 3) {
              this.statusPage = {
                title: this.$t('views.register.noPass'),
                subTitle: res.data.audit_remark
              }
              this.icon = 'error'
              this.stepStatus = 'error'
              this.step = 2;
            }
            // localStorage.setItem('id', res.data.id);
            // localStorage.setItem('token', res.data.token);
            this.loading = false;
          } else {
            this.loading = false;
          }
        })
      } else {
        this.step = 0
        this.loading = false;
      }
    },

    getcaptchaImg() {
      if (!this.captcha.imgLock || this.captcha.imgLockTime >= 0) {
        this.captcha.imgLock = true;
        this.captcha.code = '';
        this.$get(this.$store.state.url + '/api/v1/get_cptcha_img', {}).then(res => {
          this.captcha.imgLockTime -= 1;
          const that = this;
          setTimeout(function () {
            that.captcha.imgLock = false;
          }, 5000)
          if (res.status === 0) {
            this.captcha.img = res.data.img;
            this.captcha.id = res.data.captcha_id;
            this.captcha.show = true;
            const that = this;
            setTimeout(function () {
              if (that.$refs.captcha) {
                that.$refs.captcha.focus();
              }
            }, 100);
          }
        }).catch(err => {
          console.log(err);
          this.captcha.imgLockTime -= 1;
          const that = this;
          setTimeout(function () {
            that.captcha.imgLock = false;
          }, 5000)
        })
      } else {
        this.$message({
          message: this.$t('views.register.getImgError'),
          type: 'warning',
          duration: 2000
        });
      }
    },
    sendCode() {
      switch (this.accountChoose) {
        case 0:
          this.sendSMS();
          break;
        case 1:
          this.sendEmail();
          break;
      }
    },
    sendSMS() {
      if (!this.captcha.lock) {
        this.captcha.lock = true;
        let captcha_id = this.captcha.id;
        let captcha_code = this.captcha.code;
        let phone = this.message.phoneNumber;
        let country = this.areaCode.short;
        this.$get(this.$store.state.url + '/api/v1/send_sms', {captcha_id, captcha_code, phone, country}).then(res => {
          if (res.status === 0) {
            this.$message({
              message: res.msg,
              type: 'success',
              duration: 2000
            });
            this.captcha.show = false;
            this.message.verify_uuid = res.data.sms_id;
            this.$codeTimeGo();
          } else {
            this.$message({
              message: res.msg,
              type: 'warning',
              duration: 2000
            });
            this.getcaptchaImg();
          }
          this.captcha.lock = false;
        }).catch(err => {
          this.$message({
            message: err,
            type: 'error',
            duration: 2000
          });
          this.captcha.lock = false;
        })
      }

    },
    sendEmail() {
      if (!this.captcha.lock) {
        this.captcha.lock = true;
        let captcha_id = this.captcha.id;
        let captcha_code = this.captcha.code;
        let email = this.message.eMail;
        this.$get(this.$store.state.url + '/api/v1/send_email', {captcha_id, captcha_code, email}).then(res => {
          if (res.status === 0) {
            this.$message({
              message: res.msg,
              type: 'success',
              duration: 2000
            });
            this.captcha.show = false;
            this.message.verify_uuid = res.data.email_id;
          } else {
            this.$message({
              message: res.msg,
              type: 'warning',
              duration: 2000
            });
            this.getcaptchaImg();
          }
          this.captcha.lock = false;
        }).catch(err => {
          this.$message({
            message: err,
            type: 'error',
            duration: 2000
          });
          this.captcha.lock = false;
        })
      }
    },
    uploadImg(img, index) {
      const formData = new FormData();
      formData.append('image', img);
      this.$post(this.$store.state.url + '/api/v1/upload_img', formData).then(res => {
        if (res.status === 0) {
          switch (index) {
            case 0:
              this.imgCode.front = res.data.img_code;
              break;
            case 1:
              this.imgCode.back = res.data.img_code;
              break;
          }
        } else {
          switch (index) {
            case 0:
              this.img_0 = null;
              break;
            case 1:
              this.img_1 = null;
              break;
          }
        }
      })
    },

    selectImg(n) {
      switch (n) {
        case 0:
          this.$refs.front.click();
          break;
        case 1:
          this.$refs.back.click();
          break;
      }
    },
    updateImg(e) {
      let that = this;
      let files = e.target.files[0];
      let reader = new FileReader();
      switch (e.target.id) {
        case '0':
          this.imgUp_0 = e.target.files[0];
          this.uploadImg(this.imgUp_0, 0);
          reader.readAsDataURL(files);
          reader.onloadend = function () {
            that.img_0 = this.result;
          }
          this.img_0Check();
          break;
        case '1':
          this.imgUp_1 = e.target.files[0];
          this.uploadImg(this.imgUp_1, 1);
          reader.readAsDataURL(files);
          reader.onloadend = function () {
            that.img_1 = this.result;
          }
          this.img_1Check();
          break;
      }
    },

    chooseAreaCode(item) {
      this.countyOrRegion = item;
      this.areaCode = item;
      this.message.areaCode = item.tel;
      this.message.county = item.en;
      this.message.short = item.short;
      this.search = item.short;
      this.areaCodeShow = false;
      this.isPhoneNumber = true;
      this.$refs.phoneNumber.focus();

    },
    chooseCountyOrRegion(item) {
      this.countyOrRegion = item;
      this.areaCode = item;
      this.message.county = item.en;
      this.message.short = item.short;
      this.countryOrRegionShow = false;
      this.search = item.short;
      this.$refs.IDNumber.focus();

    },
    arrowChangeOn() {
      this.arrow = require("@/assets/img/header/arrowFlagOn.png");
    },
    arrowChange() {
      this.arrow = require("@/assets/img/header/arrowFlag.png");
    },
    openCOR() {
      this.isIDNumber = true;
      this.searchList = this.countyOrRegionData;
      this.countryOrRegionShow = true;

    },
    closeCountyOrRegion(e) {
      if ((this.$refs.main && !this.$refs.main.contains(e.target)) && (this.$refs.country && !this.$refs.country.contains(e.target))) {
        if (this.countryOrRegionShow == true) {
          this.countryOrRegionShow = false;
          this.iDNumberCheck();
          if (this.search != this.countyOrRegion.short) {
            this.search = this.countyOrRegion.short;

          }
        }
      }

    },
    closeAreaCode(e) {
      if (this.$refs.areaCode && !this.$refs.areaCode.contains(e.target)) {
        if (this.areaCodeShow == true) {
          this.areaCodeShow = false;
        }
      }

    },
    openAreaCode() {
      this.areaCodeShow = true;
      this.isPhoneNumber = true;

    },
    closeAccountChoose(e) {
      if (this.$refs.account && !this.$refs.account.contains(e.target)) {
        if (this.accountChooseShow == true) {
          this.accountChooseShow = false;


        }
      }

    },
    chooseAccount(n) {
      this.accountChoose = n;
      this.accountChooseShow = false;
      if (this.accountChoose === 0) {
        this.isPhoneNumber = true;
        this.message.eMail = '';
        this.message.code = '';
        this.$refs.phoneNumber.focus();
      } else {
        this.isEmail = true;
        this.isEMailFormat = true;
        this.message.phoneNumber = '';
        this.message.code = '';
        this.$refs.email.focus();
      }
    },
    firstNameCheck() {
      let str = this.message.firstName;
      const regex = /\S/;
      this.isFirstName = regex.test(str);
    },
    lastNameCheck() {
      let str = this.message.lastName;
      const regex = /\S/;
      this.isLastName = regex.test(str);
    },
    phoneNumberCheck() {
      let str = this.message.phoneNumber;
      const regex = /\S/;
      this.isPhoneNumber = regex.test(str);
    },
    eMailCheck() {
      let str = this.message.eMail;
      const regex = /\S/;
      this.isEmail = regex.test(str);
      this.eMailFormatCheck();
    },
    eMailFormatCheck() {
      let str = this.message.eMail;
      const regex = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
      this.isEMailFormat = regex.test(str);
    },
    codeCheck() {
      let str = this.message.code;
      const regex = /\S/;
      this.isCode = regex.test(str);
    },
    passwordCheck() {
      let str = this.message.password;
      const regex = /\S/;
      this.isPassword = regex.test(str);
      this.passwordLongCheck();
    },
    passwordLongCheck() {
      let str = this.message.password;
      this.isPasswordLong = str.length >= 8;
    },
    confirmCheck() {
      let str = this.message.confirm;
      const regex = /\S/;
      this.isConfirm = regex.test(str);
      this.confirmPasswordCheck();

    },
    confirmPasswordCheck() {
      this.isConfirmPassword = this.message.password === this.message.confirm;
    },
    iDNumberCheck() {
      let str = this.message.iDNumber;
      const regex = /\S/;
      this.isIDNumber = regex.test(str);
    },

    img_0Check() {
      this.isImg_0 = null !== this.imgUp_0;
    },
    img_1Check() {
      this.isImg_1 = null !== this.imgUp_1;
    },
    getCode() {
      if (this.accountChoose === 0 && this.$store.state.codeTime == 0) {
        this.phoneNumberCheck();
        if (this.isPhoneNumber) {
          this.getcaptchaImg();
        }
      } else {
        this.eMailCheck();
        this.eMailFormatCheck();
        if (this.isEmail && this.isEMailFormat) {
          this.getcaptchaImg();
        } else {
          this.phoneNumberCheck();
          this.eMailCheck();
        }
      }
    },
    submit() {
      this.firstNameCheck();
      this.lastNameCheck();
      this.phoneNumberCheck();
      this.eMailCheck();
      this.codeCheck();
      this.iDNumberCheck();
      this.img_0Check();
      this.img_1Check();
      if (!this.isAgreement) {
        this.isAgreementR = true;
      }
      if (this.isFirstName && this.isLastName &&
          this.isPassword && this.isPasswordLong && this.isConfirm && this.isConfirmPassword && this.isIDNumber && this.isAgreement && this.imgCode.front !== '' && this.imgCode.back !== '') {
        let aid = this.accountData.id || localStorage.getItem('id');
        let token = this.accountData.token || localStorage.getItem('token');
        let id_card_number = this.message.iDNumber;
        let front_of_id_card_code = this.imgCode.front;
        let back_of_id_card_code = this.imgCode.back;
        let first_name = this.message.firstName;
        let last_name = this.message.lastName;
        let rela_name = first_name + ' ' + last_name;
        let address = 'default';


        this.$post(this.$store.state.url + '/api/v1/auth/account_sub_aduit_data', this.$addQuery({
          aid,
          token,
          id_card_number,
          front_of_id_card_code,
          back_of_id_card_code,
          first_name,
          last_name,
          rela_name,
          address
        })).then(res => {
          if (res.status == 0) {
            this.statusPage = {
              title: this.$t('views.register.success1'),
              subTitle: this.$t('views.register.success2')
            }
            this.icon = 'success'
            this.stepStatus = 'success';
            this.step = 2;

          } else {
            this.$message({
              message: res.msg,
              type: 'warning',
              duration: 2000
            });
          }


        }).catch(err => {
          this.$message({
            message: err,
            type: 'warning',
            duration: 2000
          });
        })

      }
    },
    back() {
      this.step = 1;
      this.stepStatus = 'process';
    },
    openWindow() {
      window.open('/privacy-policy');
    },
    loginUsernameCheck() {
      let str = this.loginForm.username;
      const regex = /\S/;
      this.loginForm.isUsername = regex.test(str);
    },
    loginPasswordCheck() {
      let str = this.loginForm.password;
      const regex = /\S/;
      this.loginForm.isPassword = regex.test(str);
    },
    login() {
      this.loginUsernameCheck();
      this.loginPasswordCheck();
      if (this.loginForm.isUsername && this.loginForm.isPassword) {
        const regex = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
        let user_name = this.loginForm.username;
        let password = this.loginForm.password;
        let login_type = regex.test(user_name) ? 2 : 1;
        this.$post(this.$store.state.url + '/api/v1/account/login', this.$addQuery({
          user_name,
          password,
          login_type
        })).then(res => {
          if (res.status == 0) {
            this.audit_status = res.data[0].audit_status;
            if (this.audit_status == -1) {
              this.step = 1;

            } else if (this.audit_status == 0) {
              this.step = 2;
              this.icon = 'success'
              this.audit_status = 1;
              this.statusPage = {
                title: this.$t('views.register.success1'),
                subTitle: this.$t('views.register.success2')
              }
              this.stepStatus = 'success'

            } else if (this.audit_status == 1) {
              this.statusPage = {
                title: this.$t('views.register.pass'),
                subTitle: res.data[0].audit_remark
              }
              this.icon = 'success'
              this.stepStatus = 'success'
              this.step = 2;
            } else if (this.audit_status == 2) {
              this.statusPage = {
                title: this.$t('views.register.noPass'),
                subTitle: res.data[0].audit_remark
              }
              this.icon = 'error'
              this.stepStatus = 'error'
              this.step = 2;
            } else if (this.audit_status == 3) {
              this.statusPage = {
                title: this.$t('views.register.noPass'),
                subTitle: res.data[0].audit_remark
              }
              this.icon = 'error'
              this.stepStatus = 'error'
              this.step = 2;
            }
            localStorage.setItem('id', res.data[0].id);
            localStorage.setItem('token', res.data[0].token);
            localStorage.setItem('username', user_name);
            localStorage.setItem('password', password);
            localStorage.setItem('type', login_type);
            this.loading = false;
            this.loginShow = false;
          } else {
            this.$message({
              message: res.msg,
              type: 'error'
            })
            this.loading = false
          }
        })
      }
    }
  },
  watch: {
    search(val) {
      let list = [];
      this.countyOrRegionData.forEach(item => {
        let reg = new RegExp(val, 'i');
        if (item.short.match(reg)) {
          list.push(item);
        }
      })
      this.searchList = list;
    }
  },
  mounted() {
    this.checkAuditStatus();
    this.countyOrRegionData.forEach(item => {
      item.en = this.$t('views.register.' + item.short);
    })
    this.areaCode.en = this.$t('views.register.' + this.areaCode.short);
    this.countyOrRegion.en = this.$t('views.register.' + this.countyOrRegion.short);
    this.searchList = this.countyOrRegionData;
    document.addEventListener("click", this.closeAreaCode);
    document.addEventListener("click", this.closeCountyOrRegion);
    document.addEventListener("click", this.closeAccountChoose);

    const body = window.document.getElementsByTagName('body')[0];
    body.style.overflow = 'hidden';


    //图片拖拽
    let that = this;
    let box0 = this.$refs.box0;
    box0.ondragover = function (e) {
      e.preventDefault();
      e.returnValue = false;
    }
    box0.ondrop = function (e) {
      e.preventDefault();
      e.returnValue = false;
      let f = e.dataTransfer.files[0];//获取到第一个上传的文件对象
      if (f.type.indexOf('image') > -1) {
        that.imgUp_0 = f;
        that.uploadImg(f, 0);
        let reader = new FileReader();
        reader.readAsDataURL(f);
        reader.onloadend = function () {
          that.img_0 = this.result;
        }
        that.img_0Check();

      }

    }


    let box1 = this.$refs.box1;
    box1.ondragover = function (e) {
      e.preventDefault();
    }
    box1.ondrop = function (e) {
      e.preventDefault();
      let f = e.dataTransfer.files[0];
      if (f.type.indexOf('image') > -1) {
        that.imgUp_1 = f;
        that.uploadImg(f, 1);
        let reader = new FileReader();
        reader.readAsDataURL(f);
        reader.onloadend = function () {
          that.img_1 = this.result;
        }
        that.img_1Check();
      }
    }


  },
  beforeDestroy() {
    document.removeEventListener("click", this.closeAreaCode);
    document.removeEventListener("click", this.closeCountyOrRegion);
    document.removeEventListener("click", this.closeAccountChoose);
    const body = window.document.getElementsByTagName('body')[0];
    body.style.overflow = 'initial';
  }
}
</script>

<style lang="scss" scoped>
.wrap {

  .main {
    box-shadow: 0 3px 10px -1px rgba(0, 0, 0, .3), 0 2px 50px 2px rgba(0, 0, 0, .2);
    width: 62.27vw;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    padding: 54px 0;
    z-index: 200;

    .inputTable {
      width: 80%;
      margin: 0 auto;
      padding: 27px 0;

      .registerFrame, .openAccountFrame, .loginFrame {
        position: relative;

        .tips {
          color: #8d8d8d;
          font-size: 18px;
          margin-bottom: 20px;
        }

        .account_right {
          display: flex;
          justify-content: space-between;

          .getCode {
            position: absolute;
            right: 20px;
            top: 14px;
            font-size: 16px;
            color: #6D8DD5;
            font-weight: bold;
            cursor: pointer;


          }

          .captcha_img {
            position: absolute;
            padding: 10px;
            background: #fff;
            z-index: 100;
            right: 0;
            top: 45px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 5px;
            box-shadow: 0 3px 10px -1px rgba(148, 145, 145, 0.3), 0 2px 10px 2px rgba(147, 130, 130, 0.2);

            input {
              width: 90px;
              border: 1px solid #D8D8D8;
              border-radius: 5px;
              padding: 10px;
              color: #707070;
              font-size: clamp(12px, 2.5vw, 16px);
              height: 32px;
              margin-right: 10px;
            }

            img {
              cursor: pointer;
              max-width: 120px;
            }
          }
        }

        .text_input {
          width: 94%;
          border: 1px solid #D8D8D8;
          border-radius: 5px;
          padding: 10px;
          color: #707070;
          font-size: clamp(16px, 3vw, 20px);
          height: 45px;
        }

        .text_input_code {
          width: 100%;
          border: 1px solid #D8D8D8;
          border-radius: 5px;
          padding: 10px;
          color: #707070;
          font-size: clamp(16px, 3vw, 20px);
          height: 45px;
        }

        .required {
          position: absolute;
          right: 20px;
          top: 12px;
          font-size: 16px;
          color: #ff0000;
          pointer-events: none;
        }

        .row_form {
          display: flex;
          justify-content: space-between;

          .row_account {
            position: relative;
            margin-bottom: 20px;
            display: flex;
            flex-wrap: nowrap;

            .accountChoose {
              height: 45px;
              border: 1px solid #D8D8D8;
              border-radius: 5px;
              padding: 10px 20px;
              color: #333;
              font-size: clamp(16px, 3vw, 20px);
              white-space: nowrap;
              position: relative;
              min-width: 126px;
              cursor: pointer;
              margin-right: 20px;
              display: flex;
              align-items: center;

              &:hover {
                background: #eee;
              }

              .arrow {
                width: 16px;
                height: 10px;
                position: absolute;
                right: 20px;

              }
            }

            .accountChooseBox {
              position: absolute;
              top: 45px;
              left: 0;
              z-index: 20;
              border: 1px solid #D8D8D8;
              border-radius: 5px;
              color: #333;
              font-size: clamp(16px, 3vw, 20px);
              background: #fff;
              min-width: 126px;

              p {
                padding: 10px 20px;
                cursor: pointer;


                &:hover {
                  background: #eee;
                }
              }
            }
          }


          .control_wrap {
            margin-bottom: 20px;
            width: 50%;
            position: relative;


            .cORBefore {
              position: absolute;
              top: 0;
              left: 0;
              height: 45px;
              display: flex;
              align-items: center;
              width: 100px;
              padding-left: 20px;
              cursor: pointer;
              color: #707070;
              font-size: clamp(16px, 3vw, 20px);
              border-top: 1px solid #D8D8D8;
              border-left: 1px solid #D8D8D8;
              border-bottom: 1px solid #D8D8D8;
              border-top-left-radius: 5px;
              border-bottom-left-radius: 5px;

              .search {
                width: 40px;
                height: 43px;
                font-size: 18px;
                background-color: rgba(0, 0, 0, 0);
              }

              .arrow {
                position: absolute;
                top: 18px;
                right: 12px;

              }

              &:hover {
                background: #eee;


              }

              //
              //.flagIconInput {
              //  min-width: 16px;
              //  box-sizing: border-box;
              //  background-image: url(../../assets/img/header/flags.png);
              //  display: inline-block;
              //  width: 16px;
              //  height: 16px;
              //  margin-right: 10px;
              //
              //}
            }


            .areaCodeBefore {
              position: absolute;
              left: 0;
              top: 0;
              font-size: clamp(16px, 3vw, 20px);
              color: #707070;
              cursor: pointer;
              height: 45px;
              display: flex;
              align-items: center;
              padding-left: 20px;
              width: 104px;
              border-top: 1px solid #D8D8D8;
              border-left: 1px solid #D8D8D8;
              border-bottom: 1px solid #D8D8D8;
              border-top-left-radius: 5px;
              border-bottom-left-radius: 5px;

              &:hover {
                background: #eee;
              }
            }

            .chooseBox {
              height: 46px;
              display: flex;
              align-items: center;
              padding: 0 0 0 20px;
            }

            .areaCodeChooseBox {
              width: 150px;
              height: 240px;
              border: 1px #dedee1 solid;
              border-radius: 5px;
              background: #fff;
              position: absolute;
              overflow-y: scroll;
              z-index: 5;
              font-size: 14px;
              color: #555555;
              cursor: pointer;
              top: 45px;

            }

            .chooseBox:hover {
              background: #eee;
            }


            .countryOrRegionChooseBox {
              width: 26.35vw;
              height: 288px;
              border: 1px #dedee1 solid;
              border-radius: 5px;
              background: #fff;
              position: absolute;
              overflow-y: scroll;
              z-index: 1000;
              color: #555555;
              top: 45px;
              cursor: pointer;

              .chooseBox {
                height: 46px;
                display: flex;
                align-items: center;
                padding-left: 20px;
              }
            }

          }


        }

        .selectFrame {
          color: #727272;
          font-size: 18px;
          margin-bottom: 20px;
          display: flex;
          justify-content: center;
          flex-wrap: wrap;

          p {
            text-align: center;
            margin-bottom: 20px;
          }

          .selectBox {
            width: 354px;
            margin: 0 20px;

            input {
              display: none;
            }

            .imgUpdate {
              width: 354px;
              height: 213px;
              position: relative;
              border: 1px dashed #D6DDEA;;
              background: #F7F9FD;
              cursor: pointer;


              .imgModel {
                width: 100px;
                height: 80px;
                border-radius: 50%;
                border: 1px dashed #D6DDEA;;
                position: absolute;
                left: 50%;
                top: 10%;
                transform: translate(-50%, 0);
                display: flex;
                justify-content: center;
                align-items: center;

                .imgIcon {
                  width: 48px;
                  height: 48px;

                }
              }

              .img_text {
                font-size: 15px;
                font-weight: bold;
                position: absolute;
                top: 55%;
                left: 50%;
                transform: translateX(-50%);
                word-break: break-word;
                width: 100%;

                span {
                  color: #6D8DD5;
                }
              }

              .requiredImg {
                position: absolute;
                font-size: 16px;
                color: #ff0000;
                bottom: 6%;
                margin: 0;
                left: 50%;
                transform: translateX(-50%);
              }

              .imgUp {
                position: absolute;
                width: 100%;
                max-height: 100%;
                min-height: 100%;
                top: 0;
                left: 0;
                z-index: 800;
                background: #fff;
              }
            }

            .imgR {
              border: 1px dashed #ead6d6;;
              background: #fdf7f7;
            }
          }
        }

        .privacy_policy {
          display: flex;

          p {
            font-size: 14px;
            color: #333;
            margin-left: 6px;

            span {
              color: #2096A2;
              cursor: pointer;
            }
          }
        }


        .submit {
          padding: 10px 8px 10px 30px;
          font-size: 20px;
          font-weight: 500;
          background-size: cover;
          background-position: 50%;
          background-repeat: no-repeat;
          position: relative;
          transition: all .2s ease;
          background-color: #2096A2;
          border-radius: 5px;
          color: #FFFFFF;
          border: none;
          width: auto;
          margin-top: 20px;
          opacity: .8;
          cursor: pointer;
          display: inline-block;


          img {
            width: 20px;
            height: 20px;
            transform: translate(-8px, 3px);
            opacity: 0;
            transition: all .2s ease;
          }

          &:hover {
            filter: brightness(110%);
            padding: 10px 30px 10px 20px;


            img {
              opacity: 1;
              transform: translate(8px, 3px);
            }

          }
        }


      }

      .close {
        position: absolute;
        top: 0;
        right: 0;
        cursor: pointer;
        padding: 8px;
        width: 32px;
        height: 32px;

        &:hover {
          box-shadow: 0 3px 10px -1px rgba(0, 0, 0, .3), 0 2px 50px 2px rgba(0, 0, 0, .2);
          transition: .5s;
        }
      }
    }
  }

  .mask {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
    background: rgba(0, 0, 0, .55);
    backdrop-filter: saturate(180%) blur(5px);
    transition: -webkit-backdrop-filter 1s 1s, backdrop-filter 1s 1s;

  }

}

.areaCode {
  width: 150px;
  height: 44px;
  font-size: 14px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 5px;
  border: 1px #dedee1 solid;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

//.flagIcon {
//  position: relative;
//  vertical-align: top;
//  min-width: 16px;
//  margin: 2px 0;
//  box-sizing: border-box;
//  background-image: url(../../assets/img/header/flags.png);
//  display: inline-block;
//  width: 16px;
//  height: 16px;
//
//}

.chooseText {
  margin-left: 10px;
  font-size: clamp(16px, 3vw, 20px);
  color: #555555;
}

.short {
  font-size: 18px;
  color: #333;
  width: 32px;
}

.redBefore {
  border-top: 1px solid #ff0000 !important;
  border-left: 1px solid #ff0000 !important;
  border-bottom: 1px solid #ff0000 !important;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  background: #ffebeb !important;


  &:hover {
    border-top: 1px solid #ff0000 !important;
    border-left: 1px solid #ff0000 !important;
    border-bottom: 1px solid #ff0000 !important;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    background: #ffebeb !important;

    .arrow {

    }
  }
}

.country {
  top: 0;
  left: 0;
  height: 45px;
  display: flex;
  align-items: center;
  width: 94%;
  padding-left: 20px;
  cursor: pointer;
  color: #707070;
  font-size: clamp(16px, 3vw, 20px);
  border: 1px solid #D8D8D8;
  border-radius: 5px;


  .search {
    width: 40px;
    height: 43px;
    font-size: 18px;
    background-color: rgba(0, 0, 0, 0);
  }

  .arrow {
    position: absolute;
    top: 18px;
    right: 50px;

  }

  &:hover {
    background: #eee;


  }

}

.countryChooseBox {
  width: 94%;
  height: 288px;
  border: 1px #dedee1 solid;
  border-radius: 5px;
  background: #fff;
  position: absolute;
  overflow-y: scroll;
  z-index: 1000;
  color: #555555;
  top: 45px;
  cursor: pointer;

  .chooseBox {
    height: 46px;
    display: flex;
    align-items: center;
    padding-left: 20px;
  }
}

.el-step__line {
  cursor: pointer;
}

.backBtn {
  padding: 10px 20px;
  background: #fff;
  color: #000;
  border-radius: 5px;
  border: 1px solid #000;
  font-size: 16px;
  cursor: pointer;
  transition: 0.2s;

  &:hover {
    background: #000;
    color: #fff;
  }
}

.toLogin {
  text-align: center;
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    color: #2096A2;
  }
}

.text_input_login {
  width: 100%;
  border: 1px solid #D8D8D8;
  border-radius: 5px;
  padding: 10px;
  color: #707070;
  font-size: clamp(16px, 3vw, 20px);
  height: 45px;
}

.button_row {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;

  .login_btn {
    padding: 12px 32px;
    background: #2096A2;
    border-radius: 10px;
    font-size: 20px;
    color: #fff;
    cursor: pointer;
    transition: .2s;

    &:hover {
      filter: brightness(110%);
      font-size: 22px;
    }
  }
}

</style>
