<template>
  <div class="wrap">
    <div class="main">
      <div class="row_1">
        <div class="img">
          <img :src="icon">
        </div>
        <p>
          {{$t('views.home.disclaimer')}}
        </p>
      </div>
      <img class="close" :src="closeIcon" @click="$store.state.disclaimerShow=false">
    </div>
    <div class="mask" @click="$store.state.disclaimerShow = false"/>
  </div>
</template>

<script>
export default {
  name: "disclaimer",
  data() {
    return {
      icon: require('@/assets/img/home/Compass-Logo-fond-fonce-B.png'),
      closeIcon: require('@/assets/img/home/close.png'),
    }
  },
  mounted() {
    const body = window.document.getElementsByTagName('body')[0];
    body.style.overflow = 'hidden';

  },
  beforeDestroy() {
    const body = window.document.getElementsByTagName('body')[0];
    body.style.overflow = 'initial';
  }
}
</script>

<style lang="scss" scoped>
.wrap {
  .main {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 88.95vw;
    box-shadow: 0 3px 10px -1px rgba(0, 0, 0, .3), 0 2px 50px 2px rgba(0, 0, 0, .2);
    background: #1b3044;
    padding: 54px 0;
    border-radius: 10px;
    z-index: 200;
    .close {
      position: absolute;
      top: 0;
      right: 0;
      cursor: pointer;
      padding: 8px;
      width: 32px;
      height: 32px;

      &:hover {
        box-shadow: 0 3px 10px -1px rgba(0, 0, 0, .3), 0 2px 50px 2px rgba(0, 0, 0, .2);
        transition: .5s;
      }
    }
    .img {
      width: 100%;
      margin-bottom: 2.75%;
      display: flex;
      justify-content: center;
      height: 76px;
      img {


      }
    }

    p {
      font-size: 13px;
      word-break: break-word;
    }
  }

  .row_1 {
    padding: 27px 0;
    width: 90%;
    margin: auto;
  }

  .mask {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
    background: rgba(0, 0, 0, .55);
    backdrop-filter: saturate(180%) blur(5px);
    transition: -webkit-backdrop-filter 1s 1s, backdrop-filter 1s 1s;

  }
}
</style>