<template>
  <div class="wrapHeader" @mouseleave="menuShow = false">
    <div class="logo" @mouseenter="menuShow = false">
      <router-link style="outline: none" to="/"><img :src="logo"></router-link>
    </div>
    <div class="main">
      <ul id="4" @mousemove="menuBaseOn">
        <li id="0" @mouseenter.stop="menuOn">
          <a>{{ $t('views.header.cryptocurrencies.title') }}</a>
        </li>
        <li id="1" @mouseenter.stop="menuOn">
          <a>{{ $t('views.header.preciousMetals.title') }}</a>
        </li>
        <li id="2" @mouseenter.stop="menuOn">
          <a>{{ $t('views.header.forex.title') }}</a>
        </li>
        <li id="3" @mouseenter.stop="menuOn">
          <a>{{ $t('views.header.commodities.title') }}</a>
        </li>
        <li class="li_btn" @mouseenter="menuShow = false">
          <div class="openBtn" @click="$store.state.openAccountShow=true">{{ $t('views.header.btn') }}</div>
        </li>
        <li class="searchBtn">
          <!--          <img :src="searchIcon">-->
          <div class="languageChange" @mouseenter="languageShow = true" @mouseleave="languageShow = false">
            <span>{{lShow($i18n.locale)}}</span>
            <div class="select" v-if="languageShow">
              <p class="selectEn" @click="languageChange('en')">English</p>
              <p class="selectCn" @click="languageChange('cn')">繁體中文</p>
              <p class="selectKr" @click="languageChange('kr')">한국어</p>
              <p class="selectJp" @click="languageChange('jp')">日本語</p>
            </div>
          </div>
        </li>
      </ul>
      <transition name="menu">
        <div class="menu" :style="'left: '+menuLeft+'px'" v-if="menuShow" @mouseenter="menuShow = true"
             @mouseleave="menuShow=false">
          <router-link style="outline: none" :to="item.path" v-for="(item,index) in menu" :key="index"><p
              style="word-break : break-word;">
            {{ item.name }}
          </p></router-link>

        </div>
      </transition>
    </div>

    <open-account v-if="$store.state.openAccountShow"/>

  </div>
</template>

<script>
import OpenAccount from "./openAccount";

export default {
  name: "headerPage",
  components: {OpenAccount},
  computed: {
    language() {
      return localStorage.getItem('lang');
    }
  },
  data() {
    return {
      // searchIcon: require('@/assets/img/header/search.png'),
      logo: require('@/assets/img/header/logo.png'),
      menuLeft: 0,
      menuShow: false,
      menu: [],
      menuList: [],
      languageShow: false,
    }
  },
  methods: {
    menuBaseOn(e) {
      if (e.target.id == '4') {
        this.menuShow = false
      }
    },
    menuOn(e) {
      this.menuShow = true;
      this.menuLeft = e.target.offsetLeft;
      switch (e.target.id) {
        case '0':
          this.menu = this.menuList[0];
          break;
        case '1':
          this.menu = this.menuList[1];
          break;
        case '2':
          this.menu = this.menuList[2];
          break;
        case '3':
          this.menu = this.menuList[3];
          break;
      }
    },
    languageChange(lang) {
      if (lang === 'kr') {
        //this.$i18n.locale = 'kr';
        localStorage.setItem('lang', "kr");

      }else if(lang === 'cn'){
        localStorage.setItem('lang', "cn");
      }
      else if(lang === 'en') {
        //this.$i18n.locale = 'en';
        localStorage.setItem('lang', "en");
      }
      else if(lang === 'jp') {
        //this.$i18n.locale = 'en';
        localStorage.setItem('lang', "jp");
      }
      location.reload();
      this.$forceUpdate();

    },
    lShow(lang){
      let l = '';
      switch (lang){
        case 'en':
          l = 'English'
          break;
        case 'cn':
          l = '繁體中文'
          break;
        case 'kr':
          l = '한국인'
          break;
        case 'jp':
          l = '日本語'
          break;
      }
      return l;
    }
  },
  watch: {
    $route() {
      this.menuShow = false;
    }
  },
  mounted() {

    this.menuList = [
      [{
        name: this.$t('views.header.cryptocurrencies.d1'),
        path: '/bitcoin-perpetual-futures'
      }, {
        name: this.$t('views.header.cryptocurrencies.d2'),
        path: '/bitcoin-monthly-futures'
      },],
      [{
        name: this.$t('views.header.preciousMetals.d1'),
        path: '/gold-futures'
      }, {
        name: this.$t('views.header.preciousMetals.d2'),
        path: '/silver-futures'
      },],
      [{
        name: this.$t('views.header.forex.d1'),
        path: '/usd-cnh-futures'
      }],
      [{
        name: this.$t('views.header.commodities.d1'),
        path: '/palm-olein-futures'
      }, {
        name: this.$t('views.header.commodities.d2'),
        path: '/crude-palm-oil-futures'
      },
        {
          name: this.$t('views.header.commodities.d3'),
          path: '/fuel-oil-futures'
        }, {
        name: this.$t('views.header.commodities.d4'),
        path: '/low-sulphur-fuel-oil-futures'
      },],


    ]
  }
}
</script>

<style lang="scss" scoped>
.wrapHeader {
  width: 100%;
  height: 74px;
  background: #fff;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  z-index: 200;

  .logo {
    width: 230px;
    height: 100%;
    display: flex;
    align-items: center;

    img {
      width: 150px;
      pointer-events: none;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;

    }
  }

  .main {
    width: 78%;
    height: 100%;
    position: relative;

    ul {
      display: flex;
      font-size: 15px;

      li {
        display: flex;
        align-items: center;
        margin: 0 5px;

        a {
          padding: 10px 20px 10px 10px;
          margin: 18px 1px;
          white-space: nowrap;

          &:hover {
            border-radius: 10px;
            border: 1px solid #000;
            margin: 14px 0;
          }

          &::after {
            width: 12px;
            height: 12px;
            background: url("../../assets/img/header/down.png") no-repeat;
            content: "";
            display: inline-block;
            margin-left: 4px;
            vertical-align: 0;
          }
        }


      }

      .languageChange {
        position: absolute;
        right: 10%;
        font-size: 17px;
        cursor: pointer;
        height: 30px;
        top:26px;
        span {
          font-size: 14px;
        }

        .select {
          position: absolute;
          background: #f2f2f2;
          box-shadow: 0 3px 10px -1px rgba(0, 0, 0, .3), 0 2px 10px 2px rgba(0, 0, 0, .2);
          border-radius: 5px;
          top: 28px;
          left: 50%;
          transform: translateX(-50%);

          p {
            white-space: nowrap;
            font-size: 15px;
            padding: 12px 15px;
            border-radius: 5px;

            &:hover {
              background: #ddd;
            }
          }

          .selectEn {
            &::before {
              background: url("../../../src/assets/img/header/en.png") no-repeat;
              width: 24px;
              height: 15px;
              content: "";
              display: inline-block;
              vertical-align: -2px;
              margin-right: 6px;
            }
          }

          .selectCn {
            &::before {
              background: url("../../../src/assets/img/header/cn.png") no-repeat;
              width: 24px;
              height: 15px;
              content: "";
              display: inline-block;
              vertical-align: -2px;
              margin-right: 6px;
            }
          }

          .selectKr {
            &::before {
              background: url("../../../src/assets/img/header/kr.png") no-repeat;
              width: 24px;
              height: 15px;
              content: "";
              display: inline-block;
              vertical-align: -2px;
              margin-right: 6px;
            }
          }
          .selectJp {
            &::before {
              background: url("../../../src/assets/img/header/jp.png") no-repeat;
              width: 24px;
              height: 15px;
              content: "";
              display: inline-block;
              vertical-align: -2px;
              margin-right: 6px;
            }
          }
        }
      }

      .li_btn {
        width: 42vw;

        .openBtn {
          background: transparent linear-gradient(90deg, #2BBAC3 0%, #188798 100%) 0 0 no-repeat padding-box;
          border-radius: 10px;
          border: none;
          padding: 10px 20px 10px 20px !important;
          color: #fff;
          white-space: nowrap;
          cursor: pointer;
        }
      }

      .searchBtn {

        img {
          cursor: pointer;
        }
      }
    }

    .menu {
      width: 240px;
      background: #1f96a8;
      position: absolute;
      top: 74px;
      border-radius: 10px;
      font-size: 14px;
      text-align: left;
      padding: 20px;
      box-shadow: 0 3px 10px -1px rgba(0, 0, 0, .3), 0 2px 50px 2px rgba(0, 0, 0, .2);

      p {
        padding: 10px 0 10px 20px;
        width: 200px;
        cursor: pointer;

        &:hover {
          padding: 9px 0 9px 19px;
          border-radius: 10px;
          border: 1px solid #fff;

        }

      }

      a {
        color: #fff;


      }
    }
  }
}

.menu-enter {
  opacity: 0;
}

.menu-enter-to {
  opacity: 1;
}

.menu-enter-active {
  transition: 0.2s;
}

</style>