<template>
  <div class="wrap">
    <div class="main">
      <img>
      <p>© 2020-2023 Alpha fund management Pte. Ltd. All rights reserved.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "bottom"
}
</script>

<style lang="scss" scoped>
.wrap {
  width: 100%;
  background: #000;

  .main {
    width: 90%;
    max-width: 1728px;
    height: 82px;
    margin: 0 auto;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    p {
      width: 65.667%;
      color: #fff;
      font-size: 14px;
    }
  }
}
</style>